// Customizable Area Start
export const countriesCode = [
  { id: 1, code: "AF", label: "Afghanistan", phone: "93", mobile_length: 9 },
  { id: 2, code: "AL", label: "Albania", phone: "355", mobile_length: 8 },
  { id: 3, code: "DZ", label: "Algeria", phone: "213", mobile_length: 10 },
  { id: 4, code: "AS", label: "American Samoa", phone: "1-684", mobile_length: 7 },
  { id: 5, code: "AD", label: "Andorra", phone: "376", mobile_length: 8 },
  { id: 6, code: "AO", label: "Angola", phone: "244", mobile_length: 9 },
  { id: 7, code: "AI", label: "Anguilla", phone: "1-264", mobile_length: 7 },
  { id: 8, code: "AG", label: "Antigua and Barbuda", phone: "1-268", mobile_length: 7 },
  { id: 9, code: "AR", label: "Argentina", phone: "54", mobile_length: 10 },
  { id: 10, code: "AM", label: "Armenia", phone: "374", mobile_length: 8 },
  { id: 11, code: "AW", label: "Aruba", phone: "297", mobile_length: 7 },
  { id: 12, code: "AU", label: "Australia", phone: "61", mobile_length: 9 },
  { id: 13, code: "AT", label: "Austria", phone: "43", mobile_length: 11 },
  { id: 14, code: "AZ", label: "Azerbaijan", phone: "994", mobile_length: 9 },
  { id: 15, code: "BS", label: "Bahamas", phone: "1-242", mobile_length: 7 },
  { id: 16, code: "BH", label: "Bahrain", phone: "973", mobile_length: 8 },
  { id: 17, code: "BD", label: "Bangladesh", phone: "880", mobile_length: 10 },
  { id: 18, code: "BB", label: "Barbados", phone: "1-246", mobile_length: 7 },
  { id: 19, code: "BY", label: "Belarus", phone: "375", mobile_length: 9 },
  { id: 20, code: "BE", label: "Belgium", phone: "32", mobile_length: 9 },
  { id: 21, code: "BZ", label: "Belize", phone: "501", mobile_length: 7 },
  { id: 22, code: "BJ", label: "Benin", phone: "229", mobile_length: 8 },
  { id: 23, code: "BM", label: "Bermuda", phone: "1-441", mobile_length: 7 },
  { id: 24, code: "BT", label: "Bhutan", phone: "975", mobile_length: 8 },
  { id: 25, code: "BO", label: "Bolivia", phone: "591", mobile_length: 8 },
  { id: 26, code: "BA", label: "Bosnia and Herzegovina", phone: "387", mobile_length: 8 },
  { id: 27, code: "BW", label: "Botswana", phone: "267", mobile_length: 8 },
  { id: 28, code: "BR", label: "Brazil", phone: "55", mobile_length: 11 },
  { id: 29, code: "IO", label: "British Indian Ocean Territory", phone: "246", mobile_length: 7 },
  { id: 30, code: "VG", label: "British Virgin Islands", phone: "1-284", mobile_length: 7 },
  { id: 31, code: "BN", label: "Brunei", phone: "673", mobile_length: 7 },
  { id: 32, code: "BG", label: "Bulgaria", phone: "359", mobile_length: 9 },
  { id: 33, code: "BF", label: "Burkina Faso", phone: "226", mobile_length: 8 },
  { id: 34, code: "BI", label: "Burundi", phone: "257", mobile_length: 8 },
  { id: 35, code: "KH", label: "Cambodia", phone: "855", mobile_length: 9 },
  { id: 36, code: "CM", label: "Cameroon", phone: "237", mobile_length: 9 },
  { id: 37, code: "CA", label: "Canada", phone: "1", mobile_length: 10 },
  { id: 38, code: "CV", label: "Cape Verde", phone: "238", mobile_length: 7 },
  { id: 39, code: "BQ", label: "Caribbean Netherlands", phone: "599", mobile_length: 7 },
  { id: 40, code: "KY", label: "Cayman Islands", phone: "1-345", mobile_length: 7 },
  { id: 41, code: "CF", label: "Central African Republic", phone: "236", mobile_length: 8 },
  { id: 42, code: "TD", label: "Chad", phone: "235", mobile_length: 8 },
  { id: 43, code: "CL", label: "Chile", phone: "56", mobile_length: 9 },
  { id: 44, code: "CN", label: "China", phone: "86", mobile_length: 11 },
  { id: 45, code: "CX", label: "Christmas Island", phone: "61", mobile_length: 9 },
  { id: 46, code: "CC", label: "Cocos (Keeling) Islands", phone: "61", mobile_length: 9 },
  { id: 47, code: "CO", label: "Colombia", phone: "57", mobile_length: 10 },
  { id: 48, code: "KM", label: "Comoros", phone: "269", mobile_length: 7 },
  { id: 49, code: "CD", label: "Congo (DRC)", phone: "243", mobile_length: 9 },
  { id: 50, code: "CG", label: "Congo (Republic)", phone: "242", mobile_length: 9 },
  { id: 51, code: "CK", label: "Cook Islands", phone: "682", mobile_length: 5 },
{ id: 52, code: "CR", label: "Costa Rica", phone: "506", mobile_length: 8 },
{ id: 53, code: "CI", label: "Côte d'Ivoire", phone: "225", mobile_length: 8 },
{ id: 54, code: "HR", label: "Croatia", phone: "385", mobile_length: 9 },
{ id: 55, code: "CU", label: "Cuba", phone: "53", mobile_length: 8 },
{ id: 56, code: "CW", label: "Curaçao", phone: "599", mobile_length: 7 },
{ id: 57, code: "CY", label: "Cyprus", phone: "357", mobile_length: 8 },
{ id: 58, code: "CZ", label: "Czech Republic", phone: "420", mobile_length: 9 },
{ id: 59, code: "DK", label: "Denmark", phone: "45", mobile_length: 8 },
{ id: 60, code: "DJ", label: "Djibouti", phone: "253", mobile_length: 8 },
{ id: 61, code: "DM", label: "Dominica", phone: "1-767", mobile_length: 7 },
{ id: 62, code: "DO", label: "Dominican Republic", phone: "1-809", mobile_length: 10 },
{ id: 63, code: "EC", label: "Ecuador", phone: "593", mobile_length: 9 },
{ id: 64, code: "EG", label: "Egypt", phone: "20", mobile_length: 10 },
{ id: 65, code: "SV", label: "El Salvador", phone: "503", mobile_length: 8 },
{ id: 66, code: "GQ", label: "Equatorial Guinea", phone: "240", mobile_length: 9 },
{ id: 67, code: "ER", label: "Eritrea", phone: "291", mobile_length: 7 },
{ id: 68, code: "EE", label: "Estonia", phone: "372", mobile_length: 7 },
{ id: 69, code: "ET", label: "Ethiopia", phone: "251", mobile_length: 9 },
{ id: 70, code: "FK", label: "Falkland Islands (Islas Malvinas)", phone: "500", mobile_length: 5 },
{ id: 71, code: "FO", label: "Faroe Islands", phone: "298", mobile_length: 6 },
{ id: 72, code: "FJ", label: "Fiji", phone: "679", mobile_length: 7 },
{ id: 73, code: "FI", label: "Finland", phone: "358", mobile_length: 10 },
{ id: 74, code: "FR", label: "France", phone: "33", mobile_length: 9 },
  { id: 75, code: "GF", label: "French Guiana", phone: "594", mobile_length: 9 },
  { id: 76, code: "PF", label: "French Polynesia", phone: "689", mobile_length: 6 },
  { id: 77, code: "TF", label: "French Southern and Antarctic Lands", phone: "", mobile_length: null },
  { id: 78, code: "GA", label: "Gabon", phone: "241", mobile_length: 8 },
  { id: 79, code: "GM", label: "Gambia, The", phone: "220", mobile_length: 7 },
  { id: 80, code: "GE", label: "Georgia", phone: "995", mobile_length: 9 },
  { id: 81, code: "DE", label: "Germany", phone: "49", mobile_length: 10 },
  { id: 82, code: "GH", label: "Ghana", phone: "233", mobile_length: 9 },
  { id: 83, code: "GI", label: "Gibraltar", phone: "350", mobile_length: 8 },
  { id: 84, code: "GR", label: "Greece", phone: "30", mobile_length: 10 },
  { id: 85, code: "GL", label: "Greenland", phone: "299", mobile_length: 6 },
  { id: 86, code: "GD", label: "Grenada", phone: "1-473", mobile_length: 7 },
  { id: 87, code: "GP", label: "Guadeloupe", phone: "590", mobile_length: 9 },
  { id: 88, code: "GU", label: "Guam", phone: "1-671", mobile_length: 7 },
  { id: 89, code: "GT", label: "Guatemala", phone: "502", mobile_length: 8 },
  { id: 90, code: "GG", label: "Guernsey", phone: "44-1481", mobile_length: 6 },
  { id: 91, code: "GN", label: "Guinea", phone: "224", mobile_length: 8 },
  { id: 92, code: "GW", label: "Guinea-Bissau", phone: "245", mobile_length: 7 },
  { id: 93, code: "GY", label: "Guyana", phone: "592", mobile_length: 7 },
  { id: 94, code: "HT", label: "Haiti", phone: "509", mobile_length: 8 },
  { id: 95, code: "HM", label: "Heard Island and McDonald Islands", phone: "", mobile_length: null },
  { id: 96, code: "HN", label: "Honduras", phone: "504", mobile_length: 8 },
  { id: 97, code: "HK", label: "Hong Kong", phone: "852", mobile_length: 8 },
  { id: 98, code: "HU", label: "Hungary", phone: "36", mobile_length: 9 },
  { id: 99, code: "IS", label: "Iceland", phone: "354", mobile_length: 7 },
  { id: 100, code: "IN", label: "India", phone: "91", mobile_length: 10 },
  { id: 101, code: "ID", label: "Indonesia", phone: "62", mobile_length: 10 },
  { id: 102, code: "IR", label: "Iran", phone: "98", mobile_length: 10 },
{ id: 103, code: "IQ", label: "Iraq", phone: "964", mobile_length: 10 },
{ id: 104, code: "IE", label: "Ireland", phone: "353", mobile_length: 9 },
{ id: 105, code: "IM", label: "Isle of Man", phone: "44-1624", mobile_length: 6 },
{ id: 106, code: "IL", label: "Israel", phone: "972", mobile_length: 9 },
{ id: 107, code: "IT", label: "Italy", phone: "39", mobile_length: 10 },
{ id: 108, code: "JM", label: "Jamaica", phone: "+1-876", mobile_length: 7 },
{ id: 109, code: "JP", label: "Japan", phone: "81", mobile_length: 10 },
{ id: 110, code: "JE", label: "Jersey", phone: "44-1534", mobile_length: 6 },
{ id: 111, code: "JO", label: "Jordan", phone: "962", mobile_length: 9 },
{ id: 112, code: "KZ", label: "Kazakhstan", phone: "7", mobile_length: 10 },
{ id: 113, code: "KE", label: "Kenya", phone: "254", mobile_length: 9 },
{ id: 114, code: "KI", label: "Kiribati", phone: "686", mobile_length: 5 },
{ id: 115, code: "KP", label: "Korea, North", phone: "850", mobile_length: 9 },
{ id: 116, code: "KR", label: "Korea, South", phone: "82", mobile_length: 10 },
{ id: 117, code: "KW", label: "Kuwait", phone: "965", mobile_length: 8 },
{ id: 118, code: "KG", label: "Kyrgyzstan", phone: "996", mobile_length: 9 },
{ id: 119, code: "LA", label: "Laos", phone: "856", mobile_length: 8 },
{ id: 120, code: "LV", label: "Latvia", phone: "371", mobile_length: 8 },
{ id: 121, code: "LB", label: "Lebanon", phone: "961", mobile_length: 8 },
{ id: 122, code: "LS", label: "Lesotho", phone: "266", mobile_length: 8 },
{ id: 123, code: "LR", label: "Liberia", phone: "231", mobile_length: 7 },
{ id: 124, code: "LY", label: "Libya", phone: "218", mobile_length: 9 },
{ id: 125, code: "LI", label: "Liechtenstein", phone: "423", mobile_length: 7 },
  { id: 126, code: "LT", label: "Lithuania", phone: "370", mobile_length: 8 },
  { id: 127, code: "LU", label: "Luxembourg", phone: "352", mobile_length: 9 },
  { id: 128, code: "MO", label: "Macao SAR China", phone: "853", mobile_length: 8 },
{ id: 129, code: "MK", label: "Macedonia", phone: "389", mobile_length: 8 },
{ id: 130, code: "MG", label: "Madagascar", phone: "261", mobile_length: 9 },
{ id: 131, code: "MW", label: "Malawi", phone: "265", mobile_length: 9 },
{ id: 132, code: "MY", label: "Malaysia", phone: "60", mobile_length: 9 },
{ id: 133, code: "MV", label: "Maldives", phone: "960", mobile_length: 7 },
{ id: 134, code: "ML", label: "Mali", phone: "223", mobile_length: 8 },
{ id: 135, code: "MT", label: "Malta", phone: "356", mobile_length: 8 },
{ id: 136, code: "MH", label: "Marshall Islands", phone: "692", mobile_length: 7 },
{ id: 137, code: "MQ", label: "Martinique", phone: "596", mobile_length: 9 },
{ id: 138, code: "MR", label: "Mauritania", phone: "222", mobile_length: 9 },
{ id: 139, code: "MU", label: "Mauritius", phone: "230", mobile_length: 7 },
{ id: 140, code: "YT", label: "Mayotte", phone: "262", mobile_length: 9 },
{ id: 141, code: "MX", label: "Mexico", phone: "52", mobile_length: 10 },
{ id: 142, code: "FM", label: "Micronesia", phone: "691", mobile_length: 7 },
{ id: 143, code: "MD", label: "Moldova", phone: "373", mobile_length: 8 },
{ id: 144, code: "MC", label: "Monaco", phone: "377", mobile_length: 8 },
{ id: 145, code: "MN", label: "Mongolia", phone: "976", mobile_length: 8 },
{ id: 146, code: "ME", label: "Montenegro", phone: "382", mobile_length: 8 },
{ id: 147, code: "MS", label: "Montserrat", phone: "+1-664", mobile_length: 7 },
{ id: 148, code: "MA", label: "Morocco", phone: "212", mobile_length: 9 },
{ id: 149, code: "MZ", label: "Mozambique", phone: "258", mobile_length: 9 },
{ id: 150, code: "MM", label: "Myanmar (Burma)", phone: "95", mobile_length: 8 },
{ id: 151, code: "NA", label: "Namibia", phone: "264", mobile_length: 8 },
  { id: 152, code: "NR", label: "Nauru", phone: "674", mobile_length: 5 },
  { id: 153, code: "NP", label: "Nepal", phone: "977", mobile_length: 10 },
  { id: 154, code: "NL", label: "Netherlands", phone: "31", mobile_length: 9 },
{ id: 155, code: "NC", label: "New Caledonia", phone: "687", mobile_length: 6 },
{ id: 156, code: "NZ", label: "New Zealand", phone: "64", mobile_length: 8 },
{ id: 157, code: "NI", label: "Nicaragua", phone: "505", mobile_length: 8 },
{ id: 158, code: "NE", label: "Niger", phone: "227", mobile_length: 8 },
{ id: 159, code: "NG", label: "Nigeria", phone: "234", mobile_length: 10 },
{ id: 160, code: "NU", label: "Niue", phone: "683", mobile_length: 4 },
{ id: 161, code: "NF", label: "Norfolk Island", phone: "672", mobile_length: 6 },
{ id: 162, code: "KP", label: "North Korea", phone: "850", mobile_length: 9 },
{ id: 163, code: "MP", label: "Northern Mariana Islands", phone: "+1-670", mobile_length: 7 },
{ id: 164, code: "NO", label: "Norway", phone: "47", mobile_length: 8 },
{ id: 165, code: "OM", label: "Oman", phone: "968", mobile_length: 8 },
{ id: 166, code: "PK", label: "Pakistan", phone: "92", mobile_length: 10 },
{ id: 167, code: "PW", label: "Palau", phone: "680", mobile_length: 7 },
{ id: 168, code: "PS", label: "Palestinian Territories", phone: "970", mobile_length: 9 },
{ id: 169, code: "PA", label: "Panama", phone: "507", mobile_length: 8 },
{ id: 170, code: "PG", label: "Papua New Guinea", phone: "675", mobile_length: 7 },
{ id: 171, code: "PY", label: "Paraguay", phone: "595", mobile_length: 9 },
{ id: 172, code: "PE", label: "Peru", phone: "51", mobile_length: 9 },
{ id: 173, code: "PH", label: "Philippines", phone: "63", mobile_length: 10 },
{ id: 174, code: "PN", label: "Pitcairn Islands", phone: "870", mobile_length: 4 },
{ id: 175, code: "PL", label: "Poland", phone: "48", mobile_length: 9 },
{ id: 176, code: "PT", label: "Portugal", phone: "351", mobile_length: 9 },
{ id: 177, code: "PR", label: "Puerto Rico", phone: "+1-787 and 1-939", mobile_length: 7 },
  { id: 178, code: "QA", label: "Qatar", phone: "974", mobile_length: 8 },
  { id: 179, code: "CG", label: "Republic of the Congo", phone: "242", mobile_length: 7 },
  { id: 180, code: "RE", label: "Reunion", phone: "262", mobile_length: 9 },
  { id: 181, code: "RO", label: "Romania", phone: "40", mobile_length: 10 },
  { id: 182, code: "RU", label: "Russia", phone: "7", mobile_length: 10 },
  { id: 183, code: "RW", label: "Rwanda", phone: "250", mobile_length: 8 },
  { id: 184, code: "BL", label: "Saint Barthelemy", phone: "590", mobile_length: 9 },
  { id: 185, code: "SH", label: "Saint Helena", phone: "290", mobile_length: 4 },
  { id: 186, code: "KN", label: "Saint Kitts and Nevis", phone: "+1-869", mobile_length: 7 },
  { id: 187, code: "LC", label: "Saint Lucia", phone: "+1-758", mobile_length: 7 },
  { id: 188, code: "MF", label: "Saint Martin", phone: "590", mobile_length: 9 },
  { id: 189, code: "PM", label: "Saint Pierre and Miquelon", phone: "508", mobile_length: 6 },
  { id: 190, code: "VC", label: "Saint Vincent and the Grenadines", phone: "+1-784", mobile_length: 7 },
  { id: 191, code: "WS", label: "Samoa", phone: "685", mobile_length: 7 },
  { id: 192, code: "SM", label: "San Marino", phone: "378", mobile_length: 10 },
  { id: 193, code: "ST", label: "Sao Tome and Principe", phone: "239", mobile_length: 7 },
  { id: 194, code: "SA", label: "Saudi Arabia", phone: "966", mobile_length: 9 },
  { id: 195, code: "SN", label: "Senegal", phone: "221", mobile_length: 9 },
  { id: 196, code: "RS", label: "Serbia", phone: "381", mobile_length: 8 },
  { id: 197, code: "SC", label: "Seychelles", phone: "248", mobile_length: 7 },
  { id: 198, code: "SL", label: "Sierra Leone", phone: "232", mobile_length: 8 },
  { id: 199, code: "SG", label: "Singapore", phone: "65", mobile_length: 8 },
  { id: 200, code: "SX", label: "Sint Maarten", phone: "1-721", mobile_length: 7 },
  { id: 201, code: "SK", label: "Slovakia", phone: "421", mobile_length: 9 },
  { id: 202, code: "SI", label: "Slovenia", phone: "386", mobile_length: 8 },
  { id: 203, code: "SB", label: "Solomon Islands", phone: "677", mobile_length: 7 },
  { id: 204, code: "SO", label: "Somalia", phone: "252", mobile_length: 8 },
{ id: 205, code: "ZA", label: "South Africa", phone: "27", mobile_length: 9 },
{ id: 206, code: "GS", label: "South Georgia and the South Sandwich Islands", phone: "500", mobile_length: 5 },
{ id: 207, code: "KR", label: "South Korea", phone: "82", mobile_length: 10 },
{ id: 208, code: "SS", label: "South Sudan", phone: "211", mobile_length: 9 },
{ id: 209, code: "ES", label: "Spain", phone: "34", mobile_length: 9 },
{ id: 210, code: "LK", label: "Sri Lanka", phone: "94", mobile_length: 9 },
{ id: 211, code: "SD", label: "Sudan", phone: "249", mobile_length: 9 },
{ id: 212, code: "SR", label: "Suriname", phone: "597", mobile_length: 7 },
{ id: 213, code: "SJ", label: "Svalbard and Jan Mayen", phone: "47", mobile_length: 8 },
{ id: 214, code: "SZ", label: "Swaziland", phone: "268", mobile_length: 8 },
{ id: 215, code: "SE", label: "Sweden", phone: "46", mobile_length: 9 },
{ id: 216, code: "CH", label: "Switzerland", phone: "41", mobile_length: 9 },
{ id: 217, code: "SY", label: "Syria", phone: "963", mobile_length: 9 },
{ id: 218, code: "TW", label: "Taiwan", phone: "886", mobile_length: 9 },
{ id: 219, code: "TJ", label: "Tajikistan", phone: "992", mobile_length: 9 },
{ id: 220, code: "TZ", label: "Tanzania", phone: "255", mobile_length: 9 },
{ id: 221, code: "TH", label: "Thailand", phone: "66", mobile_length: 9 },
{ id: 222, code: "TG", label: "Togo", phone: "228", mobile_length: 8 },
{ id: 223, code: "TK", label: "Tokelau", phone: "690", mobile_length: 5 },
{ id: 224, code: "TO", label: "Tonga", phone: "676", mobile_length: 7 },
{ id: 225, code: "TT", label: "Trinidad and Tobago", phone: "+1-868", mobile_length: 7 },
{ id: 226, code: "TN", label: "Tunisia", phone: "216", mobile_length: 8 },
{ id: 227, code: "TR", label: "Turkey", phone: "90", mobile_length: 10 },
  { id: 228, code: "TM", label: "Turkmenistan", phone: "993", mobile_length: 8 },
  { id: 229, code: "TC", label: "Turks and Caicos Islands", phone: "+1-649", mobile_length: 7 },
  { id: 230, code: "TV", label: "Tuvalu", phone: "688", mobile_length: 5 },
{ id: 231, code: "UG", label: "Uganda", phone: "256", mobile_length: 9 },
{ id: 232, code: "UA", label: "Ukraine", phone: "380", mobile_length: 9 },
{ id: 233, code: "AE", label: "United Arab Emirates", phone: "971", mobile_length: 9 },
{ id: 234, code: "GB", label: "United Kingdom", phone: "44", mobile_length: 10 },
{ id: 235, code: "US", label: "United States", phone: "1", mobile_length: 10 },
{ id: 236, code: "UM", label: "United States Minor Outlying Islands", phone: "1", mobile_length: 10 },
{ id: 237, code: "UY", label: "Uruguay", phone: "598", mobile_length: 8 },
{ id: 238, code: "UZ", label: "Uzbekistan", phone: "998", mobile_length: 9 },
{ id: 239, code: "VU", label: "Vanuatu", phone: "678", mobile_length: 7 },
{ id: 240, code: "VA", label: "Vatican", phone: "379", mobile_length: 10 },
{ id: 241, code: "VE", label: "Venezuela", phone: "58", mobile_length: 10 },
{ id: 242, code: "VN", label: "Vietnam", phone: "84", mobile_length: 10 },
{ id: 243, code: "VG", label: "Virgin Islands, British", phone: "+1-284", mobile_length: 7 },
{ id: 244, code: "VI", label: "Virgin Islands, U.S.", phone: "+1-340", mobile_length: 7 },
{ id: 245, code: "WF", label: "Wallis and Futuna", phone: "681", mobile_length: 6 },
{ id: 246, code: "EH", label: "Western Sahara", phone: "212", mobile_length: 9 },
{ id: 247, code: "YE", label: "Yemen", phone: "967", mobile_length: 9 },
{ id: 248, code: "ZM", label: "Zambia", phone: "260", mobile_length: 9 },
{ id: 249, code: "ZW", label: "Zimbabwe", phone: "263", mobile_length: 9 },
]
// Customizable Area End