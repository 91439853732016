// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { image_for_profile_1, image_for_profile_2 } from "./assets";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    test?: any;
    context?: any;
    classes?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    friendRequest: any
    notificationData:any
    profileImg:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
// Customizable Area Start
const frienRequestData = [
    {
        id: 1,
        name: "George Welch",
        image: image_for_profile_1,
        time: "30 minutes ago"
    },
    {
        id: 2,
        name: "Kelly Moren",
        image: image_for_profile_2,
        time: "30 minutes ago"
    }
]
// Customizable Area End

// Customizable Area Start
export default class NotificationBoxController extends BlockComponent<Props, S, SS> {

    notificationDetailCallId:string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            friendRequest: frienRequestData,
            notificationData:[],
            profileImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.getNotificationDetail()
        // Customizable Area End
    }
    // Customizable Area Start
    getToken = () => {
        const token = localStorage.getItem("accessToken")
        return token
    }

    getNotificationDetail = () => {
        const headers = {
            "Content-Type": configJSON.apiContentType,
            token:  this.getToken()
        };

        const profileDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.notificationDetailCallId = profileDataMessage.messageId;

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.notificationDetailApiEndPoint
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getDataMethod
        );

        runEngine.sendMessage(profileDataMessage.id, profileDataMessage);
    }

    handleResponseForNotificationDetail = (from: string, message: Message) => {
        
        if (this.notificationDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                console.log(apiResponse.errors)


            } else {
                this.setState({notificationData:apiResponse.data})
            }
        }
    }
    async receive(from: string, message: Message) {
        
        // Customizable Area Start
        this.handleResponseForNotificationDetail(from, message)
        // Customizable Area End
    }
    // Customizable Area End

}
// Customizable Area End
