import React from "react";
// Customizable Area Start
import "./Faqs.scss";

// Customizable Area End

import SettingsController, { Props } from "./SettingController";

export default class AboutUs extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="faqs">
        <h1 className={`${this.props.context.isDark ? "color-white" :""} about-heading-text`} >About ESFERA</h1>
        <div className="faq-sec">
          <div className="faq-inner-sec">
            <p className={`about-details-text ${this.props.context.isDark ? "color-white" :""}`} >
              {" "}
              At Esfera, we strive to create a bridge between traditional and
              blockchain gaming, creating a vibrant economy within the gaming
              world where users can earn, exchange, play and learn in a safe and
              inclusive ecosystem that is adaptable to all their gaming needs.
              The value at our core is to prioritize users first to provide them
              with access to the blockchain space while including the aspects
              that they know and love from classical games and gaming platforms.
            </p>
            <p className={`${this.props.context.isDark ? "color-white" :""}`} >
              These familiarities help to create Esfera where people can not
              only learn the value of good fun with friends but also come to
              understand the basics of finance so that they can build real world
              value. We are doing this by creating a launcher in which gamers
              can be inspired to explore virtual worlds and build deeper
              connections through gaming. Developing tightly bound communities
              that not only play games but help to create them as well. Through
              our wallet and financial services we hope to empower people to
              create and understand cryptocurrency and the technology which
              secures it creating a uniquely educated community that is open to
              everyone. Through the power of NFTs and our in-app NFT market we
              further aspire to give power to the gamers and users of our
              platform by allowing them to hold assets themselves that hold real
              world value allowing them to use in-game items cross game and
              creating a new system of gaming that is fair to everyone and open
              to everyone
            </p>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

