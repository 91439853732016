/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import ThemeContext from "../../../components/src/ThemeContext.web";
import SearchBar from "../../../components/src/SearchBar.web";
import ExploreCollectionCard from "./ExploreCollectionCard.web";
import "./ExploreCollection.scss"
import MakeAnOfferModal from "./MakeAnOfferModal.web";
import BuyNftModal from "./BuyNftModal.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class ExploreNftCollection extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
   
    render() {
        return (
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation} selected="NFT Market Place" />
                <Grid container className={`main-container item-collect app-page-dashboard ${this.context.isDark ? "dark-color" : ""}`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`container-items item-collect ${this.context.isDark ? "dark-color" : ""}`}
                    >
                        <Navbar context={this.context} title="Explore Collections" navigation={this.props.navigation} />
                        <SearchBar data-test-id="search" placeholder="Search for NFT" value={this.state.search} onChange={this.handleSearchChange} />
                        {this.state.exploreData.length === 0 ? <div>loading...</div> :
                            <div className="explore-collection-main scroller scroller-width">
                                {this.state.exploreData.map(item => (
                                    <div key={item.id} className="explore-card">
                                        <ExploreCollectionCard item={item} />
                                    </div>
                                ))}
                            </div>
                       }
                    </Grid>
                </Grid>
                <MakeAnOfferModal
                    open={this.state.open}
                    onClose={this.handleCloseModal}
                />
                 <BuyNftModal
                    open={this.state.openNftModal}
                    onClose={this.handleCloseNftModal}
                />
            </>
        );
    }
}
export default ExploreNftCollection;

ExploreNftCollection.contextType = ThemeContext;

// Customizable Area End
