/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DataListItem } from "./FollowersController";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");


export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    context?: any;
    loading?: any
    feedComments?: any
    fetchCommentsData?: any
    postNewComments?: any
    likeFeed?: any
    deleteLikeFeed?: any
    userLikes?: any
    replyComment?: any
    deleteComments?: any;
    item?: any;
    allComments?: any;
    myLoader?: any;
    message?: string
    textareaRef: any
    handleOpenChat?:any
    specificAccountActivity?:any

    // Customizable Area End
}
interface S {
    userPostList: any;
    loading: boolean;
    // Customizable Area Start
    feedComments: any;
    userLikes: any;
    postData: any
    fetchCommentsData?: any
    deleteLikeFeed?: any
    commentId: string
    userFollowingPost: any;
    errorMsg: string;
    accountListData: DataListItem[],
    profile_pic: string;
    specific_account: any;
    specific_account_post: any;
    activeItem?: string;
    query: string;
    currentUser: any;
    message: string;
    openAllComment: string
    selectedUserProfile: any;
    error:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class FriendsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    likePostCallId: string = "";
    deleteLikeCountCallId: string = "";
    allLikesPostCallId: string = "";
    fetchAllCommentsApiId: string = "";
    addNewCommentCallId: string = "";
    replyOnCommentCallId: string = "";
    deletePostCommentCallId: string = "";
    // userFollowingPostCallId: string = "";
    textareaRef: any;
    accountListCallId: string = "";
    specificAccountCallId: string = "";
    UnFollowUserCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            userPostList: [],
            loading: false,
            feedComments: [],
            userLikes: [],
            postData: {},
            commentId: "",
            userFollowingPost: [],
            errorMsg: "",
            accountListData: [],
            profile_pic: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
            specific_account: {},
            specific_account_post: [],
            activeItem: this.state?.activeItem,
            query: "",
            message: "",
            openAllComment: "",
            selectedUserProfile: "",
            error:"",
            //@ts-ignore
            currentUser: JSON.parse(localStorage.getItem("profileData") || null),
        };
        this.textareaRef = React.createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }





    // Customizable Area Start

    componentDidMount(): any {
        // Customizable Area Start
        this.getAllPostLikes()
        this.getAccountListData()

        // Customizable Area End
    }
    handleOpenAllComments = (id: any) => {
        this.setState({ openAllComment: id })
    }

    handleOpenChat = (email: string) => {
        this.props.navigation.history.push({pathname: "ActivityFeed", state:  {
            currentTab: "chat",
            email
        }})
    }

    deletePostComment = (id: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken(),
        };
        const deleteCommentMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deletePostCommentCallId = deleteCommentMessage.messageId;

        deleteCommentMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCommentsApiEndPoint + `/${id}`
        );

        deleteCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        deleteCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getDeleteCommentApiMethod
        );

        runEngine.sendMessage(deleteCommentMessage.id, deleteCommentMessage);

        return true;
    }

    replyOnComment = ({ parentId, globalPostId, message }: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken(),
        };

        const data = {
            parent_id: parentId,
            post_id: globalPostId,
            description: message
        };
        let httpBody = {
            data: data,
        };

        const replyCommentMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.replyOnCommentCallId = replyCommentMessage.messageId;

        replyCommentMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.replyCommentApiEndPoint
        );

        replyCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        replyCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        replyCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(replyCommentMessage.id, replyCommentMessage);

        return true;
    }

    deletePostCommentResponseHandler = (from: string, message: Message) => {
        if (this.deletePostCommentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({error:"post not found"})
                

            } else {
                setTimeout(() => {
                    this.fetchAllCommentsData(this.state.commentId)
                })

            }
        }
    }

    replyOnCommentResponseHandler = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.replyOnCommentCallId &&
                responseJson !== undefined
            ) {
                const mappedPostComments = this.state.feedComments?.map((comment: any) => {
					if (comment.id == responseJson.data?.attributes.parent_id) {
						return {
							...comment,
							attributes: {
								...comment.attributes,
								comment_reply: [...comment.attributes.comment_reply, responseJson.data]
							}
						}
					}
					return comment;
				})
				this.setState({ feedComments: mappedPostComments })
            }
        }
    }

    getToken = () => {
        const token = localStorage.getItem("accessToken");
        return token;
    };
    updateLikeFeedData = (apiResponse: any, state: any) => {
        const specific_account_post = state.specific_account_post;

        const updateLIkeFeed = specific_account_post?.map((obj: any) => {
            if (obj.id == apiResponse?.data?.attributes.likeable_id) {
                obj.attributes.likes += 1;
            }else if(obj.id == apiResponse?.post_id){
                obj.attributes.likes -= 1;
            }
            return obj;
        });

        return updateLIkeFeed;
    }
    handleLikeFeedResponse = (from: string, message: Message) => {
        if (this.likePostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            if (apiResponse) {
                this.setState({ specific_account_post: this.updateLikeFeedData(apiResponse, this.state) })
                this.getAllPostLikes()
            }
        }
    }

    handleDeleteLikeFeedResponse = (from: string, message: Message) => {
        if (this.deleteLikeCountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            if (apiResponse) {
                this.setState({ specific_account_post: this.updateLikeFeedData(apiResponse, this.state) })
                this.getAllPostLikes()
            }
        }
    }
    handleFeedAllLikesResponse = (from: string, message: Message) => {
        if (this.allLikesPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            if (apiResponse) {
                this.setState({ userLikes: apiResponse.data, loading: false });
            }
        }
    }

    fetchAllCommentsData = (id: any) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": this.getToken(),
        };

        this.setState({ commentId: id })
        const fetchAllCommentMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchAllCommentsApiId = fetchAllCommentMessage.messageId;

        fetchAllCommentMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchUserCommentsApiEndPoint + `/${id}`
        );

        fetchAllCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        fetchAllCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethodForUserData
        );

        runEngine.sendMessage(fetchAllCommentMessage.id, fetchAllCommentMessage);

        return true;
    }

    addNewComments = (id: number, description: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken(),
        };

        const data = {
            post_id: id,
            description: description
        };
        let httpBody = {}

        httpBody = {
            data: data,
        };

        const addNewCommentMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addNewCommentCallId = addNewCommentMessage.messageId;

        addNewCommentMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCommentsApiEndPoint
        );

        addNewCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        addNewCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        addNewCommentMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(addNewCommentMessage.id, addNewCommentMessage);

        return true;
    }
    likePost = (id: string) => {

        let data = {
            data: {
                likeable_id: id

            }
        }

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };

        const likeDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.likePostCallId = likeDataMessage.messageId;

        likeDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeFeed
        );

        likeDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        )

        likeDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        likeDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );

        runEngine.sendMessage(likeDataMessage.id, likeDataMessage);
    }

    getAllPostLikes = () => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };

        const allLikesMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.allLikesPostCallId = allLikesMessage.messageId;

        allLikesMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeFeed
        );

        allLikesMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        allLikesMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(allLikesMessage.id, allLikesMessage);
    }
    deleteLikeCount = (likedPost: any) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };

        const deleteLikeCountMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteLikeCountCallId = deleteLikeCountMessage.messageId;

        deleteLikeCountMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.likeFeed + `/${likedPost?.id}`
        );

        deleteLikeCountMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        deleteLikeCountMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'DELETE'
        );

        runEngine.sendMessage(deleteLikeCountMessage.id, deleteLikeCountMessage);
    }
    deleteLikePost = (id: string) => {
        let likedPost = this.state.userLikes.find((item: any) => item.attributes.likeable_id == id)
        this.deleteLikeCount(likedPost)

    }

    fetchCommentResponseHandler = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.fetchAllCommentsApiId &&
                responseJson !== undefined
            ) {
                this.setState({ feedComments: responseJson.data })
            }
        }
    }
    updateFeedData = (responseJson: any, state: any) => {
        const specific_account_post = state.specific_account_post;

        const updatedFeedData = specific_account_post?.map((obj: any) => {
            if (obj.id == responseJson?.data?.attributes.post_id) {
                obj.attributes.comments += 1;
            }
            return obj;
        });

        return updatedFeedData;
    }

    handlePostCommentsResponseHandler = (from: string, message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null) {
            if (
                apiRequestCallId === this.addNewCommentCallId &&
                responseJson !== undefined
            ) {
                this.setState({ feedComments: [...this.state.feedComments, responseJson.data], specific_account_post: this.updateFeedData(responseJson, this.state) })
            }
        }
    }

    getAccountListData = () => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };

        const accountListDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.accountListCallId = accountListDataMessage.messageId;

        accountListDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followingApiEndPoint}${this.state.currentUser?.id}`
        );

        accountListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        accountListDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(accountListDataMessage.id, accountListDataMessage);
    }
    specificAccountActivity = (account?: any) => {
        this.setState({ activeItem: account.id, selectedUserProfile: account?.attributes?.email })
        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()

        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account.id

                },
            }
        }
        const specificAccountDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.specificAccountCallId = specificAccountDataMessage.messageId;

        specificAccountDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.specificAccountApiEndPoint
        );

        specificAccountDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        specificAccountDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        specificAccountDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(specificAccountDataMessage.id, specificAccountDataMessage);

    }
    handleResponseAccountList = (from: string, message: Message) => {

        if (this.accountListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse) {
                this.setState({ accountListData: apiResponse.data, loading: false })
                if (this.state.accountListData.length > 0) {
                    this.specificAccountActivity(this.state?.accountListData[0])
                } else if (this.state.accountListData.length === 0) {
                    this.setState({ specific_account: {}, specific_account_post: [] })
                }

            }
        }
    }
 
    unFollowUser = (account_id: string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }

        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UnFollowUserCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteFollowApiEndPoint}/${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }
    handleResponseForSpecificAccount = (from: string, message: Message) => {
        if (this.specificAccountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.setState({ specific_account: apiResponse?.data, specific_account_post: apiResponse?.data?.attributes?.posts, message: apiResponse?.meta.message, loading: false })

            }
        }
    }
    handleResForunFollowUser = (from: string, message: Message) => {
        if (this.UnFollowUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ message: apiResponse.error })


            } else {
                this.getAccountListData()

            }
        }

    }
    async receive(from: string, message: Message) {

        this.handlePostCommentsResponseHandler(from, message);
        this.fetchCommentResponseHandler(from, message);
        this.handleLikeFeedResponse(from, message);
        this.handleFeedAllLikesResponse(from, message);
        this.handleDeleteLikeFeedResponse(from, message);
        this.replyOnCommentResponseHandler(from, message);
        this.deletePostCommentResponseHandler(from, message);
        this.handleResponseAccountList(from, message)
        this.handleResponseForSpecificAccount(from, message)
        this.handleResForunFollowUser(from, message)
    }

    // Customizable Area End
}
// Customizable Area End
