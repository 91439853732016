/** @format */

import React from "react";
// Customizable Area Start
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class ExploreCollectionCard extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <div className="explore-img-container">
                    <img src={this.props.item?.image} alt={this.props.item?.text} />
                </div>
                <div className="explore-avatar-text">
                    <div className="avatar-explore">
                        <img src={this.props.item?.avatar} alt={this.props.item?.avatar} />
                    </div>
                    <h3>{this.props.item?.text}</h3>
                </div>
            </>
        );
    }
}
export default ExploreCollectionCard;

ExploreCollectionCard.contextType = ThemeContext;

// Customizable Area End
