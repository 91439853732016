/** @format */

import React from "react";
// Customizable Area Start
import ActivityFeedController from "./ActivityFeedController";
import { FeedView } from "../../../components/src/FeedView.web";
import ContentLoader from 'react-content-loader'
import ThemeContext from "../../../components/src/ThemeContext.web";
import { CreatePostModal } from "../../../components/src/CreatePostModal.web";


// Customizable Area End

// Customizable Area Start
class FeedTab extends ActivityFeedController {
	constructor(props: any) {
		super(props)
	}

	MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
		{Array(4).fill(4).map((item, i) => <ContentLoader key={i} />)}
	</div>)
	
	feedTabData = (item: any, index: any) => (
		<FeedView
			context={this.context}
			allComments={this.props.postComments}
			fetchCommentsData={this.props.fetchCommentsData}
			postNewComments={this.props.postNewComments}
			likeAction={this.props.likeFeed}
			deleteLikeAction={this.props.deleteLikeFeed}
			key={index}
			textareaRef={this.props.textareaRef}
			uploadImgRef={this.props.uploadImgRef}
			item={item}
			userLikes={this.props.userLikes}
			deleteFeed={this.props.deleteFeed}
			handleEdit={this.handleEdit}
			postModalVisible={this.state.postModalVisible}
			createPostImageData={this.state.createPostImageData}
			handleFileUpload={this.handleFileUpload}
			handleCloseModel={this.handleCloseModel}
			createPost={this.createPost}
			closeFileHandler={this.closeFileHandler}
			loggedInUser={this.state.loggedInUser}
			replyComment={this.props.replyComment}
			deleteComments={this.props.deleteComments}
			unFollowUserFromPost={this.props.unFollowUserFromPost}
			followingUserFromPost={this.props.followingUserFromPost}
			openAllComment={this.props.openAllComment}
			handleOpenAllComments={() => this.props.handleOpenAllComments(item.id)}
		/>
	)
	render() {
		return (
			<div id={"scrollableDiv"} className={`scroller scroller-width`} ref={this.props.scrollRef} onScroll={this.props.scrollHandle}>
				{this.props?.feedData?.length === 0 ? this.MyLoader() :
					this.props.feedData?.map(this.feedTabData)
				}
				<CreatePostModal
					postModalVisible={this.state.postModalVisible}
					textareaRef={this.textareaRef}
					uploadImgRef={this.uploadImgRef}
					createPostImageData={this.state.createPostImageData}
					handleFileUpload={this.handleFileUpload}
					context={this.context.isDark}
					handleCloseModel={this.handleCloseModel}
					createPost={this.createPost}
					closeFileHandler={this.closeFileHandler}
					loggedInUser={this.state.loggedInUser}
					postTextValue={this.state.postTextValue}
					setPostText={this.setPostText}
					isEditPost={this.state.isEditPost}
					editPost={this.editPost}
				/>
			</div>
		);
	}
}

export default FeedTab;
FeedTab.contextType = ThemeContext;
// Customizable Area End
