/** @format */

import React from "react";
// Customizable Area Start
import UserProfileDetailController, {
	Props,
} from "./UserProfileDetailController";
import "./UserActivity.scss";
import ThemeContext from "../../../components/src/ThemeContext.web";
import UserProfileCard from "../../../components/src/UserProfileCard";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class UserProfileDetail extends UserProfileDetailController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	render() {
		return (
			<UserProfileCard userData={this.state.userData} context={this.context} />
		);
	}
}
export default UserProfileDetail;
UserProfileDetail.contextType = ThemeContext;

// Customizable Area End
