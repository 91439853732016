/** @format */
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Client } from 'twilio-chat';

// Customizable Area Start
import {
	profileImg1,
} from "../../dashboard/src/assets";
import { createConsumer } from '@rails/actioncable';
import { debounce } from 'lodash'
// Customizable Area End 

// Customizable Area Start
export const configJSON = require("./config");


export interface Props {
	targetEmail?:string;
	navigation?: any;
	id?: string;
	// Customizable Area Start
	history?: any;
	userChatList?: any;
	searchData?: any;
	isFetchingChatList?: boolean
	// Customizable Area End
}

interface S {
	addNewSearch:string ,
	userData: any;
	newChatModalVisible: boolean;
	searchData: any;
	// Customizable Area Start
	newChannelData: any;
	newChannelCreated: boolean;
	userChatList: any;
	isFetchingChatList: boolean;
	isFetchingUsersForChat: boolean;
	isCreatingNewChannel: boolean;
	messages: any;
	newMessage: string;
	loading: boolean;
	chatEmojiPickerVisible: boolean;
	channel: any;
	profileData: any;
	error: any
	twilioToken: string;
	currentSelectedUser: any;
	searchValue: any;
	chatImages: any;
	chatAttachment: any;
	twilioChatList: any;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class ChatListWebController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	fetchUserForChatApi: string = "";
	createChannelForNewUser: string = "";
	getTwilioTokenApi: string = "";
	fetchUserChatListApi: string = "";
	TwilioRef: any;
	chatChannel: any;
	messagesEndRef: any;
	uploadImgRef: any;
	textareaRef: any;
	myConsumerRef:any;
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];

		this.state = {
			addNewSearch:"",
			userData: null,
			newChatModalVisible: false,
			searchData: null,
			newChannelData: null,
			newChannelCreated: false,
			userChatList: [],
			isFetchingChatList: true,
			isFetchingUsersForChat: false,
			isCreatingNewChannel: false,
			messages: [],
			newMessage: '',
			loading: false,
			chatEmojiPickerVisible: false,
			channel: null,
			profileData: null,
			error: "",
			twilioToken: "",
			currentSelectedUser: {},
			searchValue: "",
			chatImages: [],
			chatAttachment: "",
			twilioChatList: ""
		};

		this.TwilioRef = React.createRef();
		this.chatChannel = React.createRef();
		this.messagesEndRef = React.createRef();
		this.uploadImgRef = React.createRef();
		this.textareaRef = React.createRef();
		this.myConsumerRef = React.createRef();

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area End
	}


	// Customizable Area Start

	handleSearch = (event: any) => {
		const data = event.target.value;
		this.setState({ searchValue: data })
		if (data) {
			let searchArray = this.state.userChatList.filter((item: any) => item.name.toLowerCase().includes(data.toLowerCase()))
			this.setState({ searchData: searchArray });
		} else {
			this.setState({ searchData: [] })
		}
	}

	async componentDidMount() {
		// fetch chat list api
		await this.setupTwilio();
		this.fetchCurrentUser();
		this.getLastMessage();
		this.connectWithSocket()
		this.fetchUserChatList(null, 'foreground')
		this.callHandleRedirectionOfChat();
	}

	callHandleRedirectionOfChat = ( ) => {
		setTimeout(() => {
			this.handleRedirectionOfChat()
		},2000)
	}

	handleRedirectionOfChat = () => {
		const emailInHistory = this.props.navigation?.history?.location?.state?.email;
		const emailInProps = this.props.targetEmail
		const emailParams = emailInProps || emailInHistory ;
		if (emailParams) {
			let targetUserEmail = emailParams;
			let userChatList = [...this.state.userChatList];
			let targetUserIndex = userChatList.findIndex((user) => user.email === targetUserEmail);
			if (targetUserIndex < 0) {
				this.getNewChatWithUser(targetUserEmail)
			} else {
				const targetUser = userChatList.splice(targetUserIndex, 1)[0];
				userChatList.unshift(targetUser);
				this.setState({ userChatList: userChatList }, () => {
					this.getMessage(userChatList[0])
				})
			}
		}
	}

	connectWithSocket = async () => {
		const URL = 'wss://esfera-163031-ruby.b163031.dev.eastus.az.svc.builder.cafe/cable';
		const consumer = createConsumer(URL);
		this.myConsumerRef.current = consumer;
		consumer.subscriptions.create({
			channel: 'EventTriggerChannel',
		}, {
			connected: this.connected,
			disconnected: this.disconnected,
			received: this.received,
		})
	}

	connected =  () => console.log('connected')

	disconnected = () => console.log('disconnected')

	received = async (data:any) => {				
		if(data.current_user_email !== this.state.profileData.email) {
			this.fetchUserChatList({channel_id: data.channel, email: data.email }, "background");
		}
	}

	async componentWillUnmount() {
		this.disconnectTwillo()
	}

	debaunceCallback = (searchData:string) => {
		if(searchData.length){
			this.fetchUserForChat(searchData);
		}
	}

	disconnectTwillo = async () => {
		try {
			await this.myConsumerRef.current.consumer.disconnect()
			const client = this.TwilioRef.current;
			if (client) {
				await client.shutdown(); // Destroy the Twilio.Chat.Client object
			}
		} catch (error) {
			this.setState({ error: "Failed to destroy chat" })
		}
	}

	handleDebauncingNewSearch = debounce(this.debaunceCallback ,500) 

	handleAddNewSearch = (event : any ) => {
		const data = event.target.value;
		this.setState({addNewSearch : data})
		this.handleDebauncingNewSearch( data)
	}

	fetchCurrentUser = async () => {
		//@ts-ignore
		const userInfo = JSON.parse(localStorage.getItem("profileData"))
		const userEmail = localStorage.getItem("email");
		this.setState({ profileData: { ...userInfo, email: userEmail } })
	}

	getTwilioToken = async () => {
		try {
			const response = await fetch('https://esfera-163031-ruby.b163031.dev.eastus.az.svc.builder.cafe/bx_block_chat9/generate_chat_token', {
				method: "POST",
				//@ts-ignore
				headers: {
					token: this.getToken(),
					'Content-Type': 'application/json'
				}
			});
			const data = await response.json();
			return data.token
		} catch (error) {
			this.setState({ error: error })
		}
	}

	setupTwilio = async () => {
		try {
			const token = await this.getTwilioToken();
			const chatClient = new Client(token);

			this.TwilioRef.current = chatClient;

			chatClient.on("messageAdded"  , async (message:any) => {
				const { items } = await this.chatChannel?.current?.getMessages();
				this.setState({ messages:items});
			});
			chatClient.on('tokenAboutToExpire', async () => {
				const token = await this.getTwilioToken();

				await chatClient.updateToken(token);
				this.TwilioRef.current = chatClient;
			});
		} catch (error: any) {
			console.log("error in create chaneel")
		}

	}

	sortingChatList = (chatlist: any) => {
		let chatlistModified = JSON.parse(chatlist)
		const newSorted = chatlistModified.sort((a: any, b: any) => {
			const { lastMessageDate , dateCreated } = a;
			const { lastMessageDate:blastMessageDate, dateCreated:bdateCreated } = b;
			let aDate: any = lastMessageDate ? new Date(lastMessageDate) : new Date(dateCreated);
			let bDate: any = blastMessageDate ? new Date(blastMessageDate) : new Date(bdateCreated);
			// Compare by lastMessageDate, if defined
			if (aDate && bDate) {
				if (aDate > bDate) {
					return -1
				} else if (aDate < bDate) {
					return 1
				} else {
					return 0
				}
			}
			return bDate - aDate;
		});

		return newSorted;
	}
	async getAllChannelMember(allChannels: any, allMembers: any) {
		if (!allChannels.items.length) {
			this.setState({ isFetchingChatList: false, loading: false });
			return;
		}

		const getLastMessagePromises = []
		for (let i = 0; i < allChannels.items.length; i++) {
			let message = allChannels.items[i].getMessages(1)
			getLastMessagePromises.push(message)
		}

		const allMessages = await Promise.all(getLastMessagePromises)

		allChannels.items.forEach(async (member: any, index: number) => {
			let singleMember = {
				name: member.attributes.name,
				profileImg: member.attributes?.profile_pic?.url || profileImg1,
				uniqueChannelName: allChannels.items[index]?.sid,//need token .. 
				email: member.attributes?.email,
				lastMessageDate: allChannels.items[index].channelState?.lastMessage?.dateCreated,
				dateCreated: allChannels.items[index]?.channelState?.dateCreated,
				lastMessage: allMessages[index]?.items[0]?.state?.body
			}
			allMembers.push(singleMember)
		})

		let myTwilioChatList = this.sortingChatList(JSON.stringify(allMembers));
		return myTwilioChatList

	}

	getTwilioChatList = async () => {
		if (this.TwilioRef.current) {
			// this.forNewChannelJoined()
			const allChannels = await this.TwilioRef.current.getSubscribedChannels();
			return allChannels.items;
		}
	}

	
	//backend chat list 
	fetchUserChatList = (body: any, mode: string) => {
		mode === "forground" && this.setState({ isFetchingChatList: true });
		const header = {
			"Content-Type": configJSON.exampleApiContentType,
			"token": this.getToken(),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.fetchUserChatListApi = requestMessage.messageId;

		mode === "background" ? requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.fetchUserChatListApiEndPoint}?channel_id=${body.channel_id}&email=${body.email}`
		) : requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.fetchUserChatListApiEndPoint
		);


		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);


		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getMethodForUserData
		);


		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	getMinutesDifference(startDate: Date, endDate: Date): number {
		const startMillis = startDate.getTime();
		const endMillis = endDate.getTime();
		const diffMillis = endMillis - startMillis;
		const minutes = Math.floor(diffMillis / (1000 * 60)); // Milliseconds to minutes conversion
	  
		return minutes;
	  }

	handleChatUIResponse = ( index:number) => {

		if(index === this.state.messages.length - 1) {
			return true;
		} else {

			const currentUserMessage = this.state.messages[index]?.state?.author;
			const nextUserMessage = this.state.messages[index + 1]?.state?.author;
			
			if(currentUserMessage === nextUserMessage) {
				let currentDate = this.state.messages[index]?.state?.dateUpdated;
				let nextDate = this.state.messages[index + 1]?.state?.dateUpdated;
				currentDate = new Date(currentDate);
				nextDate   = new Date(nextDate);
				const diff =  this.getMinutesDifference(  currentDate , nextDate) 
				return diff > 5 ? true : false;
		
			} else {
				return true;
			}
		}

	}

	handleLastMessage = async (allChannels : any) => {
		const getLastMessagePromises = []
		for (let i = 0; i < allChannels.length; i++) {
			let message = allChannels[i].getMessages(1)
			getLastMessagePromises.push(message)
		}

		const allMessages = await Promise.all(getLastMessagePromises)
		return allMessages;
	}
	//handle backend send
	handleFetchUserChatListResponse = async (from: string, message: Message) => {
		
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.fetchUserChatListApi &&
				responseJson !== undefined
			) {
				await this.handleMappedChatListData(responseJson  )
			}
		}
	}

	handleMappedChatListData = async (responseJson:any) => {
		let myTwilioChatList:any = await this.getTwilioChatList();
		let lastMessage:any = myTwilioChatList?.length ? await this.handleLastMessage(myTwilioChatList) : [];
		lastMessage.reverse()
		const sendersChatlist = responseJson?.senders_chatlist
		const mappedChatListData = ( sendersChatlist ? sendersChatlist.data : responseJson?.data)?.map(({ id, attributes }: any , index:number) => {
			const secondParticipant = attributes.second_participant

			let lastMessageInfo = lastMessage.length ?
				lastMessage.find((data:any)  => {
					const targetEmail = data.items[0]?.channel?.channelState?.attributes?.email;
					if(targetEmail === secondParticipant) return true;
				})
				:
				{}
			
			const dateCreated = myTwilioChatList.length ? 
				myTwilioChatList.find((data:any)  => {
					const targetEmail = data.channelState?.attributes?.email
					if(targetEmail === secondParticipant) return true;
				}) :
				{};

			if(!lastMessageInfo && ! dateCreated?.channelState?.dateCreated) {
				const currentUserEmail = this?.state?.profileData?.email ;
				lastMessageInfo = lastMessage.length ?
					lastMessage.find((data:any)  => {
						const targetEmail = data.items[0]?.channel?.channelState?.attributes?.email ;
						if( targetEmail === currentUserEmail) return true;
					})
					:
					{}
			}

			let lastMessageDate =  lastMessageInfo?.items ? lastMessageInfo?.items[0]?.channel?.channelState?.lastMessage?.dateCreated : '' ;
			let lastMess =   lastMessageInfo?.items ? lastMessageInfo?.items[0]?.state?.body : ''

			return {
				id: id,
				name: attributes.user_name,
				profileImg: attributes?.profile_pic?.url || profileImg1,
				uniqueChannelName: attributes.channel_sid,//need token .. 
				email: secondParticipant,
				lastMessageDate,
				dateCreated: dateCreated?.channelState?.dateCreated ,
				lastMessage:lastMess ,
			}
		})

		mappedChatListData?.length ? this.setState({ userChatList: mappedChatListData, isFetchingChatList: false }, () => {
			this.getMessage(mappedChatListData[0]);
		}) : this.setState({
			isFetchingChatList: false
		})
	}

	scrollToBottom = () => {
		if (this.messagesEndRef.current != null) {
			this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
		}
	}


	joinChannel = async (channel: any, selectedUser: any) => {
		try {
			if (channel.channelState.status !== "joined") {
				await channel.join();
				const messages = await channel.getMessages();
				this.setState({ messages: messages.items, loading: false, currentSelectedUser: selectedUser }, () => {
				});
			} else {
				const messages = await channel.getMessages();
				this.setState({ messages: messages.items, loading: false, currentSelectedUser: selectedUser }, () => {
					this.scrollToBottom();
				});
			}
		} catch (err) {
			console.log("error in joining", err)
		}
	}

	forNewChannelJoined = async (channelSid: string) => {
		try {
			const channel = await this.TwilioRef.current.getChannelBySid(channelSid);
			if (channel.channelState.status !== "joined") {
				await channel.join();
			} else {
				console.log("joined!")
			}
		} catch (err) {
			console.log("error in joining-----", err)
		}
	}

	getMessage = async (data: any) => {
		if (!data || !data.uniqueChannelName) return;
		try {
			const channel = await this.TwilioRef.current.getChannelBySid(data.uniqueChannelName);
			this.chatChannel.current = channel;
			const { name , id , profileImg , email , uniqueChannelName } = data;
			this.joinChannel(channel, { name , id , profileImg , email , uniqueChannelName });
		} catch (err) {
			console.log('error logged', err)
		}
	}

	//get message 
	getLastMessage = async () => {
		try {
			const channels = await this.TwilioRef?.current?.getSubscribedChannels();

			// Subscribe to the messageAdded event for each channel
			channels?.items?.forEach((channel: any) => {
				channel.on('messageAdded', async(message: any) => {
					const members = await channel.getMembers();
					let senderEmail = "";
					let checkEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
					members?.forEach((member: any) => {
						if(checkEmail.test(member.state.identity)) {
							if(member.state.identity !== this.state.profileData.email){
								senderEmail = member.state.identity
							}
						}
					});
					this.fetchUserChatList({channel_id: channel.sid, email: senderEmail}, "background")
				});
			});
		} catch (err) {
			console.log(err, "from multiple message")
		}
	}

	handleMessageSubmit = async () => {
		try {
			const { newMessage, chatAttachment, currentSelectedUser } = this.state
			//need to add logic for mutiple calling
			this.fetchUserChatList({ channel_id: currentSelectedUser.uniqueChannelName, receiver_email_id: currentSelectedUser.email }, "background")
			if (newMessage.trim()) {
				await this.chatChannel.current?.sendMessage(newMessage)
				this.setState({ newMessage: "" })
			}
			else if (chatAttachment) {
				const formData = new FormData() 
				formData.append("file", chatAttachment)
				await this.chatChannel.current?.sendMessage(formData)
				this.setState({ chatAttachment: "", chatImages: [] })
			}

		} catch (err) {
			console.log("error in send message", err)
		}
	}

	closeFileHandler = () => {
		this.setState({ chatImages: [], chatAttachment: "" })
	}

	handleFileUpload = async (event: any) => {
		let files = event.target.files[0];
		let val = [files.type, URL.createObjectURL(files)];
		this.setState({ chatImages: val, chatAttachment: files })
		event.target.value = null;
	}

	openUserChatHandler = (channelData: any) => {
		this.setState({ newMessage: "" });
		this.getMessage(channelData);
	};

	getToken = () => {
		const token = localStorage.getItem("accessToken");
		return token;
	};

	handleEmojiPicker = () => {
		this.setState((prev) => ({ chatEmojiPickerVisible: !prev.chatEmojiPickerVisible }))
	}

	EmojiSelectHandler = (emoji: any) => {
		let val = this.state.newMessage + `${emoji.emoji}`
		this.setState((prev) => ({ newMessage: val, chatEmojiPickerVisible: !prev.chatEmojiPickerVisible }))
	}

	fetchUserForChat = (searchData:string) => {
		
		this.setState({ isFetchingUsersForChat: true });
		const header = {
			"Content-Type": configJSON.exampleApiContentType,
			"token": this.getToken(),
		};


		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.fetchUserForChatApi = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.addNewChatGlobalSearch}?query=`+searchData
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getMethodForUserData
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	getNewChatWithUser = (targetUserEmail: string) => {
		const header = {
			"Content-Type": configJSON.exampleApiContentType,
			"token": this.getToken(),
		};


		const data = {
			attributes:
			{
				email_id: targetUserEmail
			}
		}

		const httpBody = {
			data: data,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.createChannelForNewUser = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createNewChannelApiEndpoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.createNewChannelMethod
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	handleNewChatModal = () => {
		this.setState((prev) => { return { newChatModalVisible: !prev.newChatModalVisible } })
	}

	handleNewChatWithUser = (targetUserEmail: string) => {
		this.setState({ isCreatingNewChannel: true })
		this.getNewChatWithUser(targetUserEmail)
	}

	handleChangeTextArea = (e: any) => {
		this.setState({ newMessage: e.target.value });
		if (this.textareaRef.current != null) {
			this.textareaRef.current.style.height = "0px";
			const scrollHeight = this.textareaRef.current.scrollHeight;
			this.textareaRef.current.style.height = scrollHeight + "px";
		}
	}

	handleMessageAdded = async (message: any) => {
		const messages = [...this.state.messages];
		messages.push(message)
		this.setState({
			messages,
		},
			() => {
				this.scrollToBottom()
			}
		);
	};

	fetchMappedData = (responseJson: any) => {
		const mappedUserData = responseJson?.data?.map(({ attributes, id }: any) => {
			return {
				id: id,
				profileImg: attributes?.profile_pic?.url ? attributes.profile_pic : profileImg1,
				name: attributes.user_name,
				status: "Offline",
				text: "",
				email: attributes.email,
			}
		})
		this.setState({ userData: mappedUserData, isFetchingUsersForChat: false })
	}

	handleFetchUsersResponse = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.fetchUserForChatApi &&
				responseJson !== undefined
			) {
				this.fetchMappedData(responseJson)
			} else {
				this.setState({isFetchingUsersForChat : false})
			}
		} 
	}

	handleCreateNewChannelResponse = async (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.createChannelForNewUser &&
				responseJson !== undefined
			) {
				delete responseJson.current_participant;
				this.setState((prev) => { return { newChatModalVisible: false, newChannelData: responseJson, newChannelCreated: true, isCreatingNewChannel: false } });
				this.fetchUserChatList(null, 'foreground')
			} 

		}
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		this.handleFetchUsersResponse(from, message);
		this.handleCreateNewChannelResponse(from, message);
		this.handleFetchUserChatListResponse(from, message);
		// Customizable Area End
	}

	// Customizable Area End
}
// Customizable Area End
