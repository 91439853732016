/** @format */

import React from "react";
// Customizable Area Start
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import ThemeContext from "../../../components/src/ThemeContext.web";
import { Dialog, RadioGroup, FormControlLabel, Radio, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PaymentIcon from '@material-ui/icons/Payment';

import "./Modal.scss"
import { amoeba } from "./assets";
import NftPaymentModal from "./NftPaymentModal.web";
// Customizable Area End

// Customizable Area Start
export const CustomFormControlLabel = ({ label, icon, ...rest }: any) => {
    return (
        <FormControlLabel
            {...rest}
            label={
                <React.Fragment>
                    <IconButton size="small">
                        {icon}
                    </IconButton>
                    {label}
                </React.Fragment>
            }
        />
    );
};

// Customizable Area End

// Customizable Area Start
class BuyNftModal extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <Dialog open={this.props.open} onClose={this.handleCloseNftModal} className="modal-section flex" data-test-id="modal_buynft">
                    <div className="modal-content-offer modal-width">
                        <div className="flex buy-nft">
                            <h3>Buy NFT</h3>
                            <div onClick={this.props.onClose}>
                                <CloseIcon />
                            </div>
                        </div>
                        <div className="flex">
                            <h3>1 Item</h3>
                            <h3>clear all</h3>
                        </div>
                        <div className="modal-inner-sec flex">
                            <div className="item-detail-nft flex">
                                <img src={amoeba} alt="detail" />
                                <div className="details-nft">
                                    <h3>Amoeba #714</h3>
                                    <p>Amoeba by last</p>
                                    <p>Creator fee:7.5%</p>
                                </div>
                            </div>
                            <div className="item-price">
                                <p>0.480 ETH</p>
                            </div>
                        </div>
                        <div className="flex total-price">
                            <h3>Total Price</h3>
                            <div>
                                <h3>0.48 ETH</h3>
                                <p style={{ textAlign: "right" }}>$622.53</p>
                            </div>
                        </div>
                        <div className="payment-option">
                            <h3>Payment Method</h3>
                            <RadioGroup aria-label="gender" name="gender1" value={this.state.value} onChange={this.handleChange}>
                                <CustomFormControlLabel
                                    value="crypto"
                                    control={<Radio />}
                                    label="Crypto"
                                    icon={<AttachMoneyIcon />}
                                />
                                <CustomFormControlLabel
                                    value="card"
                                    control={<Radio />}
                                    label="Credit card or Debit card"
                                    icon={<PaymentIcon />}
                                />
                            </RadioGroup>
                        </div>
                        <div className="make-offer-btn">
                            <button data-test-id="open_modal" onClick={this.handleOpenPaymentModal}>Complete Purchase</button>
                        </div>
                    </div>
                </Dialog>
                <NftPaymentModal
                    open={this.state.nftPayModal}
                    onClose={this.handleCloseNftPayModal}
                />
            </>
        );
    }
}
export default BuyNftModal;

BuyNftModal.contextType = ThemeContext;

// Customizable Area End
