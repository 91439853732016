// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    title?: string;
    open?: any;
    onClose?:any;
    context?:any;
    userData?:any;
    logoutUser?:any
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
	open: boolean;
	profileData: any;
	defaultImage:string
    muiSearchData: any;
    userData:any
    value:any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class NavbarController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    profileDetailCallId: string = "";
    logoutCallId: string = "";
    searchUserApiId: string = "";
    timer:any = null;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
			open: false,
			profileData: {},
            muiSearchData: [],
            userData:[],
            value : this.props.navigation.history.location?.state?.value,
			defaultImage:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
        };
     
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
		this.getProfileDetail();
        this.setState({value:this.props.navigation.history.location?.state?.value})
        this.startTimer();
        window.addEventListener('mousemove', this.resetTimer);
        window.addEventListener('keypress', this.resetTimer);
        // Customizable Area End  
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if(prevProps?.userData !== this.props?.userData) {
            if(this.props.userData) {
                this.setState({profileData: {
                    ...this.state.profileData,
                    attributes: this.props.userData,
                }})
            }
        }
    }
    // Customizable Area Start
    componentWillUnmount():any {
        this.clearTimer();
        window.removeEventListener('mousemove', this.resetTimer);
        window.removeEventListener('keypress', this.resetTimer);
      }
    
      startTimer = () => {
        this.timer = setTimeout(this.logOutUser, 30 * 60 * 1000);
      };
    
      clearTimer = () => {
        clearTimeout(this.timer);
        this.timer = null;
      };
    
      resetTimer = () => {
        this.clearTimer();
        this.startTimer();
      };
    
    handleOpenModal () {
        this.setState({ open: !this.state.open })
	}
	onClose = () => {
		this.setState({ open: false });
	}
	logOut = () => {
        localStorage.clear();
		this.props.navigation.navigate("EmailAccountLoginBlock")
	}
    getToken = () => {
        const token = localStorage.getItem("accessToken")
        return token
    }

      searchUserDebounce = async (searchValue?: string) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token:  this.getToken()
        };

        const searchUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchUserApiId = searchUserMessage.messageId;

        searchUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.globalSearchApiEndPoint}${searchValue}`
        );

        searchUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        searchUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.profileDetailApiMethodType
        );

        runEngine.sendMessage(searchUserMessage.id, searchUserMessage);
      }
    logOutUser = () => {
        let headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.getToken()

        };
        let httpBody = {}
        httpBody = {
            "data": {
              "user_type": "inactive"
            }
        }
        const logoutMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.logoutCallId = logoutMessage.messageId;

        logoutMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteApiEndPoint
        );

        logoutMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        logoutMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        logoutMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(logoutMessage.id, logoutMessage);

    }
	handleNavigate = () => {
		this.props.navigation.navigate("Dashboard")
    }

    handleNotification =  () => {
		this.props.navigation.history.push("/notification")
    }

    getProfileDetail = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token:  this.getToken()
        };

        const profileDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileDetailCallId = profileDataMessage.messageId;

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.profileDetailApiEndPoint
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.profileDetailApiMethodType
        );

        runEngine.sendMessage(profileDataMessage.id, profileDataMessage);
    }
  
    handleUpdatedSearch = (apiResponse:any)=> {
        let mappedSearchData = apiResponse?.data?.map((user: any) => {
            return {
                title: user.attributes.user_name,
                id: user.id,
                profile_pic:user.attributes.profile_pic,
                ...user
            }
        })               
        this.setState({ muiSearchData:  mappedSearchData, userData:apiResponse.data})
    }
    handleResponseForSearchUser = (from: string, message: Message) => {
        
        if (this.searchUserApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse) {
               this.handleUpdatedSearch(apiResponse)
            }
        }
    }

    handleResponseForProfileDetail = (from: string, message: Message) => {
        
        if (this.profileDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                console.log(apiResponse.errors)


            } else {
                this.props.context.updateDarkMode(apiResponse.data.attributes.mode)
				this.setState({ profileData: apiResponse.data },()=>{
                    localStorage.setItem("profileData", JSON.stringify(this.state.profileData));
                })
            }
        }
    }
    handleResponseToLogout = (from: string, message: Message) => {
        if (this.logoutCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.message) {
                 localStorage.clear();
		        this.props.navigation.history.push("/")
                location.reload()

            } else{
                console.log(apiResponse.error)
            }
        }
    }


    // Customizable Area End  

    async receive(from: string, message: Message) {
        
        // Customizable Area Start
        this.handleResponseForProfileDetail(from, message)
        this.handleResponseToLogout(from,message)
        this.handleResponseForSearchUser(from, message);
        // Customizable Area End
    }

}
