/** @format */

import React from "react";
// Customizable Area Start
import { Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import ThemeContext from "../../../components/src/ThemeContext.web";
import MenuIcon from '@material-ui/icons/Menu';
import TimerIcon from '@material-ui/icons/Timer';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoIcon from '@material-ui/icons/Info';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import "./Modal.scss"
import { amoeba } from "./assets";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class NftPaymentModal extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose} className="modal-section flex ">
                <div className="modal-content-offer modal-width">
                    <div className="flex buy-nft">
                        <div></div>
                        <h3>Checkout with payment</h3>
                        <div onClick={this.props.onClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="flex">
                        <h3>Purchase NFT with card</h3>
                        <div onClick={this.props.onClose}>
                            <MenuIcon />
                        </div>
                    </div>
                    <div className="nft-item-detail flex">
                        <div className="">
                            <h3>Amoeba #714</h3>
                            <p>Amoeba by last even</p>
                            <p>View item on chain</p>
                        </div>
                        <img src={amoeba} alt="detail" />
                    </div>
                    <div className="summary">
                        <div className="flex">
                            <p>Summary</p>
                            <p className="flex">
                                <TimerIcon />
                                Quote updates in 7s
                            </p>
                        </div>
                        <div className="summary-detail">
                            <div className="flex">
                                <div className="detail-pay flex">
                                    <img src={amoeba} alt="amoeba" />
                                    <h3>Purchasing NFT for $662.04</h3>
                                </div>
                                <KeyboardArrowUpIcon />
                            </div>
                            <div className="flex border-top">
                                <h4>Amoeba #714</h4>
                                <h4>$626.00</h4>
                            </div>
                            <div className="flex">
                                <h4 className="flex">
                                    Network fee
                                    <InfoIcon />
                                </h4>
                                <h4>+$19.00</h4>
                            </div>
                            <div className="flex">
                                <h4 className="flex">
                                    Processing fee
                                    <InfoIcon />
                                </h4>
                                <h4>+$19.00</h4>
                            </div>

                        </div>
                    </div>
                    <div className="make-offer-btn flex continue-btn">
                        <div></div>
                        <button>Continue</button>
                        <ArrowForwardIcon />
                    </div>
                </div>
            </Dialog>
        );
    }
}
export default NftPaymentModal;

NftPaymentModal.contextType = ThemeContext;

// Customizable Area End
