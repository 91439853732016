import React from "react";
// Customizable Area Start
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import "./SearchBar.scss";
// Customizable Area End

// Customizable Area Start
interface IProps {
	placeholder: string;
	onChange: (e: any) => void;
	value: string;
}
// Customizable Area End

// Customizable Area Start
const SearchBar = ({ placeholder, onChange, value }: IProps) => {
	return (
		<div className="search-bar-main-container">
			<div className="search-bar-section">
				<div className="search-bar-input">
					<SearchIcon />
					<input
						type="text"
						placeholder={placeholder}
						value={value}
						onChange={onChange}
					/>
				</div>
				<div>
					<TuneIcon />
				</div>
			</div>
		</div>
	);
};

export default SearchBar;
// Customizable Area End




