// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  test?: any;
  context?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appearenceSettings: {
    login_with_pin: boolean;
    has_login_pin: any;
  };
  new_pin: any,
  confirm_pin: any,
  errorsList:any,
  is_pin_change: boolean,
  message: string,
  is_open:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class SecuritySettingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiChangeAppearenceSettings: string = "";
  apiPasswordAppearenceSettings: string = "";
  getAppearenceApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      appearenceSettings: {
        login_with_pin: false,
        has_login_pin: false
      },
      new_pin: "",
      confirm_pin: "",
      errorsList:[],
      is_pin_change: false,
      message:"",
      is_open:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getSecuritySetting() 
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  setPass = (key: any, value: any) => {
    let appearenceSettings = { ...this.state.appearenceSettings };
    //@ts-ignore 
    appearenceSettings[key] = value;
    this.setState({
      appearenceSettings,
    });
  }
  handleClosePinChange=()=> {
    this.setState({is_pin_change: false});
    }
  updateNewPin = (value: any) => {
    this.setState({ new_pin: value });
  }
  updateConfirmPin = (value: any) => {
    this.setState({ confirm_pin: value });
  }
handleOPen =()=> {
  this.setState({is_open: !this.state.is_open})
  console.log(this.state.is_open,"isopen")
}
handleClose =()=> {
  this.setState({is_open:false})
  console.log(this.state.is_open,"isopen")
}
  handleErrors = (pinChange:any)=> {

    let errorData = [];

    if(pinChange?.new_pin == ""){
     errorData.push({new_pin: "Please enter valid pin"});
    }

    if(pinChange?.new_pin.length != 4){
      errorData.push({new_pin: "Security pin must be 4 digits"});
    }

    if(pinChange?.confirm_pin != pinChange?.new_pin){
      errorData.push({confirm_pin: "security numbers is different from confirm security number"});
     }

     this.setState({errorsList:errorData});

     if(errorData.length > 0){
return false;
     }

     return true;
  }

  getSecuritySetting = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppearenceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoundSettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodForSoundSetting
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSoundSettings = (key: string) => {
    let appearenceSettings = { ...this.state.appearenceSettings };
    //@ts-ignore
    appearenceSettings[key] = !appearenceSettings[key];

    this.setState({
      appearenceSettings,
    });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };

    const attrs = {
      //@ts-ignore
      [key]: appearenceSettings[key]
    };

    const data = {
      "attributes": attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessageForSecurity = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeAppearenceSettings = requestMessageForSecurity.messageId;

    requestMessageForSecurity.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeSecuritySettingApiEndpoint
    );

    requestMessageForSecurity.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForSecurity.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageForSecurity.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessageForSecurity.id, requestMessageForSecurity);

    return true;
  };
handleValidationForSecuritySetting = (new_pin:string , confirm_pin:string)=> {
  if(new_pin == ""){
    this.setState({is_pin_change:true,message:"Please enter new pin"})
    return
  }
  if (new_pin != confirm_pin) {
    this.setState({is_pin_change:true,message:"Both confirm pin and new pin should be same"})
    return
  }
}
  handleChangePasswordSettings = () => {

    let bool = this.handleErrors(this.state);

    if(!bool){
      return;
    }
 
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };

this.handleValidationForSecuritySetting(this.state.new_pin, this.state.confirm_pin)

    const data = {
      "attributes": {
        "current_pin": "",
        "new_pin": this.state.new_pin,
        "confirm_pin": this.state.confirm_pin
      },
    };

    const httpBody = {
      data: data,
    };

    const requestMessagePassword = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPasswordAppearenceSettings = requestMessagePassword.messageId;

    requestMessagePassword.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changepasswordSettingApiEndpoint
    );

    requestMessagePassword.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessagePassword.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessagePassword.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessagePassword.id, requestMessagePassword);

    return true;
  };

  handleChangeSoundSettingResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.apiChangeAppearenceSettings &&
        responseJson !== undefined
      ) {
        this.setState({is_pin_change:true,message:"Setting changed successfully"})
      }
    }
  }

  
  handleChangePasswordSettingResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.apiPasswordAppearenceSettings &&
        responseJson !== undefined
      ) {
        this.setState({is_pin_change:true,message:"Pin changed successfully"})
        this.setState({appearenceSettings:{...this.state.appearenceSettings}, new_pin: "",
          confirm_pin: "",is_open:false})
          this.getSecuritySetting()
        // this.setPass("has_login_pin", true)
      }
    }
  }

  handleGetSoundSettingsResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.getAppearenceApiId &&
        responseJson !== undefined
      ) {
        delete responseJson.data.attributes.account_id;
        delete responseJson.data.attributes.sound_for_media;
        delete responseJson.data.attributes.sound_for_notifications;
        delete responseJson.data.attributes.sound_for_open_close;
        delete responseJson.data.attributes.sound_for_sound_effects;
        delete responseJson.data.attributes.mode;
        this.setState({ appearenceSettings: { ...responseJson.data.attributes} })
      }
    }
  }




  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleChangePasswordSettingResponse(from, message);
    this.handleChangeSoundSettingResponse(from, message);
    this.handleGetSoundSettingsResponse(from,message);
    // Customizable Area End
  }
}
// Customizable Area End
