/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	context?: any
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	userData: any
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class UserProfileDetailController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	userDetailCallId: string = "";
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];

		this.state = {
			userData: {}
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}

	// Customizable Area Start
	componentDidMount(): any {
		// Customizable Area Start
		this.getUserDetail();
		// Customizable Area End  
	}
	getToken = () => {
		const token = localStorage.getItem("accessToken")
		return token
	}
	getUserDetail = () => {
		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const userDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.userDetailCallId = userDataMessage.messageId;

		userDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.userDetailApiEndPoint
		);

		userDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		userDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);

		runEngine.sendMessage(userDataMessage.id, userDataMessage);
	}
	handleResponseForUserProfileDetail = (from: string, message: Message) => {

		if (this.userDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (apiResponse) {
				this.setState({ userData: apiResponse.data })


			} 
		}
	}
	async receive(from: string, message: Message) {

		// Customizable Area Start
		this.handleResponseForUserProfileDetail(from, message)
		// Customizable Area End
	}

	// Customizable Area End
}
// Customizable Area End
