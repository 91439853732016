
import React, { useEffect, useState } from "react";

import {
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    Modal,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "../../blocks/ActivityFeed/src/ActivityFeed.scss";
import {
    clip,
    closeImage,
    globe,
    location,
    smile,
} from "../../blocks/ActivityFeed/src/assets";
import { profileImg1 } from "../../blocks/dashboard/src/assets";
import EmojiPicker from "emoji-picker-react";


export const CreatePostModal = (props: any) => {
    const [animate, setAnimate] = useState(false);
    const [currentValue, setCurrentValue] = useState("");
    const [createPostEmojiPickerVisible, setCreatePostEmojiPickerVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 1000)
    }, [])

    return (
        <Modal
            open={props.postModalVisible}
            onClose={props.handleCloseModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className={animate ? "mainWrapper-animated" : "mainWrapper"}>
                <div className="modalWrapper">
                    <p className="modal-title">
                        {props.isEditPost ? "Edit Post" : " Create Post"}
                    </p>

                    <div
                        className="divider-class-modal"
                    />

                    <div
                        className="user-detail-card modal-class-card-wrapper">
                        <div className="chat-card-sec card-chat-detail">
                            <div className="profile-img-container">
                                <img
                                    className="modal-user-image-class"
                                    src={props.loggedInUser?.attributes.profile_pic.url || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                                    alt={"Clara Jensen"}
                                />
                            </div>
                            <div className="profile-detail">
                                <p className="large-text-class">{`${props.loggedInUser?.attributes.first_name} ${props.loggedInUser?.attributes.last_name}`}</p>
                                <div
                                    className="public-row-class">
                                    <div

                                        className="div-align-center">
                                        <img
                                            className="globe-icon-class"
                                            src={globe}
                                        />
                                    </div>
                                    <p style={{ paddingRight: 10 }} className="public-text-class">{"Public"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="what-on-mind-class-wrapper add-post-class">
                        <textarea value={props.postTextValue} ref={props.textareaRef} onChange={(e: any) => {
                            props.setPostText(e.target.value);
                            // props.textareaRef.current.style.height = "0px";
                            // const scrollHeight = props.textareaRef.current.scrollHeight;
                            // props.textareaRef.current.style.height = scrollHeight + "px";
                        }} placeholder={`What’s on your mind, ${props.loggedInUser?.attributes.first_name}?`} className={`create-post-text-area ${props.context ? "textfield-color" : ''}`} />
                    </div>
                    <div className="modal-image-holder-class">
                        {props.createPostImageData.map((item: any, index: any) => {
                            return item[0].includes("image") ? <div className="uploaded-image-class-wrapper" key={index + 'key'}>
                                <img className="uploaded-img-class" src={item[1]} />
                                <img onClick={() => {
                                    let val = props.createPostImageData;
                                    val.splice(index, 1);
                                    props.closeFileHandler(index)
                                }} className={props.isEditPost ? "display-none" : "pointer-class uploaded-img-remove-class"} src={closeImage} />
                            </div> : <div className="uploaded-image-class-wrapper" key={index + 'key'}>
                                <video className="uploaded-img-class" src={item[1]} />
                                <img onClick={() => {
                                    let val = props.createPostImageData;
                                    val.splice(index, 1);
                                    props.closeFileHandler(index)
                                }} className={props.isEditPost ? "display-none" : "pointer-class uploaded-img-remove-class"} src={closeImage} />
                            </div>
                        })
                        }
                    </div>

                    {createPostEmojiPickerVisible && <div>
                        <EmojiPicker previewConfig={{ showPreview: false }} onEmojiClick={(emoji) => {
                            let val = props.postTextValue + `${emoji.emoji}`
                            setCreatePostEmojiPickerVisible(!createPostEmojiPickerVisible);
                            props.setPostText(val)
                        }} searchDisabled height={200} width={'95%'} /> </div>}
                    <div
                        className="add-post-class add-post-wrapper">
                        <div onClick={() => {
                            if (!props.isEditPost) {
                                props.uploadImgRef.current.click();
                            }

                        }}>
                            <input
                                disabled={true}
                                type={"text"}
                                onClick={() => {
                                    props.uploadImgRef.current.click();
                                }}
                                placeholder="Add to your post"
                                className={`app-create-post-form-field-input ${props.context ? "textfield-color" : ""
                                    }`}
                            />
                        </div>
                        <div
                            onClick={() => {
                                props.uploadImgRef.current.click();
                            }}
                            className={props.isEditPost ? "display-none" : "input-field-clip-buttons div-align-center"}>
                            <img
                                className="clip-icon-class"
                                src={clip}
                            />
                        </div>
                        <div
                            className="input-field-emoji-buttons div-align-center">
                            <input
                                ref={props.uploadImgRef}
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*,video/*"
                                name="image-upload"
                                id="input"
                                data-test-id="file-input-handler"
                                onChange={props.handleFileUpload}
                            />
                            <img
                                className="clip-icon-class"
                                onClick={() => { setCreatePostEmojiPickerVisible(!createPostEmojiPickerVisible) }}
                                src={smile}
                            />
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="app--create-post-form-field-button">
                                <Button
                                    className={`app-create-post-form-button ${props.context ? "btn-color color-white" : ""
                                        }`}
                                    variant="contained"
                                    style={{
                                        width: "95%"
                                    }}
                                    onClick={() => { props.isEditPost ? props.editPost() : props.createPost(); props.setPostText('') }}>
                                    {props.isEditPost ? "Update" : "Post"}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <div
                        className="pointer-class close-modal-class"
                        onClick={props.handleCloseModel}>
                        <img
                            className="globe-icon-class"
                            src={closeImage}
                            alt="Close"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );

}