export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const nftImage1 = require("../assets/nftImage1.jpg")
export const nftImage2 = require("../assets/nftImage2.jpg")
export const nftImage3 = require("../assets/nftImage3.jpg")
export const nftImage4 = require("../assets/nftImage4.jpg")
export const nftImage5 = require("../assets/nftImage5.jpg")
export const nftImage6 = require("../assets/nftImage6.jpg")
export const nftImage7 = require("../assets/nftImage7.jpg")
export const nftImage8 = require("../assets/nftImage8.jpg")
export const nftImage9 = require("../assets/nftImage9.jpg")
export const scobby = require("../assets/scobby.png")
export const jellyFish = require("../assets/jellyfish.jpg")
export const amoeba = require("../assets/amoeba.jpg")
