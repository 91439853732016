import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  FormControl,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Snackbar from '@material-ui/core/Snackbar'
// Customizable Area End

import SettingsContactusController, { Props } from "./SettingContactusController";

export default class ContactUs extends SettingsContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.is_open}
			autoHideDuration={3000}
					onClose={this.handleCloseContactus}
			message='Form submitted successfully we will contact with you soon'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
			</div>
     
        <Container maxWidth={"sm"}>
          <Box className={`${this.props.context?.isDark ? "background-none" :""}`} sx={webStyle.mainWrapper}>
            <Typography className={`${this.props.context?.isDark ? "color-white" :""}`} variant="h4" style={webStyle.settingTitle}>Contact</Typography>
            <FormControl style={{ marginTop: '20px'}}>
              <Box sx={webStyle.formRow}>
                <Box sx={webStyle.inputWrapper}>
                  <Typography style={webStyle.lableStyle} className={`${this.props.context?.isDark ? "color-white" :""}`} >First Name</Typography>
                  <input data-test-id="contact-us-input-field-first_name" type="text" style={webStyle.inputStyle} name="first_name" onChange={this.handleContactUsFormInputChange} value={this.state.contactUs.first_name}/>
                  {this.state.errorMessage?.length > 0 && this.state.errorMessage.findIndex((item: any) => item?.first_name != null) >= 0 &&  <span className="error-text">{this.state.errorMessage[this.state.errorMessage.findIndex((item: any) => item?.first_name != null)]?.first_name}</span>}
                </Box>
                <Box sx={webStyle.inputWrapper}>
                  <Typography style={webStyle.lableStyle} className={`${this.props.context?.isDark ? "color-white" :""}`}>last Name</Typography>
                  <input data-test-id="contact-us-input-field-last_name" type="text" style={webStyle.inputStyle} name="last_name" onChange={this.handleContactUsFormInputChange} value={this.state.contactUs.last_name}/>
                  {this.state.errorMessage?.length > 0 && this.state.errorMessage.findIndex((item: any) => item?.last_name != null) >= 0 &&<span className="error-text">{this.state.errorMessage[this.state.errorMessage.findIndex((item: any) => item?.last_name != null)]?.last_name}</span>}
                </Box>
              </Box>
              <Box sx={webStyle.formRow}>
                <Box sx={webStyle.inputWrapper}>
                  <Typography style={webStyle.lableStyle} className={`${this.props.context?.isDark ? "color-white" :""}`}>Email Address</Typography>
                  <input data-test-id="contact-us-input-field-email" type="email" style={webStyle.inputStyle} name="email" onChange={this.handleContactUsFormInputChange} value={this.state.contactUs.email}/>
                  {this.state.errorMessage?.length > 0 && this.state.errorMessage.findIndex((item: any) => item?.email != null) >= 0 &&<span className="error-text">{this.state.errorMessage[this.state.errorMessage.findIndex((item: any) => item?.email != null)]?.email}</span>}
                </Box>
                <Box sx={webStyle.inputWrapper}>
                  <Typography style={webStyle.lableStyle} className={`${this.props.context?.isDark ? "color-white" :""}`}>Phone Number</Typography>
                  <input data-test-id="contact-us-input-field-phone" type="number" style={webStyle.inputStyle} name="phone_number" onChange={this.handleContactUsFormInputChange} value={this.state.contactUs.phone_number}/>
                  {this.state.errorMessage?.length > 0 && this.state.errorMessage.findIndex((item: any) => item?.phone != null) >= 0 && <span className="error-text">{this.state.errorMessage[this.state.errorMessage.findIndex((item: any) => item?.phone != null)]?.phone}</span>}
                </Box>
              </Box>
              <Box sx={webStyle.textAreaWrapper}>
                <Typography style={webStyle.lableStyle} className={`${this.props.context?.isDark ? "color-white" :""}`}>Message</Typography>
                <textarea data-test-id="contact-us-input-field-desc" rows={4} style={webStyle.textAreaStyle} name="description" onChange={this.handleContactUsFormInputChange} value={this.state.contactUs.description}/>
                {this.state.errorMessage?.length > 0 && this.state.errorMessage.findIndex((item: any) => item?.description != null) >= 0 && <span className="error-text">{this.state.errorMessage[this.state.errorMessage.findIndex((item: any) => item?.description != null)]?.description}</span>}
              </Box>
              <Box sx={webStyle.btnWrapper}>
                <button data-test-id="contact-us-btn" style={webStyle.submitBtn} onClick={this.handleSubmitContactUs}>Submit</button>
              </Box>
            </FormControl>
          </Box>
        </Container>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  settingTitle: {
    font: 'normal normal bold 38px/36px Gilroy',
    color: '#1D1350',
    fontFamily:'Gilroy-Regular',
  },
  lableStyle: {
    font: 'normal normal normal 18px/36px Gilroy',
    color: '#1D1350',
    fontFamily:'Gilroy-Regular',
  },
  inputStyle: {
    border: "1px solid #DBE5ED",
    width: "100%",
    padding: '10px',
    outline: "none",
    backgroundColor: '#F9FAFC 0% 0% no-repeat padding-box',
    borderRadius: '10px',
    marginTop: '10px',
    fontFamily:'Gilroy-Regular',
  },
  textAreaWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: '20px',
  },
  textAreaStyle: {
    width: '100%',
    height: '115px',
    background: '#F9FAFC 0% 0% no-repeat padding-box',
    border: '1px solid #DBE5ED',
    borderRadius: '15px',
    padding: '10px',
    fontFamily:'Gilroy-Regular',
  },

  formRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: '20px',
    gap: '20px',
  },

  inputWrapper: {
    width: '50%',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  submitBtn: {
    width: '124px',
    height: '44px',
    borderRadius: '24px',
    outline: 'none',
    border: 'none',
    fontSize: '18px',
    letterSpacing: '0px',
    color: '#FFFFFF',
    cursor: "pointer",
    opacity: 1,
    fontFamily:'Gilroy-Regular',
    background: 'transparent linear-gradient(180deg, #702290 0%, #3F3291 100%) 0% 0% no-repeat padding-box',
  }
};
// Customizable Area End
