/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { DataListItem } from "../../Followers/src/FollowersController";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");


export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	// history: any;
	context?: any;
	loading?: any
	feedData?: any
	postComments?: any
	fetchCommentsData?: any
	postNewComments?: any
	likeFeed?: any
	deleteLikeFeed?: any
	textareaRef?: any
	uploadImgRef?: any
	userPostList?: any
	userLikes?: any
	deleteFeed?: any
	handleEdit?: any
	scrollHandle?: any
	scrollRef?: any
	replyComment?: any
	deleteComments?: any;
	data?: any;
	unFollowUserFromPost?: any
	followingUserFromPost?: any
	openAllComment?: string
	handleOpenAllComments?: any
	userFollowingPost?:any
	handleOpenChat?:any
	// Customizable Area End
}
interface S {
	targetEmail:string;
	txtInputValue: string;
	txtSavedValue: string;
	enableField: boolean;
	tabValue: string;
	postModalVisible: boolean;
	createPostImageData: any;
	uploadedImages: any;
	uploadedVideos: any;
	optionsPostModalVisible: boolean;
	currentValue: string;
	postTextValue: string;
	feedData: any;
	userPostList: any;
	loading: boolean;
	// Customizable Area Start
	isClickedOnTab: boolean;
	postComments: any;
	userLikes: any;
	uploadedFiles: any;
	error: any;
	loggedInUser: any;
	isEditPost: boolean;
	showsnackbar: boolean;
	postData: any
	feedDataPage: number;
	fetchCommentsData?: any
	postNewComments?: any
	likeFeed?: any
	deleteLikeFeed?: any
	textareaRef?: any
	uploadImgRef?: any
	deleteFeed?: any
	handleEdit?: any;
	commentId: string
	userFollowingPost: any;
	isFollowingPost: boolean;
	errorMsg: string;
	accountListData: DataListItem[],
	profile_pic: string;
	activeItem: string;
	query: string;
	isPostEdited: boolean;
	snackBarMessage: string;
	message: string,
	openAllComment: string

	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class ActivityFeedController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start

	getActivityFeedCallId: string = "";
	likeFeedCallId: string = "";
	createPostApiId: string = "";
	editPostApiId: string = "";
	deleteLikeFeedCallId: string = "";
	allLikesCallId: string = "";
	fetchUserCommentsApiId: string = "";
	fetchUserPostApiId: string = "";
	postUserCommentsApiId: string = "";
	deleteFeedCallId: string = "";
	replyCommentApiId: string = "";
	deleteCommentApiId: string = "";
	userFollowingPostCallId: string = "";
	uploadImgRef: any;
	textareaRef: any;
	scrollRef: any;
	accountListCallId: string = "";
	specificAccountCallId: string = "";
	UnFollowingUserCallId: string = "";
	followingUserCallId: string = "";
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];

		this.state = {
			targetEmail:"",
			txtInputValue: "",
			txtSavedValue: "A",
			enableField: false,
			tabValue: "activity",
			postModalVisible: false,
			createPostImageData: [],
			uploadedImages: [],
			uploadedVideos: [],
			optionsPostModalVisible: false,
			currentValue: '',
			feedData: [],
			userPostList: [],
			loading: false,
			postComments: [],
			userLikes: [],
			uploadedFiles: [],
			error: "",
			loggedInUser: null,
			postTextValue: "",
			isEditPost: false,
			showsnackbar: false,
			postData: {},
			feedDataPage: 1,
			commentId: "",
			userFollowingPost: [],
			isFollowingPost: false,
			errorMsg: "",
			accountListData: [],
			profile_pic: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
			activeItem: "1",
			query: "",
			isPostEdited: false,
			snackBarMessage: "",
			message: "",
			openAllComment: "",
			isClickedOnTab: false
		};


		this.uploadImgRef = React.createRef();
		this.textareaRef = React.createRef();
		this.scrollRef = React.createRef();
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}





	// Customizable Area Start

	componentDidMount(): any {
		// Customizable Area Start
		if (this.state.feedData.length === 0) {
			this.setState({ isFollowingPost: false })
			this.getActivityFeedData(this.state.feedDataPage)
			this.getAllLIkes()
			this.getLoggedInUser()
		}
		// const emailParams = this.props.navigation.history?.location?.state?.email
		// console.log(emailParams, "this is componentDidMount22")

		// Customizable Area End
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
		if (prevState.postModalVisible !== this.state.postModalVisible && this.state.postModalVisible === false && this.state.postData) {
			this.getActivityFeedData(0)
		}


	}

	handleOpenAllComments = (id: any) => {
		this.setState({ openAllComment: id })
	}

	handleOpenChat = (email: string) => {
		this.setState({tabValue: "chat" , targetEmail:email})
	}

	getLoggedInUser = () => {

		try {
			//@ts-ignore
			const userDetails = JSON.parse(localStorage.getItem("profileData"))
			this.setState({ loggedInUser: userDetails })
		} catch (error) {
			if (error instanceof SyntaxError) {
				console.log('Invalid JSON:', error.message);
			} else {
				throw error;
			}
		}


	}

	handleFileUpload = (event: any) => {
		let val = [[event.target.files[0].type, URL.createObjectURL(event.target.files[0])], ...this.state.createPostImageData];
		this.setState({ createPostImageData: val, uploadedFiles: [event.target.files[0], ...this.state.uploadedFiles] })
		event.target.value = null;
	}

	closeFileHandler = (file: any, index: number) => {
		let filterdImg = [...this.state.createPostImageData].filter((item: any, myIndex: number) => item != file);
		let filterdUploadedFiles = this.state.uploadedFiles.filter((item: any, myIndex: number) => myIndex != index);
		this.setState({ createPostImageData: filterdImg, uploadedFiles: filterdUploadedFiles })
	}
	handleScroll = (event: any) => {
		const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;

		if (Math.floor(clientHeight + scrollTop) >= (scrollHeight - 30)) {
			this.setState({ feedDataPage: this.state.feedDataPage + 1 }, () => {
				this.getActivityFeedData(this.state.feedDataPage);
			});
		}
	}
	handleCloseSnackBar = () => {
		this.setState({ showsnackbar: false })
	}
	createGlobalPostApiCall = (formData: any) => {
		const headers = {
			token: this.getToken()
		};

		const createPostMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.createPostApiId = createPostMessage.messageId;

		createPostMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getUserPostList
		);

		createPostMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			formData
		)

		createPostMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		createPostMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(createPostMessage.id, createPostMessage);
	}
	createGlobalPost = (formData: any) => {

		if (!formData?.get("[data][description]") && !formData?.get("[data][images][]") && !formData?.get("[data][videos][]")) {
			// FormData object is empty
			this.setState({ showsnackbar: true, snackBarMessage: "Fields should not be empty" })
			return;
		}
		this.createGlobalPostApiCall(formData)

	}
	handleCreateEditPost = (formData: any) => {
		this.textareaRef.current.value && formData.append("[data][description]", this.textareaRef.current.value)
		if (this.state.uploadedFiles.length) {
			for (let i = 0; i < this.state.uploadedFiles.length; i++) {
				if (this.state.uploadedFiles[i].type.includes("image"))
					formData.append("[data][images][]", this.state.uploadedFiles[i])
				if (this.state.uploadedFiles[i].type.includes("video"))
					formData.append("[data][videos][]", this.state.uploadedFiles[i])

			}
		}

		formData.append("[data][tag]", "12,13,14")
		formData.append("[data][post_id]", this.state.postData.id)
		//@ts-ignore
		formData.append("[data][user_name]", this.state.loggedInUser.attributes.user_name)
	}
	createPost = async () => {
		this.setState({ loading: true })
		let formData = new FormData()
		this.handleCreateEditPost(formData)
		this.createGlobalPost(formData)
	}

	editGlobalPost = (formData: any) => {
		const headers = {
			token: this.getToken()
		};

		const editPostMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.editPostApiId = editPostMessage.messageId;

		editPostMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.getUserPostList}/${this.state.postData.id}`
		);

		editPostMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			formData
		)

		editPostMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		editPostMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'PUT'
		);

		runEngine.sendMessage(editPostMessage.id, editPostMessage);
	}

	editPost = async () => {
		this.setState({ loading: true })
		let formData = new FormData()
		this.handleCreateEditPost(formData)
		this.editGlobalPost(formData)
	}
	deleteComments = (id: any) => {
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken(),
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.deleteCommentApiId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.postCommentsApiEndPoint + `/${id}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getDeleteCommentApiMethod
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	replyComment = ({ parentId, globalPostId, message }: any) => {
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken(),
		};

		const data = {
			parent_id: parentId,
			post_id: globalPostId,
			description: message
		};
		let httpBody = {
			data: data,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.replyCommentApiId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.replyCommentApiEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.exampleAPiMethod
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	deleteCommentResponseHandler = (from: string, message: Message) => {
		if (this.deleteCommentApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.errors) {
				console.log("error")

			} else {
				setTimeout(() => {
					this.fetchCommentsData(this.state.commentId)
				})

			}
		}
	}
	mapedComentData = (responseJson:any)=> {
		const mappedPostComments = this.state.postComments.map((comment: any) => {
			if (comment.id == responseJson.data.attributes.parent_id) {
				return {
					...comment,
					attributes: {
						...comment.attributes,
						comment_reply: [...comment.attributes.comment_reply, responseJson.data]
					}
				}
			}
			return comment;
		})
		this.setState({ postComments: mappedPostComments })
	}

	replyCommentResponseHandler = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);
		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.replyCommentApiId &&
				responseJson !== undefined
			) {
			this.mapedComentData(responseJson)
			}
		}
	}

	setPostText = (value: any) => {
		this.setState({ postTextValue: value })
	}

	getToken = () => {
		const token = localStorage.getItem("accessToken");
		return token;
	};

	handleCreatePostResponse = (from: string, message: Message) => {
		if (this.createPostApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.textareaRef.current = null
				this.setState({ feedData: apiResponse.data, loading: false, uploadedFiles: [], createPostImageData: [], postModalVisible: false });
			}
		}
	}

	handleEditPostResponse = (from: string, message: Message) => {
		if (this.editPostApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse.data) {
				location.reload()
				this.textareaRef.current = null
				this.setState({ postModalVisible: false, isPostEdited: true });
			}
		}
	}

	handleGetActivityFeedResponse = (from: string, message: Message) => {
		if (this.getActivityFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				let setFeedData = (feedData: any) => {
					if (feedData?.length) {
						if (this.scrollRef.current) {
							this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
						}
						return [...feedData, ...apiResponse]

					}

					return apiResponse;
				}
				this.setState((prevSate) => ({ feedData: setFeedData(prevSate.feedData), loading: false, isFollowingPost: false }));
			}
		}
	}

	handleUserPostListResponse = (from: string, message: Message) => {
		if (this.fetchUserPostApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.setState({ userPostList: apiResponse.data, loading: false });
			}
		}
	}

	handleLikeActivityFeedResponse = (from: string, message: Message) => {
		if (this.likeFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				if (this.state.isFollowingPost) {
					const updatedFeedData = this.state.userFollowingPost.map((obj: any) => {

						if (obj.id == apiResponse.data.attributes.likeable_id) {
							obj.attributes.likes += 1;
						}
						return obj;
					})
					this.setState({ userFollowingPost: updatedFeedData, loading: false })
					this.getAllLIkes()
				} else {
					const updatedFeedData = this.state.feedData.map((obj: any) => {

						if (obj.id == apiResponse.data.attributes.likeable_id) {
							obj.attributes.likes += 1;
						}
						return obj;
					})
					this.setState({ feedData: updatedFeedData, isFollowingPost: false })
					this.getAllLIkes()
				}
			}
		}
	}


	handleDeleteLikeActivityFeedResponse = (from: string, message: Message) => {
		if (this.deleteLikeFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				if (this.state.isFollowingPost) {
					const updatedFeedData = this.state.userFollowingPost.map((obj: any) => {

						if (obj.id == apiResponse.post_id) {
							obj.attributes.likes -= 1;
						}
						return obj;
					})
					this.setState({ userFollowingPost: updatedFeedData, loading: false })
					this.getAllLIkes()
				} else {
					const updatedFeedData = this.state.feedData.map((obj: any) => {

						if (obj.id == apiResponse.post_id) {
							obj.attributes.likes -= 1;
						}
						return obj;
					})
					this.setState({ feedData: updatedFeedData })
					this.getAllLIkes()
				}
			}
		}
	}

	handleDeleteFeedCallResponse = (from: string, message: Message) => {
		if (this.deleteFeedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.setState({ showsnackbar: true, feedData: apiResponse.data, loading: false, uploadedFiles: [], createPostImageData: [], postModalVisible: false, snackBarMessage: "Post deleted successfully" })
				setTimeout(() => {
					this.getActivityFeedData(0)
				})

			}
		}
	}

	handleAllLikesResponse = (from: string, message: Message) => {
		if (this.allLikesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.setState({ userLikes: apiResponse.data, loading: false });
			}
		}
	}

	fetchCommentsData = (id: any) => {
		const header = {
			"Content-Type": configJSON.exampleApiContentType,
			"token": this.getToken(),
		};

		this.setState({ commentId: id })
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.fetchUserCommentsApiId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.fetchUserCommentsApiEndPoint + `/${id}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getMethodForUserData
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	postNewComments = (id: number, description: string) => {
		const header = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken(),
		};

		const data = {
			post_id: id,
			description: description
		};
		let httpBody = {}

		httpBody = {
			data: data,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.postUserCommentsApiId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.postCommentsApiEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.exampleAPiMethod
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return true;
	}

	deleteFeed = (id: string) => {

		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()
		};

		const deletePostMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.deleteFeedCallId = deletePostMessage.messageId;

		deletePostMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getUserPostList + `/${id}`
		);

		deletePostMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		deletePostMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		runEngine.sendMessage(deletePostMessage.id, deletePostMessage);
	}

	likeFeed = (id: string) => {

		let data = {
			data: {
				likeable_id: id

			}
		}

		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()
		};

		const likeDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.likeFeedCallId = likeDataMessage.messageId;

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likeFeed
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(data)
		)

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(likeDataMessage.id, likeDataMessage);
	}

	getAllLIkes = () => {

		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()
		};

		const likeDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.allLikesCallId = likeDataMessage.messageId;

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likeFeed
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(likeDataMessage.id, likeDataMessage);
	}

	deleteLikedCount = (likedPost: any) => {
		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()
		};

		const likeDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.deleteLikeFeedCallId = likeDataMessage.messageId;

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likeFeed + `/${likedPost.id}`
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		likeDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		runEngine.sendMessage(likeDataMessage.id, likeDataMessage);
	}

	deleteLike = (id: string) => {
		let likedPost = this.state.userLikes.find((item: any) => item.attributes.likeable_id == id)
		this.deleteLikedCount(likedPost)
	}

	getActivityFeedData = (page: any) => {
		this.setState({ loading: true, });

		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const activityDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.getActivityFeedCallId = activityDataMessage.messageId;
		let globalPostEndPoint = `${page ? (configJSON.getActivityFeed + '?page=' + page) : configJSON.getActivityFeed}`

		activityDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			globalPostEndPoint
		);

		activityDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		activityDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(activityDataMessage.id, activityDataMessage);
	}

	fetchCommentsResponseHandler = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.fetchUserCommentsApiId &&
				responseJson !== undefined
			) {
				this.setState({ postComments: responseJson.data })
			}
		}
	}

	handlePostCommentsResponse = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.postUserCommentsApiId &&
				responseJson !== undefined
			) {
				if (this.state.isFollowingPost) {
					const updatedFeedData = this.state.userFollowingPost.map((obj: any) => {

						if (obj.id == responseJson.data.attributes.post_id) {
							obj.attributes.comments += 1;
						}
						return obj;
					})
					this.setState({ postComments: [...this.state.postComments, responseJson.data], userFollowingPost: updatedFeedData })
				} else {
					const updatedFeedData = this.state.feedData.map((obj: any) => {

						if (obj.id == responseJson.data.attributes.post_id) {
							obj.attributes.comments += 1;
						}
						return obj;
					})
					this.setState({ postComments: [...this.state.postComments, responseJson.data], feedData: updatedFeedData })
				}
			}
		}
	}
	getUserFollowingPostList = () => {
		this.setState({ loading: true })
		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const userDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.userFollowingPostCallId = userDataMessage.messageId;

		userDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getUserFollowingPostListApiEndPoint
		);

		userDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		userDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);

		runEngine.sendMessage(userDataMessage.id, userDataMessage);
	}
	handleResponseForUserFollowingPostList = (from: string, message: Message) => {

		if (this.userFollowingPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (apiResponse) {
				this.setState({ userFollowingPost: apiResponse, isFollowingPost: true,loading:false })
				if (apiResponse.length === 0) {
					this.setState({ loading: false })
				}

			}
		}
	}
	unFollowUserFromPost = (account_id: string) => {

		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()
		};
		let httpBody = {}
		httpBody = {
			data: {
				attributes: {
					account_id: account_id,

				},
			}
		}

		const unfollowMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.UnFollowingUserCallId = unfollowMessage.messageId;

		unfollowMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.deleteFollowApiEndPoint}/${account_id}`
		);

		unfollowMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);
		unfollowMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		unfollowMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getDeleteCommentApiMethod
		);

		runEngine.sendMessage(unfollowMessage.id, unfollowMessage);
	}
	 updatedFollowingData(feedData:any, apiResponse:any,message:null | string) {
		return feedData.map((obj:any) => {
		  if (obj.attributes.account_id === apiResponse.data.attributes.account_id) {
			obj.attributes.is_follow = message;
		  }
		  return obj;
		});
	  }
	handleResForUnfollowingUserFromPost = (from: string, message: Message) => {
		if (this.UnFollowingUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.data) {
				this.setState({ feedData: this.updatedFollowingData(this.state.feedData,apiResponse,null), isFollowingPost: false })
			}
		}

	}
	followingUserFromPost = (account_id: string) => {
		let headers = {
			"Content-Type": configJSON.validationApiContentType,
			token: this.getToken()

		};
		let httpBody = {}
		httpBody = {
			data: {
				attributes: {
					account_id: account_id,

				},
			}
		}
		const followingUserMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.followingUserCallId = followingUserMessage.messageId;

		followingUserMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.addFollowersApiEndPoint
		);

		followingUserMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);
		followingUserMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		followingUserMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.createNewChannelMethod
		);

		runEngine.sendMessage(followingUserMessage.id, followingUserMessage);

	}
	handleResponseToFollowFromPost = (from: string, message: Message) => {
		if (this.followingUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.data) {
				this.setState({ feedData: this.updatedFollowingData(this.state.feedData,apiResponse,"following"), isFollowingPost: false })
			}
		}
	}

	async receive(from: string, message: Message) {

		this.handlePostCommentsResponse(from, message);
		this.handleGetActivityFeedResponse(from, message);
		this.handleUserPostListResponse(from, message);
		this.fetchCommentsResponseHandler(from, message);
		this.handleLikeActivityFeedResponse(from, message);
		this.handleAllLikesResponse(from, message);
		this.handleDeleteLikeActivityFeedResponse(from, message);
		this.handleDeleteFeedCallResponse(from, message);
		this.handleCreatePostResponse(from, message);
		this.handleEditPostResponse(from, message);
		this.replyCommentResponseHandler(from, message);
		this.deleteCommentResponseHandler(from, message);
		this.handleResponseForUserFollowingPostList(from, message);
		this.handleResForUnfollowingUserFromPost(from, message)
		this.handleResponseToFollowFromPost(from, message)
	}

	txtInputWebProps = {
		onChangeText: (text: string) => {
			this.setState({ txtInputValue: text });
		},
		secureTextEntry: false,
	};
	handleCloseModel = () => {
		this.setState({ postModalVisible: false });
	};
	handleOpen = () => {
		this.setState({ postModalVisible: true, isEditPost: false })
	}

	handleEdit = (post: any) => {
		let mediaArrayImage = []
		let mediaArrayVideo = []
		if (post.attributes.images.length)
			mediaArrayImage = post.attributes.images.map((item: any) => (["image", item.url]))
		if (post.attributes.videos.length)
			mediaArrayVideo = post.attributes.videos.map((item: any) => (["video", item.url]))
		this.setState({ postModalVisible: true, createPostImageData: [...mediaArrayImage, ...mediaArrayVideo], postTextValue: post.attributes.description, isEditPost: true, postData: post })
	}

	txtInputMobileProps = {
		...this.txtInputWebProps,

	};

	txtInputProps = this.isPlatformWeb()
		? this.txtInputWebProps
		: this.txtInputMobileProps;

	btnShowHideProps = {
		onPress: () => {
			this.setState({ enableField: !this.state.enableField });
		},
	};

	btnShowHideImageProps = {
		source: this.txtInputProps.secureTextEntry
			? imgPasswordVisible
			: imgPasswordInVisible,
	};

	btnExampleProps = {
		onPress: () => this.doButtonPressed(),
	};

	doButtonPressed() {
		let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
		this.send(msg);
	}

	// web events
	setInputValue = (text: string) => {
		this.setState({ txtInputValue: text });
	};

	setEnableField = () => {
		this.setState({ enableField: !this.state.enableField });
	};
	// Customizable Area End
}
// Customizable Area End
