/** @format */

import React from "react";
// Customizable Area Start
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import ThemeContext from "../../../components/src/ThemeContext.web";
import { Dialog, Select, MenuItem, OutlinedInput } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import "./Modal.scss"
import { jellyFish } from "./assets";
// Customizable Area End

// Customizable Area Start
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 160,
        },
    },
};
// Customizable Area End

// Customizable Area Start
class MakeAnOfferModal extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.handleCloseModal} className="modal-section flex" data-test-id="close_modal_offer">
                <div className="modal-content-offer">
                    <div className="flex">
                        <div onClick={this.props.onClose}>
                            <ArrowBackIcon />
                        </div>
                        <h3>Make an offer</h3>
                        <div onClick={this.props.onClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="modal-inner-sec flex">
                        <div className="item-detail flex">
                            <img src={jellyFish} alt="detail" />
                            <div className="details">
                                <h3>OS Jellyfish</h3>
                                <p>Os Support test collection</p>
                            </div>
                        </div>
                        <div className="item-price">
                            <h3>0 WETH</h3>
                            <p>$0</p>
                        </div>
                    </div>
                    <div className="balance-price-section">
                        <div className="balance flex">
                            <div className="account-balance">
                                <AccountBalanceWalletIcon />
                                <p>Balance</p>
                            </div>
                            <p>0.05 WETH</p>
                        </div>
                        <div className="flex">
                            <p>Floor Price</p>
                            <p>0.0005 ETH</p>
                        </div>
                    </div>
                    <div className="conversion-price">
                        <div className="conversion">
                            <input type="text" placeholder="Price" />
                            <button>WETH</button>
                        </div>
                        <span>Total after amount: 0 WETH</span>
                    </div>
                    <div>
                        <h3>Duration</h3>
                        <div className="duration">
                            <Select
                                displayEmpty
                                className="select-input-field"
                                value={this.state.title}
                                onChange={this.handleUserTitle}
                                input={<OutlinedInput />}
                                renderValue={(selected: any) => {
                                    return selected
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="2 Days">
                                    2 Days
                                </MenuItem>
                                <MenuItem value="3 Days">
                                    3 Days
                                </MenuItem>
                                <MenuItem value="4 Days">
                                    4 Days
                                </MenuItem>
                            </Select>
                            <div className="date-time flex">
                                <p>20 Oct 2022</p>
                                <p>3:14PM</p>
                            </div>
                        </div>
                    </div>
                    <div className="make-offer-btn">
                        <button>Make Offer</button>
                    </div>
                </div>
            </Dialog>
        );
    }
}
export default MakeAnOfferModal;

MakeAnOfferModal.contextType = ThemeContext;

// Customizable Area End
