/** @format */

import React from "react";
// Customizable Area Start

import "./Settings.scss"
import PublicProfileController, {Props} from "./PublicProfileController";
import "./Profile.scss"
import Snackbar from '@material-ui/core/Snackbar'
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class PublicProfile extends PublicProfileController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	  }
	render() {
		console.log(this.state.previewUploadFile, "preview")
		console.log(this.state.profileData.profile_pic, "preview")
		return (
			<>
				<div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.open}
			autoHideDuration={2000}
					onClose={this.handleClose}
			message={this.state.message}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
			</div>
				<div className="profile-sec">
	  <div className="profile-heading">
		  <p className={`${this.props.context.isDark ? "color-white" :""} profile-title-text`}>Public Profile</p>
		  <p className={`${this.props.context.isDark ? "color-white" :""} profile-description`}>People visiting your profile will see the following info</p>
	  </div>
		  <p className={`${this.props.context.isDark ? "color-white" :""} heading-text`}>Photo</p>
		  <div className="upload" style={{ display: "flex" }}>
			  <div className="img-holder">
				  <img src={this.state.profileData.profile_pic.url=== null?this.state.profileImg:this.state.profileData.profile_pic.url} alt="" id="img" className="imageUpload" />
			  </div>
		  <input
				  type="file"
				  accept="image/*"
				  name="image-upload"
				  id="input"
				  data-test-id='file-input-handler'
			  onChange={this.handleChangePublicProfile}
			  />
			  <div className="label">
				  <label  className="image-upload heading-text" htmlFor="input">
					  Change
				  </label>
			  </div>
		  </div>
	  <div className="input-field">
		  <div className="input-inner-field">
			  <label className="heading-text">First Name</label>
			  <input className="heading-text" data-test-id="on-change-firstname" type="text" name="first_name" placeholder="Enter first name"  value={this.state.profileData.first_name} onChange={this.handleChangePublicProfile} />
		  </div>
		  <div className="input-inner-field">
			  <label className="heading-text">Last Name</label>
			  <input className="heading-text" data-test-id="on-change-lastname" type="text" name="last_name" placeholder="Enter last name" value={this.state.profileData.last_name} onChange={this.handleChangePublicProfile} />
		  </div>
	  </div>
	  <div className="input-field-username input-field">
		  <label className="heading-text">User Name</label>
		  <input className="heading-text" data-test-id="on-change-userName" type="text" name="user_name" placeholder="Enter user name" value={this.state.profileData.user_name} onChange={this.handleChangePublicProfile} />
	  </div>
	  <div className="button-group">
		  <button data-test-id="reset-profile-data" onClick={this.resetFunc} className="reset heading-text">Reset</button>
		  <button data-test-id="save-profile-data" onClick={this.updateProfileDetail} className="save heading-text">Save</button>
	  </div>

  </div>
			</>
			
		);
	}
}
export default PublicProfile;

// Customizable Area End
