import React from 'react'
import { imageCube } from '../../blocks/ActivityFeed/src/assets'
import "../../blocks/ActivityFeed/src/UserActivity.scss"
import ContentLoader from 'react-content-loader'


const UserProfileCard = (props: any) => {
    console.log(props.userData,"from userpost")
    const profileImg = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
    const MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', background: "green", width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        {Array(4).fill(4).map((item, i) => <ContentLoader key={i} />)}
    </div>)
    return (
        <div className={`activity-profile-detail ${props.context?.isDark ? "black-color" : ""}`}>
            <div className="activity-user-detail">
                {props?.loading ? <div>loading...</div> : Object.keys(props.userData).length === 0 ? <div></div> : (
                    <div className='profile-chat-sec'>
                        <div className="profile-section">
                            <div className="image-section">
                                <img src={props.userData?.attributes?.profile_pic?.url === null ? profileImg : props.userData?.attributes?.profile_pic?.url} alt="user" />
                            </div>
                            <div className="profile-detail">
                                <h2 className="profile-section-name">{props.userData.attributes.first_name}</h2>
                                <h4 className="profile-section-email">@{props.userData.attributes.user_name}</h4>
                                <div className="follower-following">
                                    <div className="followers">
                                        <span>{props.userData.attributes.followers_count}</span>
                                        <span className="profile-text">Followers</span>
                                    </div>
                                    <div className="following">
                                        <span>{props.userData.attributes.following_count}</span>
                                        <span className="profile-text">Following</span>
                                    </div>

                                </div>
                                <button onClick={() =>  props.userData.attributes.is_follow === "following" ? props.unFollowFromFollowing(props.userData?.id):props.followFromFollowing(props.userData?.id)}>{props.userData.attributes.is_follow === "following" ? "Following" : "Follow"}</button>
                            </div>

                        </div>

                    </div>
                )}

            </div>
            <div className="floating-cube-image">
                <img src={imageCube} alt="icecube" />
            </div>
            {Object.keys(props.userData).length === 0 ? <div></div> :
                <div className='chat-image-right'>
                    <img src={props.chatImage} alt="chat" onClick={() => {
                        props.handleOpenChat();
                    } }/>
                </div>}
        </div>
    )
}

export default UserProfileCard
