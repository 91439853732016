// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core"
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import NotificationBoxController, { Props } from "./NotificationBoxController";
import Navbar from "../../dashboard/src/Navbar.web";
import "./NotificationBox.scss"
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default class Notification extends NotificationBoxController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            // Customizable Area Start
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation} selected="notification" />
                <Grid container className={`main-container app-page-sec app-page-dashboard ${this.context.isDark ? "dark-color" : ""}`}>
                    <Grid
                        item
                        md={12}
                        lg={12}
                        className={`container-items item-collect ${this.context.isDark ? "dark-color" : ""}`}
                    >
                        <Navbar context={this.context} title="Notification" navigation={this.props.navigation} />
                        <Grid container className="">
                            <Grid item xs={12} sm={12} md={3} lg={3}>

                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9} className="notification-section">
                                <div className="notification-friend-request">
                                    <h1>Notifications</h1>
                                    {this.state.notificationData.length === 0 ?<div>No notification yet</div>: this.state.notificationData.map((item: any) => (
                                        <div className="friend-request-first-section" key={item.id}>
                                            <div className="image-container">
                                                <div className="empty-dots"></div>
                                                <img src={item.attributes.profile_pic.url === null ? this.state.profileImg:item.attributes.profile_pic.url} alt="notification" />
                                            </div>
                                            <div className="group-detail">
                                                {/* <p>
                                                    <strong>{item.name}</strong>
                                                    Following you
                                                </p> */}
                                                <p>{item.attributes.contents}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                          
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>

            // Customizable Area End
        );
    }
}
Notification.contextType = ThemeContext;
// Customizable Area End

