import React from 'react'

const AccountDetailCard = (props: any) => {
    return (
        <div className={`friend-list ${props.activeItem === props.item.id ? "friendlist-bg" : ""}`} onClick={()=>props.specificAccountActivity(props.item)}>
            <img className="profile-img" src={props.item.attributes.profile_pic.url === null ? props.profile_pic : props.item.attributes.profile_pic.url} alt={props.item.attributes.email} />
            <p>{props.item.attributes.first_name} {props.item.attributes.last_name}</p>
            <img className="chat-item-img" src={props.chatImage} alt="chat" onClick={() =>props.handleOpenChat(props.item.attributes.email)}/>
        </div>
    )
}

export default AccountDetailCard
