// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CfConnectToGamesOnComputer from "../../blocks/CfConnectToGamesOnComputer/src/CfConnectToGamesOnComputer";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import CfApiGame from "../../blocks/CfApiGame/src/CfApiGame";
import CfApiCoinbase from "../../blocks/CfApiCoinbase/src/CfApiCoinbase";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import Payments from "../../blocks/Payments/src/Payments";
import Videos from "../../blocks/videos/src/Videos";
import CfOpenSeaApi from "../../blocks/CfOpenSeaApi/src/CfOpenSeaApi";
import InappGifting from "../../blocks/InappGifting/src/InappGifting";
import FriendList from "../../blocks/FriendList/src/FriendList";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/PasswordForgotOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import BitcoinPayment from "../../blocks/BitcoinPayment/src/BitcoinPayment";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import Wishlist2 from "../../blocks/Wishlist2/src/Wishlist2";
import PaypalIntegration from "../../blocks/PaypalIntegration/src/PaypalIntegration";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import Trending from "../../blocks/Trending/src/Trending";
import AuctionBidding from "../../blocks/AuctionBidding/src/AuctionBidding";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfApiFlaggingClearingBots2 from "../../blocks/CfApiFlaggingClearingBots2/src/CfApiFlaggingClearingBots2";
import Scoring from "../../blocks/Scoring/src/Scoring";
import CfLastPlayed from "../../blocks/CfLastPlayed/src/CfLastPlayed";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import UserAvatar from "../../blocks/UserAvatar/src/UserAvatar";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import DragDropInterface from "../../blocks/DragDropInterface/src/DragDropInterface";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import CfApiNft from "../../blocks/CfApiNft/src/CfApiNft.web";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Followers from "../../blocks/Followers/src/Followers";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import LockScreen from "../../blocks/email-account-login/src/LockScreen.web";
import SecurityCode from "../../blocks/email-account-login/src/SecurityCode.web";
import SplashScreen from "../../blocks/email-account-login/src/SplashScreen.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Avatar from "../../blocks/avatar/src/Avatar";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import AbuseDetector from "../../blocks/AbuseDetector/src/AbuseDetector";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ThemeContext from '../../components/src/ThemeContext';
import Settings from "../../blocks/Settings5/src/Settings.web"
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web"
import DashboardFullChat from "../../blocks/dashboard/src/DashboardFullChat.web"
import UserActivity from "../../blocks/ActivityFeed/src/UserActivity.web"
import ExploreNftCollection from "../../blocks/CfApiNft/src/ExploreNftCollection.web"
import Friends from "../../blocks/Followers/src/Friends.web"
import NotificationBox from "../../blocks/notifications/src/NotificationBox.web"
import Snackbar from '@material-ui/core/Snackbar'
import UserProfilePost from "../../blocks/dashboard/src/UserProfilePost.web"
const routeMap = {
  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Avatar: {
    component: Avatar,
    path: "/Avatar"
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: "/ThemeBlock"
  },
  AbuseDetector: {
    component: AbuseDetector,
    path: "/AbuseDetector"
  },
  AddFriends: {
    component: AddFriends,
    path: "/AddFriends"
  },
  FileAttachment: {
    component: FileAttachment,
    path: "/FileAttachment"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/Ordermanagement"
  },
  OrderDetails: {
    component: OrderDetails,
    path: "/OrderDetails"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  CfConnectToGamesOnComputer: {
    component: CfConnectToGamesOnComputer,
    path: "/CfConnectToGamesOnComputer"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  CfApiGame: {
    component: CfApiGame,
    path: "/CfApiGame"
  },
  CfApiCoinbase: {
    component: CfApiCoinbase,
    path: "/CfApiCoinbase"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  LikeAPost: {
    component: LikeAPost,
    path: "/LikeAPost"
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: "/PrivacySettings"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  CfOpenSeaApi: {
    component: CfOpenSeaApi,
    path: "/CfOpenSeaApi"
  },
  InappGifting: {
    component: InappGifting,
    path: "/InappGifting"
  },
  FriendList: {
    component: FriendList,
    path: "/FriendList"
  },
  GroupChat: {
    component: GroupChat,
    path: "/GroupChat"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  LockScreen: {
    component: LockScreen,
    path: "/LockScreen"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  BitcoinPayment: {
    component: BitcoinPayment,
    path: "/BitcoinPayment"
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: "/ActivityFeed"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  ContentModeration: {
    component: ContentModeration,
    path: "/ContentModeration"
  },
  Wishlist2: {
    component: Wishlist2,
    path: "/Wishlist2"
  },
  PaypalIntegration: {
    component: PaypalIntegration,
    path: "/PaypalIntegration"
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: "/CollectTransactionFees"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  OrderSummary: {
    component: OrderSummary,
    path: "/OrderSummary"
  },
  Invitefriends: {
    component: Invitefriends,
    path: "/Invitefriends"
  },
  DesktopNotifications: {
    component: DesktopNotifications,
    path: "/DesktopNotifications"
  },
  Trending: {
    component: Trending,
    path: "/Trending"
  },
  AuctionBidding: {
    component: AuctionBidding,
    path: "/AuctionBidding"
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: "/Interactivefaqs"
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: "/AddInteractivefaqs"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  CfApiFlaggingClearingBots2: {
    component: CfApiFlaggingClearingBots2,
    path: "/CfApiFlaggingClearingBots2"
  },
  Scoring: {
    component: Scoring,
    path: "/Scoring"
  },
  CfLastPlayed: {
    component: CfLastPlayed,
    path: "/CfLastPlayed"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  UserStatus: {
    component: UserStatus,
    path: "/UserStatus"
  },
  UserAvatar: {
    component: UserAvatar,
    path: "/UserAvatar"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  DragDropInterface: {
    component: DragDropInterface,
    path: "/DragDropInterface"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  CfApiNft: {
    component: CfApiNft,
    path: "/CfApiNft"
  },
  Blockedusers: {
    component: Blockedusers,
    path: "/Blockedusers"
  },
  AddBlockeduser: {
    component: AddBlockeduser,
    path: "/AddBlockeduser"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  Followers: {
    component: Followers,
    path: "/Followers"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Chat9: {
    component: Chat9,
    path: "/Chat9"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLogin,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: "/PhotoLibrary3"
  },

  Home: {
    component: SplashScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  SecurityCode: {
    component: SecurityCode,
    path: '/SecurityCode'
  },
  Setting: {
    component: Settings,
    path: '/setting',
    exact: true,
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  DashboardFullChat: {
    component: DashboardFullChat,
    path: '/chat',
    exact: true
  },
  UserActivity: {
    component: UserActivity,
    path: '/Activity',
    exact: true
  },
  ExploreNftCollection: {
    component: ExploreNftCollection,
    path: '/NftMarket',
    exact: true
  },
  Friends: {
    component: Friends,
    path: '/friends',
    exact: true
  },
  Notifications: {
    component: NotificationBox,
    path: '/notification',
    exact: true
  },

  UserProfilePost: {
    component: UserProfilePost,
    path: '/user-post/:name',
    exact: true
  }

};

const firebaseAPI = firebase.initializeApp({
  // apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  // authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  // databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  // projectId: "rnmasterapp-c11e9",
  // storageBucket: "rnmasterapp-c11e9.appspot.com",
  // messagingSenderId: "649592030497",
  // appId: "1:649592030497:web:7728bee3f2baef208daa60",
  // measurementId: "G-FYBCF3Z2W3"
  apiKey: "AIzaSyBTpeOQ5LgWFVy_LJ0GfLf9amHqmZUeUXw",
  authDomain: "esfera-notification.firebaseapp.com",
  projectId: "esfera-notification",
  storageBucket: "esfera-notification.appspot.com",
  messagingSenderId: "52147269568",
  appId: "1:52147269568:web:92bbda5b02d0d1d7b6a225",
  measurementId: "G-41W7TRYQMT"
});

class App extends Component {
  state = {
    title: "",
    showsnack: false
  }
  handleClose = () => {
    this.setState({ showsnack: false });
  }

  componentDidMount() {
    const messaging = firebase.messaging()

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            messaging.getToken().then((token) => {
              console.log("FCM token:", token);
              localStorage.setItem("firebaseToken", token)
            });

            messaging.onMessage((message) => {
              console.log("Received FCM message:", message);
              this.setState({ title: message.notification.body, showsnack: true })
            });
          });
      }
    });
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (

      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        <div className={`app-main ${this.context.isDark ? 'dark-side-color' : ''}`}>
          {WebRoutesGenerator({ routeMap })}
          <Snackbar
            open={this.state.showsnack}
            autoHideDuration={3000}
            onClose={this.handleClose}
            message={this.state.title}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        </div>
        <ModalContainer />
      </View>
    );
  }
}

App.contextType = ThemeContext;
export default App;
