import React, { useEffect, useState } from 'react';
import { bubbleChat, deleteImg, edit, smile, verticalEllipse } from '../../blocks/ActivityFeed/src/assets';
import {
  Menu,
  MenuItem,
  Box
} from "@material-ui/core";
import EmojiPicker from 'emoji-picker-react';
import "../../blocks/ActivityFeed/src/ActivityFeed.scss";
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import ItemsList from './UserComment';
import { chatImage } from '../../blocks/Followers/src/assets';

export const FeedView = (props: any) => {
  const getLikeStatus = () => {
    return !!props.userLikes.find((post: any) => post.attributes.likeable_id == props.item.id)
  }
  const [sourceArray, setSourceArray] = useState<any>([]);
  const [replyComment, setReplyComment] = useState<any>(null);
  const [valueText, setValueText] = React.useState("");
  const [viewText, setViewText] = useState(false)
  const [showComments, setShowComments] = useState(false);

  const [isLiked, setIsLiked] = useState(false);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [currentValue, setCurrentValue] = useState('');


  const [anchor, setAnchor] = useState(null);
  const [openAllComment, setOpenAllComment] = useState(false);

  const options = [{ image: edit, title: "Edit" }, { image: deleteImg, title: "Delete" }];

  const [selected, setSelected] = useState(-1);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openDeleteComment, setOpenDeleteComment] = React.useState(false);
  const [openDeleteReply, setOpenDeleteReply] = React.useState(false);
  const openAllCommentFunc = (id: any) => {
    setOpenAllComment(id)
  }
  const onHandleOpenComment = (id: any) => {

    setOpenDeleteComment(id)
    setOpenDeleteReply(false)
  }
  const onHandleOpenReply = (id: any) => {
    setOpenDeleteReply(id)
    setOpenDeleteComment(false)
  }
  const handleClose = () => {
    setOpenDeleteComment(false)
    setOpenDeleteReply(false)
  }
  useEffect(() => {
    setIsLiked(getLikeStatus())
    // setLikes(props.item.likes)

    let newArray: any[] = [];

    props.item?.attributes?.videos?.map((item: any) => {
      newArray.push({ source: item.url });
    })

    props.item?.attributes?.images?.map((item: any) => {
      newArray.push({ source: item.url });
    })

    setSourceArray(newArray);

  }, [props.item])


  const openMenu = (event: any) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };
  const onSetValueClick = (item: any) => {
    setViewText(true)
    setValueText(`@${item.userName}`)
  }
  const onMenuItemClick = (event: any, index: any) => {
    setAnchor(null);
    setSelected(index);
    if (index === 1)
      props.deleteFeed(props.item.id)
    if (index === 0)
      props.handleEdit(props.item)
  };
  const handleCloseClick = () => {
    setViewText(false)
    setReplyComment(null)
  }
  return (
    <div className='activity-item'>
      <div className="user-detail-card seventy-percent-wrapper">
        <div className="chat-card-sec">
          <div className="profile-img-container">
            <img src={props.item.attributes.profile_image === null ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" : props.item.attributes.profile_image} alt={props.item.attributes.user_name} />
            <span className={`dots ${props.item.attributes.user_type === "active" ? "dots-active" : "dots-inactive"}`} />
          </div>
          <div className="profile-detail">
            <div className='follow-unfollow-tag'>
              <p className={`profile-detail-user-name ${props.context.isDark ? 'text-color' : ''}`}>{props.item.attributes.user_name}</p>
              {props.item.attributes.is_follow === "current_account" ? null : <p className='profile-detail-user-name dot-symbol' style={{ marginLeft: "3px" }}> .</p>}
              {props.item.attributes.is_follow === "current_account" ? null : props.item.attributes.is_follow === "following" ? <span onClick={() => props.unFollowUserFromPost(props.item.attributes.account_id)}>Following</span> : <span onClick={() => props.followingUserFromPost(props.item.attributes.account_id)}>Follow</span>}

            </div>
            <p className={`profile-detail-user-status  ${props.context.isDark ? 'text-color' : ''}`}>{props.item.attributes.user_type}</p>

          </div>
        </div>
        <div className='chat-icon-mui'>
          <img src={chatImage} alt="chat" onClick={() => props.handleOpenChat(props.item.attributes.email)} />
        </div>
        {/* @ts-ignore */}
        {props.item.attributes.account_id == JSON.parse(localStorage.getItem("profileData")).id && <img className="pointer-class vertical-ellipse-class" onClick={openMenu} src={verticalEllipse} alt={props.item.attributes.user_name} />}

        <Menu
          open={Boolean(anchor)}
          anchorEl={anchor}
          onClose={closeMenu}
          keepMounted
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={(event) => onMenuItemClick(event, index)}
              selected={index === selected}
            >
              <img className='dropdown-img-class' src={option.image} />
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
      {sourceArray?.length ? <div style={{ width: "70%" }} className={`image-cube ${sourceArray.length === 1 ? "hidePointer" : ""}`}>
        <AwesomeSlider
          media={sourceArray}
          style={{
            display: "block",
            overflow: "hidden",
            position: "relative",
            height: "100%",
            width: "100%",
            borderRadius: "10px"
          }}
          organicArrows={sourceArray.length > 1 ? true : false}
        />
      </div> : null}
      <h5 className={`seventy-percent-wrapper ${props.context.isDark ? 'icon-color' : ''}`}>
        {props.item.attributes.description}
      </h5>

      <div className={`Like-reply-main-class`}>
        <Checkbox checked={isLiked} onChange={() => {
          if (!isLiked) {
            // setLikes(likes + 1)
            props.likeAction(props.item.id);
          } else {
            props.deleteLikeAction(props.item.id)
            // setLikes(likes - 1)
          }
          setIsLiked(!isLiked)
        }} icon={<FavoriteBorder className={`${props.context.isDark ? 'icon-color' : ''}`} style={{ height: 30, width: 30 }} />}
          checkedIcon={<Favorite style={{ height: 30, width: 30 }} />}
          name="checkedH" />
        <p className={`like-text-class ${props.context.isDark ? 'icon-color' : ''}`}>{props.item.attributes.likes}</p>
        <img
          onClick={() => {
            props.handleOpenAllComments(props.item.id)
            props.fetchCommentsData(props.item.id)
          }}
          className="like-button-class"

          src={bubbleChat}
          alt={"like"}
        />
        <p className="like-text-class">{props.item.attributes.comments ?? ''}</p>
      </div>

      {props.openAllComment === props.item.id && <div className="comments-main-class" >
        {props?.allComments?.map((item: any) => {
          return (
            <div key={item.id} style={{ display: "flex", flexDirection: "column" }} >
              <ItemsList openDeleteReply={openDeleteReply} setOpenDeleteReply={setOpenDeleteReply} onHandleOpenReply={onHandleOpenReply} setOpenDeleteComment={setOpenDeleteComment} openDeleteComment={openDeleteComment} onHandleOpenComment={onHandleOpenComment} deleteComments={props.deleteComments} item={props.item} verticalEllipse={verticalEllipse} onSetValueClick={onSetValueClick} data={{ postId: item.attributes.post_id, accountId: item.attributes.account_id, commentId: item.id, userImage: item.attributes.profile_pic || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png', userName: item.attributes.user_name, comment: item.attributes.description, nestedComments: item.attributes.comment_reply }} replyComment={props.replyComment} message={currentValue} inputRef={props.textareaRef} setReplyComment={setReplyComment} />
            </div>

          );
        })}
      </div>
      }

      {showEmojiPicker &&
        <EmojiPicker previewConfig={{ showPreview: false }} onEmojiClick={(emoji) => {
          let val = currentValue + `${emoji.emoji}`
          setCurrentValue(val)
          setShowEmojiPicker(!showEmojiPicker)
          setButtonDisabled(false);
        }} searchDisabled height={300} width={'70%'} />
      }

      {props.openAllComment === props.item.id && <div className="comment-text-area-wrapper add-post-class">
        {viewText ?
          <div className='reply-comment-view'>
            <p style={{ color: "white", fontWeight: "bold" }}>{valueText}</p>
            <span onClick={handleCloseClick}>
              <CloseIcon />
            </span>
          </div>
          : ""}
        <textarea ref={props.textareaRef} value={currentValue} onChange={(e) => {
          handleClose()
          setCurrentValue(e.target.value)
          if (e.target.value === "") {
            setButtonDisabled(true);
          } else {
            setButtonDisabled(false);
          }

        }} placeholder="Enter your message here" className={`user-chat-input-text-area ${props.context ? "textfield-color" : ''}`} />
        <div className="user-input-action-buttons">

          <div className="pointer-class div-align-center">
            <input
              ref={props.uploadImgRef}
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              name="image-upload"
              id="input"
              data-test-id='file-input-handler'

            />
            <img
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="smile-icon-class"
              src={smile}
            />
          </div>
          <button disabled={buttonDisabled} className={buttonDisabled ? "input-field-send-icon color-disabled" : "input-field-send-icon"} onClick={() => {
            if (replyComment !== null) {
              //@ts-ignore
              props.replyComment({ parentId: replyComment?.commentId, globalPostId: replyComment?.postId, message: currentValue });
              setReplyComment(null);
              setViewText(false)
            } else {
              props.postNewComments(props.item.id, currentValue)
            }
            setCurrentValue("")
            setButtonDisabled(true)
          }}>
            <SendIcon />
          </button>

        </div>
      </div>}

    </div>

  );
}