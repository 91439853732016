import React, { useState,useRef } from "react";
import "./SearchInput.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { debounce} from "lodash";

function AutoSearch({ placeholder, debounceSearchFunc, data, navigation, defaultImage,error }: any) {
  const [ open, setOpen] = useState(false)
  const [word, setWord] = useState("");
  const inputRef = useRef(null)
  const handletext = debounce((word: string) => {
    setWord(word);
  
    console.log(word,"word123")
    debounceSearchFunc(word)
    setOpen(true)
  }, 500);

  const clearInput = () => {
    console.log("clicked")
    //@ts-ignore
    inputRef.current.value = null
    setOpen(false)
    data = [] 
    setWord("");

  };

  return (
    <>
    <div className="search">
      <div className="searchInputs">
        <input
          ref={inputRef}
            type="text"
            placeholder={placeholder}
            onChange={(e) => handletext(e.target.value)}
          />
          <div className="searchIcon">
            {data.length === 0 || !open ? (
              <SearchIcon />
            ) : (
              <CloseIcon id="clearBtn" onClick={()=> clearInput()} />
            )}
          </div>
        </div>
        {data.length != 0 && open && (
          <div className="dataResult">
            {data.slice(0, 15).map((value: any) => {
              return (
                <div key={value.id} className="dataItem" onClick={() => {
                  navigation.history.push({
                    pathname: `/user-post/${value.attributes.user_name.replace(/\s/g, '-')}`, state: {
                      value
                    }
                  })

                  clearInput()
                }}>
                  <img style={{ width: "30px", height: "30px", borderRadius: "50%", marginLeft: "20px" }} src={value.profile_pic.url === null ? defaultImage : value.profile_pic.url} />
                  <p>{value.title} </p>
                </div>
              );
            })}
          </div>
        )}
      </div >
    </>
  );
}

export default AutoSearch;
