/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import ThemeContext from "../../../components/src/ThemeContext.web";
import SearchIcon from '@material-ui/icons/Search';
import ContentLoader from 'react-content-loader'
import { chatImage, chatFollow } from "./assets";
import "./Friends.scss"
import FriendsController, { Props } from "./FriendsController";
import UserProfileCard from "../../../components/src/UserProfileCard";
import AccountDetailCard from "../../../components/src/AccountDetailCard";
import { FeedView } from "../../../components/src/FeedView.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class Friends extends FriendsController {
    constructor(props: Props) {
        super(props);
    }
    MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        {Array(4).fill(4).map((item, i) => <ContentLoader key={i} />)}
    </div>)
    feedTabData = (item: any, index: any) => (

        <React.Fragment key={index}>
            <FeedView navigation={this.props.navigation}
                loading={this.state.loading}
                item={item}
                allComments={this.state.feedComments}
                fetchCommentsData={this.fetchAllCommentsData}
                postNewComments={this.addNewComments}
                likeAction={this.likePost}
                deleteLikeAction={this.deleteLikePost}
                userPostList={this.state.userPostList}
                userLikes={this.state.userLikes}
                replyComment={this.replyOnComment}
                deleteComments={this.deletePostComment}
                context={this.context}
                textareaRef={this.textareaRef}
                openAllComment={this.state.openAllComment}
                handleOpenAllComments={this.handleOpenAllComments}
                selectedUserEmail={this.state.selectedUserProfile}
                handleOpenChat={this.handleOpenChat}
            />
        </React.Fragment>
    )
   
    render() {
        return (
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation} selected="Friends" />
                <Grid container className={`main-container container-inner-main app-page-dashboard`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`container-items pd`}
                    >
                        <Navbar context={this.context} title="All Friends" navigation={this.props.navigation} />
                        <Grid container>
                            <Grid item md={3} lg={3} className="left-section">
                                <div className="search-friend">
                                    <input data-test-id="on-search" type="text" placeholder="Search friend" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
                                    <SearchIcon />
                                </div>
                                <div className="friend-section">
                                    {this.state.loading ? this.MyLoader() : this.state.accountListData.length === 0 ? <div>No list found</div> : (
                                        <>
                                            <p>{this.state.accountListData.length} friends</p>
                                            <div className="scroller scroll-item scroller-width">
                                                {this.state.accountListData.filter((item => item.attributes.first_name.toLowerCase().startsWith(this.state.query))).length > 0 ? (
                                                    this.state.accountListData.filter((item => item.attributes.first_name.toLowerCase().startsWith(this.state.query))).map((item, index) => (
                                                        <React.Fragment key={item.id}>
                                                            <AccountDetailCard item={item} activeItem={this.state.activeItem} profile_pic={this.state.profile_pic} specificAccountActivity={this.specificAccountActivity} chatImage={chatImage} handleOpenChat={this.handleOpenChat} />
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    <p>Match not found</p>
                                                )}
                                            </div>

                                        </>
                                    )}
                                </div>
                            </Grid>
                            <Grid item md={9} lg={9}>
                                <div style={{ width: "80%", margin: "auto" }} className="user-post">
                                    <UserProfileCard unFollowFromFollowing={this.unFollowUser} userData={this.state.specific_account} context={this.context} chatImage={chatFollow} loading={this.state.loading} handleOpenChat={() => {
                                        this.handleOpenChat(this.state.selectedUserProfile)
                                    }} />
                                    <div className="specific-post">
                                        {this.state.specific_account_post.length === 0 ? <div>No post to show</div> :
                                            this.state.specific_account_post.map(this.feedTabData)
                                        }
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        );
    }
}
export default Friends;

Friends.contextType = ThemeContext;

// Customizable Area End