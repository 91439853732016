
/** @format */

import React from "react";
// Customizable Area Start
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import NavbarDropdownModal from "./NavbarDropdownModal.web";
import "./Navbar.scss";
import NavbarController, { Props } from "./NavbarController";
import AutoSearch from "../../../components/src/AutoSearch";
// Customizable Area End


// Customizable Area Start
class Navbar extends NavbarController {
	constructor(props: Props) {
		super(props);
	}
	render() {
		return (
			<>
				<div className="navbar-sec">
					<div data-test-id="navigate-button" style={{ cursor: "pointer" }} onClick={this.handleNavigate}>
						<h1 className={`navbar-header ${this.props.context.isDark ? "color-white" : ""}`}>{this.props.title}</h1>
					</div>
					<AutoSearch debounceSearchFunc = {this.searchUserDebounce} placeholder="Search for users" defaultImage={this.state.defaultImage} data={this.state.muiSearchData} navigation={this.props.navigation} />
					<div className="navbar-section-inner">
						<div className={`navbar-profile ${this.props.context.isDark ? "color-white" : ""}`}>
							{Object.keys(this.state?.profileData).length === 0 ? <div>loading...</div> : (
								<>
									{this.state?.profileData?.attributes?.profile_pic.url === null ? <img src={this.state?.defaultImage} alt="profile" /> : <img src={this.state.profileData.attributes.profile_pic.url} alt="profile" />}

									<h3 className={`hello-text ${this.props.context.isDark ? "color-white" : ""}`}>Hello, {this.state?.profileData?.attributes?.first_name} {this.state?.profileData?.attributes?.last_name}</h3>
								</>
							)}
							<div data-test-id="expand-button" onClick={this.handleOpenModal}>
								<ExpandMoreIcon />
							</div>
						</div>
						<div className={`navbar-icon-sec ${this.props.context.isDark ? "color-white" : ""}`}>
							<FavoriteBorderOutlinedIcon />
							<ChatBubbleOutlineOutlinedIcon />
							<div onClick={this.handleNotification}>
								<NotificationsNoneOutlinedIcon />
							</div>
							<AccountBalanceWalletOutlinedIcon />
						</div>
						<NavbarDropdownModal
							open={this.state.open}
							onClose={this.onClose}
							context={this.props.context}
							logOutUser={this.logOutUser}
							navigation={this.props.navigation}
							data={{ first_name: this.state?.profileData?.attributes?.first_name, last_name: this.state?.profileData?.attributes?.last_name }}
						/>
					</div>
				</div>

			</>
		);
	}
}
export default Navbar;

// Customizable Area End
