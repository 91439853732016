import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline-block',
        marginRight: "20px"
    },
}));

export default function ItemsList({ onSetValueClick, data, setOpenDeleteReply, item, openDeleteReply, onHandleOpenReply, setOpenDeleteComment, openDeleteComment, onHandleOpenComment, replyComment, verticalEllipse, inputRef, message, setReplyComment, deleteComments }: any) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const nestedCommentData = data.nestedComments
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={data?.userImage} />
                </ListItemAvatar>
                <ListItemText

                    primary={
                        <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {data?.userName}
                            </Typography>
                            {/* @ts-ignore */}
                            {data?.accountId == JSON.parse(localStorage.getItem("profileData")).id && <img className="pointer-class vertical-ellipse-class" onClick={() => onHandleOpenComment(data?.commentId)} src={verticalEllipse} alt={item.attributes.user_name} />}
                            {openDeleteComment === data?.commentId &&
                                <div className='delete-modal'>
                                    <div className='delete-sec' onClick={() => deleteComments(data?.commentId)}>
                                        <DeleteIcon />
                                        <p>Delete</p>
                                    </div>

                                    <CloseIcon className='close-sec' onClick={() => setOpenDeleteComment(false)} />
                                </div>}
                        </div>

                    }
                    secondary={
                        <ListItemText
                            primary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {data?.comment}
                                </Typography>
                            }
                            secondary={
                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>

                                    <Typography
                                        variant="body1"
                                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                        onClick={() => {
                                            inputRef.current.focus();
                                            setReplyComment({ postId: data.postId, commentId: data.commentId });
                                            onSetValueClick(data)
                                        }}
                                        color="textPrimary">
                                        reply
                                    </Typography>

                                    <Typography style={{ marginLeft: 20, cursor: 'pointer' }} onClick={handleClick}>─ view replies</Typography>
                                </Box>
                            }
                        />
                    }
                />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data?.nestedComments?.map((item: any, index: number) => (
                        <ListItem key={index} alignItems="flex-start" style={{ marginLeft: 40 }}>
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={item.attributes.profile_pic} />
                            </ListItemAvatar>
                            <ListItemText
                                secondary={
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {item.attributes?.description}
                                        </Typography>

                                    </div>
                                }
                                primary={
                                    <div style={{ display: "flex", alignItems: "center", position: 'relative' }}>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {item.attributes?.user_name}
                                        </Typography>
                                        {/* @ts-ignore */}
                                        {item.attributes.account_id == JSON.parse(localStorage.getItem("profileData")).id && <img className="pointer-class vertical-ellipse-class" src={verticalEllipse} onClick={() => onHandleOpenReply(item?.id)} alt={item.attributes.user_name} />}
                                        {openDeleteReply === item?.id &&
                                            <div className='delete-modal'>
                                                <div className='delete-sec' onClick={() => deleteComments(item?.id)}>
                                                    <DeleteIcon />
                                                    <p>Delete</p>
                                                </div>

                                                <CloseIcon className='close-sec' onClick={() => setOpenDeleteReply(false)} />
                                            </div>}
                                    </div>

                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
}
