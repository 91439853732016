Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";

exports.btnExampleTitle = "CLICK ME";
exports.userDetailApiEndPoint = "account_block/account_activities";
exports.getActivityFeed = "bx_block_posts/global_post";
exports.likeFeed = "bx_block_like/likes";
exports.fetchUserForChatApiEndPoint = "bx_block_login/logins/suggestion";
exports.getMethodForUserData = "GET";
exports.createNewChannelMethod = "POST";
exports.createNewChannelApiEndpoint = "bx_block_chat9/create_channel";
exports.fetchUserChatListApiEndPoint = "bx_block_chat9/chat_lists"
exports.fetchUserCommentsApiEndPoint = "bx_block_comment/comments"
exports.postCommentsApiEndPoint = "bx_block_comment/comments"
exports.replyCommentApiEndPoint = "bx_block_comment/reply_comment";
exports.getTwilioTokenApiEndPoint = "bx_block_chat9/generate_chat_token";
exports.getTwilioTokenApiMethod = "POST";
exports.getUserPostList = "bx_block_posts/posts";
exports.getPostList = "bx_block_posts/posts";
exports.getDeleteCommentApiMethod = "DELETE";
exports.getUserFollowingPostListApiEndPoint = "bx_block_followers/follow/following_post";
exports.accountListApiEndPoint = "/bx_block_login/logins/suggestion";
exports.specificAccountApiEndPoint = "account_block/specific_account_details"
exports.deleteFollowApiEndPoint = "bx_block_followers/follow/unfollow_account"
exports.addFollowersApiEndPoint = "bx_block_followers/follows";
exports.addNewChatGlobalSearch="account_block/global_search"
// Customizable Area End 