/** @format */

export const profileImg1 = require("../assets/profile1.png");
export const profileImg2 = require("../assets/profile2.png");
export const profileImg3 = require("../assets/profile3.png");
export const imageCube = require("../assets/cube.png");
export const scobbyImg = require("../assets/scobby.png");
export const beastImg = require("../assets/beast.png");
export const notificationSound = require("../assets/notification.mp3");
export const chatImage = require("../assets/chat_Image.svg");
