// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    test?: any;
    context?: any;
    classes?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    notificationSettings: {
        notification_for_nft: boolean,
        notification_for_exchange_fail_success: boolean,
        notification_for_bids: boolean,
        notification_for_price_increase_decrease: boolean,
        notification_for_sale_purchase: boolean,
        notification_for_message: boolean,
        notification_for_post: boolean,
    };
    openSnackbar: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

 // Customizable Area Start
export default class NotificationSettingController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            notificationSettings: {
                notification_for_nft: false,
                notification_for_exchange_fail_success: false,
                notification_for_bids: false,
                notification_for_price_increase_decrease: false,
                notification_for_sale_purchase: false,
                notification_for_message: false,
                notification_for_post: false,
            },
            openSnackbar: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleCloseSanacbar = () => {
        this.setState({ openSnackbar: false })
    }
 
    handleNotificationSettings = (key: string) => {
        let notificationSettings = { ...this.state.notificationSettings };
        //@ts-ignore
        notificationSettings[key] = !notificationSettings[key];

        this.setState({
            notificationSettings,
            openSnackbar: true
        });
    }
    // Customizable Area End

}
// Customizable Area End
