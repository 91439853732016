/** @format */

import React from "react";
// Customizable Area Start
import Chat from "./Chat.web";
import ChatList from "./ChatList.web";
import "./Chat.scss";
import "./ActivityFeed.scss";
import ChatMainComponentController from "./ChatWebController";
// Customizable Area End

// Customizable Area Start
class ChatMainComponent extends ChatMainComponentController {
	render() {
		const {newMessage, loading, chatEmojiPickerVisible, profileData, userData, searchData, newChatModalVisible, userChatList, isFetchingChatList , addNewSearch } = this.state
		return (
			<div style={{ flex: 1 }} className="chat-main-class scroller scroller-width">
				<ChatList
				    //@ts-ignore
				    userData={userData}
					addNewSearch={addNewSearch}
				    searchData={searchData}
				    newChatModalVisible={newChatModalVisible}
				    userChatList={userChatList}
				    isFetchingChatList={isFetchingChatList}
				    handleNewChatModal={this.handleNewChatModal}
				    handleSearch={this.handleSearch}
				    openUserChatHandler={this.openUserChatHandler}
				    handleNewChatWithUser={this.handleNewChatWithUser}
					handleAddNewSearch={this.handleAddNewSearch}
					searchValue={this.state.searchValue}
					selectedUserId={this.state.currentSelectedUser?.email}
					isFetchingUsersForChat={this.state.isFetchingUsersForChat}
				 />

				<Chat
				    //@ts-ignore
					handleMessageSubmit={this.handleMessageSubmit}
					messages={this.state.messages}
				    chatEmojiPickerVisible={chatEmojiPickerVisible}
				    newMessage={newMessage}
				    loading={loading}
				    profileData={profileData}
				    messagesEndRef={this.messagesEndRef}
				    textareaRef={this.textareaRef}
				    handleChangeTextArea={this.handleChangeTextArea}
				    uploadImgRef={this.uploadImgRef}
				    context={this.context}
					handleEmojiPicker={this.handleEmojiPicker}
					EmojiSelectHandler={this.EmojiSelectHandler}
					newChannelData={this.state.newChannelData}
                    currentSelectedUser={this.state.currentSelectedUser}
					chatImages={this.state.chatImages}
					handleFileUpload={this.handleFileUpload}
					closeFileHandler={this.closeFileHandler}
					chatAttachment={this.state.chatAttachment}
					handleChatUIResponse={this.handleChatUIResponse}
				 />
			</div>
		);
	}
}

export default ChatMainComponent;
// Customizable Area End
