import React, { useEffect, useRef, useState } from 'react'
const ChatImage = (props: any) => {
  const [mediaUrl, setMediaUrl] = useState("")
  const [mediaType, setMediaType] = useState("")
  const [ inputVal , setInputVal] = useState("")
  useEffect(() => {
    props.media.getContentTemporaryUrl().then((url: any) => setMediaUrl(url))
    setMediaType(props.media.contentType)
  }, [])
  const fileRef = useRef(null)
  const handleOpen = () => {
    //@ts-ignore
    fileRef.current?.click()
  }
  const handleChangeInput = (e:any)=> {
    setInputVal(e.target.value)
  }
  return (
    <>
    {mediaType.includes("image") ?
      <div><img src={mediaUrl} alt="" style={{ "width": "125px" }} /></div> :
      mediaType.includes("video") ?
        <div>
          <video src={mediaUrl} controls style={{ "width": "125px" }} />
          
        </div> :
        <div className='doc-card-main' data-test-id="chat-image" onClick={() => handleOpen()}><a ref={fileRef} href={mediaUrl} target='_blank'></a>{props.media?.filename}</div>
  }
        <input style={{display:"none"}} type='text' data-test-id="input-val" value={inputVal} onChange={handleChangeInput} />
        </>
  )
}

export default ChatImage