// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    item?: any;
	context?:any;

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userData: any;
    loading:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class FollowingChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
	  onlineFollowingUserCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            userData: [],
            loading:false
      };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
		this.getOnlineFollowingUser()
        // Customizable Area End  

    }
    // Customizable Area Start
  getToken = () => {
	const token = localStorage.getItem("accessToken")
	return token
}

handleOpenChat = (email: string) => {
    this.props.navigation.history.push({pathname: "ActivityFeed", state:  {
        currentTab: "chat",
        email
    }})
}

  getOnlineFollowingUser = () => {
    this.setState({loading:true})
	const headers = {
		"Content-Type": configJSON.dashboarContentType,
		token:  this.getToken()
	};

	const onLineUserMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
	);

	this.onlineFollowingUserCallId = onLineUserMessage.messageId;

	onLineUserMessage.addData(
		getName(MessageEnum.RestAPIResponceEndPointMessage),
	  configJSON.onlineFollowingUserApiEndPoint
	);

	onLineUserMessage.addData(
		getName(MessageEnum.RestAPIRequestHeaderMessage),
		JSON.stringify(headers)
	);

	onLineUserMessage.addData(
		getName(MessageEnum.RestAPIRequestMethodMessage),
		configJSON.profileDetailApiMethodType
	);

	runEngine.sendMessage(onLineUserMessage.id, onLineUserMessage);
}

handleResponseForOnlineUser = (from: string, message: Message) => {
	
	if (this.onlineFollowingUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
		const apiResponse = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiResponse) {
			this.setState({loading:false, userData:apiResponse.data})

		} 
	}
}
    // Customizable Area End  

    async receive(from: string, message: Message) {
        
        // Customizable Area Start
		this.handleResponseForOnlineUser(from,message)
        // Customizable Area End
    }

}
