/** @format */

import React from "react";

import {
	Box,
	Button,
	Typography,
	// Customizable Area Start
	Grid,
	Modal,
	Snackbar
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Navbar from "../../dashboard/src/Navbar.web";
import "./ActivityFeed.scss";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import {
	activityImage,
	activitySelected,
	chatImage,
	chatSelected,
	userImage,
	userSelected,
	whitePlus,
} from "./assets";
import ChatMainComponent from "./ChatMainComponent.web";
import FeedTab from "./FeedTab.web";
import ThemeContext from "../../../components/src/ThemeContext.web";
import UserActivity from "./UserActivity.web";
// Customizable Area End

import ActivityFeedController, { Props } from "./ActivityFeedController";
import { CreatePostModal } from "../../../components/src/CreatePostModal.web";

class ActivityFeed extends ActivityFeedController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	
	// Customizable Area Start
	renderScreen = () => {
		if(this.props?.navigation?.history?.location?.state?.currentTab && !this.state.isClickedOnTab) {
					return <ChatMainComponent navigation={this.props.navigation}  targetEmail={this.state.targetEmail} />;
		} else {
			switch (this.state.tabValue) {
				case "activity":
					return <FeedTab navigation={this.props.navigation}
						loading={this.state.loading}
						feedData={this.state.feedData}
						postComments={this.state.postComments}
						fetchCommentsData={this.fetchCommentsData}
						postNewComments={this.postNewComments}
						likeFeed={this.likeFeed}
						deleteLikeFeed={this.deleteLike}
						textareaRef={this.textareaRef}
						uploadImgRef={this.uploadImgRef}
						userPostList={this.state.userPostList}
						userLikes={this.state.userLikes}
						deleteFeed={this.deleteFeed}
						handleEdit={this.handleEdit}
						scrollHandle={this.handleScroll}
						scrollRef={this.scrollRef}
						replyComment={this.replyComment}
						deleteComments={this.deleteComments}
						unFollowUserFromPost={this.unFollowUserFromPost}
						followingUserFromPost={this.followingUserFromPost}
						openAllComment={this.state.openAllComment}
						handleOpenAllComments={this.handleOpenAllComments}
					/>;
				case "chat":
					return <ChatMainComponent navigation={this.props.navigation} targetEmail={this.state.targetEmail}/>;
				case "user":
					return <UserActivity navigation={this.props.navigation} userFollowingPost={this.state.userFollowingPost} loading={this.state.loading}
					postComments={this.state.postComments}
					fetchCommentsData={this.fetchCommentsData}
					postNewComments={this.postNewComments}
					likeFeed={this.likeFeed}
					deleteLikeFeed={this.deleteLike}
					userPostList={this.state.userPostList}
					userLikes={this.state.userLikes}
					replyComment={this.replyComment}
					deleteComments={this.deleteComments}
					context={this.context}
					textareaRef={this.textareaRef}
					openAllComment={this.state.openAllComment}
					handleOpenAllComments={this.handleOpenAllComments}
					handleOpenChat={this.handleOpenChat}
					/>;
				default:
					return <FeedTab navigation={this.props.navigation}  feedData={undefined} postComments={undefined} fetchCommentsData={undefined} postNewComments={undefined} likeFeed={undefined} deleteLikeFeed={undefined} textareaRef={undefined} uploadImgRef={undefined} userPostList={undefined} userLikes={undefined} deleteFeed handleEdit />;
			}
		}
	};





	// Customizable Area End

	render() {
		// Customizable Area Start
		const { tabValue, postModalVisible } = this.state;
		const currentTabFromHistory = !this.state.isClickedOnTab ? this.props.navigation?.history?.location?.state?.currentTab: null;
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
				<div className={`${this.props?.context?.isDark ? "color-white" : "snackbar-error"}`}>
					<Snackbar
						open={this.state.showsnackbar}
						message={this.state.snackBarMessage}
						onClose={this.handleCloseSnackBar}
						autoHideDuration={2000}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					/>
				</div>
				<DashboardSidebar
					context={this.context}
					history={this.props.navigation}
					selected="Activity"
				/>

				<Grid container className="main-container app-page-dashboard">

					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						className={`container-items ${this.context.isDark ? "dark-color" : ""}`}
					>
						<Navbar title="Welcome" navigation={this.props.navigation} context={this.context} />
						<div className={`activity-feed-container ${this.context.isDark ? "dark-color" : ""} `}>
							<div className={`${this.context.isDark ? "dark-color" : ""} tab-top-bar-bg `}>
								<div
									style={{
										flex: 1,
									}}
									data-test-id={"onActivityTabClick"}
									onClick={() => {
										this.setState({ tabValue: "activity", isFollowingPost: false, isClickedOnTab: true ,feedData:[]});
										this.getActivityFeedData(this.state.feedDataPage)
									}}
									className={
										(tabValue == "activity" && !currentTabFromHistory)
											? `selected-tab ${this.context.isDark ? "dark-color" : ""}`
											: "unselected-tab"
									}>
									<img
										className="tab-item-img"
										src={
											(tabValue == "activity" && !currentTabFromHistory)
												? activitySelected
												: activityImage
										}
									/>

									{(tabValue == "activity" && !currentTabFromHistory) && (
										<h4 className="tab-item-text">
											Activity
										</h4>
									)}
								</div>

								<div
									style={{
										flex: 1,
									}}
									data-test-id={"onChatTabClick"}
									onClick={() => {
										this.setState({ tabValue: "chat", isClickedOnTab: true });
									}}
									className={
										(tabValue == "chat" || currentTabFromHistory === "chat")
											? `selected-tab ${this.context.isDark ? "dark-color" : ""}`
											: "unselected-tab"
									}>
									<img
										className="tab-item-img"
										src={(tabValue == "chat" || currentTabFromHistory === "chat") ? chatSelected : chatImage}
									/>
									{(tabValue == "chat" || currentTabFromHistory === "chat") && (
										<h4 className="tab-item-text">
											Chat
										</h4>
									)}
								</div>

								<div
									style={{
										flex: 1,
									}}
									data-test-id={"onUserTabClick"}
									onClick={() => {
										
										this.setState({ tabValue: "user", isClickedOnTab: true,isFollowingPost: true });
										this.getUserFollowingPostList()
									}}
									className={
										tabValue == "user"
											? `selected-tab ${this.context.isDark ? "dark-color" : ""}`
											: "unselected-tab"
									}>
									<img
										className="tab-item-img"
										src={tabValue == "user" ? userSelected : userImage}
									/>
									{tabValue == "user" && (
										<h4 className="tab-item-text">
											User
										</h4>
									)}
								</div>
							</div>
						</div>
						{this.renderScreen()}
					</Grid>

					{(tabValue == "activity" && !currentTabFromHistory) && (
						<div
							data-test-id={"onCreatePostCLick"}
							onClick={this.handleOpen}
							className="create-post-class">
							<div
								style={{ alignItems: "center", justifyItems: "center" }}
								className="plus-button">
								<img
									className="plus-icon"
									src={whitePlus}
									alt={"like"}
								/>
							</div>
							<h5 className="margin-top-small">Create post</h5>
						</div>
					)}
				</Grid>

				<CreatePostModal
					postModalVisible={postModalVisible}
					textareaRef={this.textareaRef}
					uploadImgRef={this.uploadImgRef}
					createPostImageData={this.state.createPostImageData}
					handleFileUpload={this.handleFileUpload}
					context={this.context.isDark}
					handleCloseModel={this.handleCloseModel}
					createPost={this.createPost}
					closeFileHandler={this.closeFileHandler}
					loggedInUser={this.state.loggedInUser}
					postTextValue={this.state.postTextValue}
					setPostText={this.setPostText}
					isEditPost={this.state.isEditPost}
					editPost={this.editPost}
				/>

			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

ActivityFeed.contextType = ThemeContext;

export default ActivityFeed
// Customizable Area End
