/** @format */

export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const activityImage = require("../assets/activity.svg");
export const chatImage = require("../assets/chat.svg");
export const userImage = require("../assets/user.svg");
export const activitySelected = require("../assets/activity_selected.svg");
export const chatSelected = require("../assets/chat_selected.svg");
export const userSelected = require("../assets/user_selected.svg");
export const heartFilled = require("../assets/heart.png");
export const whitePlus = require("../assets/plus.png");
export const globe = require("../assets/world.svg");
export const clip = require("../assets/paperclip.svg");
export const smile = require("../assets/smile.svg");
export const closeImage = require("../assets/close.png");
export const location = require("../assets/location.png");
export const search = require("../assets/search.png");
export const send = require("../assets/send.png");
export const profileImg = require("../assets/profile1.png");
export const imageCube = require("../assets/cube.png");
export const gameImage = require("../assets/game.jpg");
export const bubbleChat = require("../assets/bubble-chat.png");
export const heartOutlined = require("../assets/heart_outlined.png");
export const verticalEllipse = require("../assets/more.png");
export const edit = require("../assets/edit.png");
export const deleteImg = require("../assets/delete.png");
export const video = require("../assets/video.png");
export const gallery = require("../assets/gallery.png");
export const loaderAnimation = require("../assets/loader.json");

