// Customizable Area Start
import React from "react"; 
import "./NftCard.scss";
// Customizable Area End


const NftCard = ({image, title}: any) => {
    return (
        <div className="nft-card">
           <img className="nft-card-image" src={image} /> 
        </div>
    )
}


export default NftCard;