// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
    Container,
    Box,
    Typography
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { webStyle } from "./IsoData"
import Snackbar from '@material-ui/core/Snackbar'
// Customizable Area End

import CurrencyController, {
    Props,
} from "./CurrencyController";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default class Currency extends CurrencyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End


    render() {
        return (
            // Customizable Area Start
            <>
            	<div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.openCurrencyToast}
			autoHideDuration={2000}
					onClose={this.handleCloseCurrencyToast}
			message='Currency changed successfully!'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
                </div>
                <Container maxWidth={"sm"}>
                <Box className={`${this.props.context.isDark ? "background-none" :""}`} sx={webStyle.mainWrapper}>
                    <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="h4" style={webStyle.settingTitle}>Currency</Typography>
                    <p className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.settingDescription}>
                        Change the currency that is displayed in the app
                    </p>
                    <Box style={{ marginTop: "15px" }}>
                        <Box sx={webStyle.pickerWrapper}>
                            <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant='body2' style={webStyle.soundsettingTitle}>Preferred Currency</Typography>
                                <Typography className={`${this.props.context.isDark ? "color-white" :""}`} style={{fontFamily:'Gilroy-Regular',color:'#1D1350'}} variant='body2'>Conversion rates have to be accurate too</Typography>
                            </Box>
                            <Box className={`iso_switch ${this.props.context.isDark ? "currancy-change-dark" :""}`} sx={{ width: 80 }}>
                                <FormControl className="w-100" >
                                    <Select
                                        displayEmpty
                                        className={`app-custom-select-new ${this.props.context.isDark ? 'textfield-color currancy-change-dark' : ''}`}
                                        value={this.state.selectedCurrency}
                                        onChange={(value) => {this.setCurrency(value?.target.value) }}
                                        // IconComponent = {down_icon}
                                        input={<OutlinedInput />}
                                        renderValue={(selected: any) => {
                                            if (selected.length === 0) {
                                                return <span className="app-custom-select-placeholder">Select Currency</span>;
                                            }

                                            return selected;
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        data-test-id="currancy-change"
                                    >
                                        <MenuItem disabled value="">
                                            Select Currency
                                        </MenuItem>
                                        {
                                            this.state.currencyList?.map((item,index) => {
                                              return  <MenuItem key={index} value={item} 
                                                >
                                                    {item}
                                                </MenuItem>
                                            })
                                        }


                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            </>
           
            // Customizable Area End
        );
    }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
