/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import ThemeContext from "../../../components/src/ThemeContext.web";
import ContentLoader from 'react-content-loader'
import UserActivityPostController, { Props } from "./UserActivityPostController";
import UserProfileCard from "../../../components/src/UserProfileCard";
import { FeedView } from "../../../components/src/FeedView.web";


// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class UserProfilePost extends UserActivityPostController {
    constructor(props: Props) {
        super(props);
    }

    feedTabData = (item: any, index: any) => (
		<FeedView
			context={this.context}
			key={index}
			item={item}
            allComments={this.state.postComments}
            fetchCommentsData={this.fetchAllPostCommentsData}
            postNewComments={this.postNewComments}
            likeAction={this.postToLike}
            deleteLikeAction={this.unLikePost}
            userLikes={this.state.userLikes}
            replyComment={this.replyOnCommentedPost}
            deleteComments={this.deletePostComment}
            textareaRef={this.textareaRef}
            openAllComment={this.state.openAllComment}
            handleOpenAllComments={() => this.handleToOpenAllComments(item.id)}
			
		/>
	)

    MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
		{Array(4).fill(4).map((item, i) => <ContentLoader key={i} />)}
	</div>)


    render() {
        console.log(this.state.userCurrentId,"userCurrentId")
        return (
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation}  />
                <Grid container className={`main-container container-inner-main app-page-dashboard ${this.context.isDark ? "dark-color" : ""}`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`container-items pd ${this.context.isDark ? "dark-color" : ""}`}
                    >
                        <Navbar context={this.context} title="Welcome" navigation={this.props.navigation} />
                        <Grid container>
                            <Grid item md={2} lg={2}></Grid>
                            <Grid item md={8} lg={8}>
                            {this.state.userCurrentId === undefined? "": <UserProfileCard userData={this.state?.userCurrentId} followFromFollowing={this.followUser} unFollowFromFollowing={this.unFollowUser} />}
                            </Grid>
                            <Grid item md={2} lg={2}></Grid>
                            <div id={"scrollableDiv"} className={`scroller scroller-width single-user-post`} >
                            {this.state.specific_account_post?.length === 0 ? <div style={{marginLeft:"150px"}}>No post to show</div> :
					            this.state.specific_account_post?.map(this.feedTabData)
				            }
                            </div>
                        </Grid>
                        
                        
                    </Grid>
                </Grid>

            </>
        );
    }
}
export default UserProfilePost;

UserProfilePost.contextType = ThemeContext;

// Customizable Area End