/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { nftImage1, nftImage2, nftImage3, nftImage4, nftImage5, nftImage6, nftImage7, nftImage8, nftImage9 } from "./assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface NftCollectionData {
	id: number | string,
	image: string;
	avatar: string;
	text: string
}
const collectionNftData: NftCollectionData[] = [
	{
		id: 1,
		image: nftImage1,
		avatar: nftImage1,
		text: "CryptoKitties"
	},
	{
		id: 2,
		image: nftImage2,
		avatar: nftImage2,
		text: "LetsWalk"
	},
	{
		id: 3,
		image: nftImage3,
		avatar: nftImage3,
		text: "Ecumenopolis"
	},
	{
		id: 4,
		image: nftImage4,
		avatar: nftImage4,
		text: "ZED RUN"
	},
	{
		id: 5,
		image: nftImage5,
		avatar: nftImage5,
		text: "Monster Cave"
	},
	{
		id: 6,
		image: nftImage6,
		avatar: nftImage6,
		text: "Fidenza by Tyler Hobbs"
	},
	{
		id: 7,
		image: nftImage7,
		avatar: nftImage7,
		text: "Elemental by Fang Lijun"
	},
	{
		id: 8,
		image: nftImage8,
		avatar: nftImage8,
		text: "SYLTARE, Dawn of East"
	},
	{
		id: 9,
		image: nftImage9,
		avatar: nftImage9,
		text: "Capsule House"
	},
]
export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	item?: NftCollectionData;
	open?: any;
	onClose?: any
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	search: string;
	exploreData: NftCollectionData[];
	open: boolean;
	title: string;
	openNftModal: boolean;
	value: string;
	nftPayModal: boolean
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class ExploreNftCollectionController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start

	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

		this.state = {
			search: "",
			exploreData: collectionNftData,
			open: false,
			title: "2 Days",
			openNftModal: false,
			value: "crypto",
			nftPayModal: false
		}
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}

	// Customizable Area Start
	handleSearchChange = (e: any) => {
		this.setState({ search: e.target.value })
	}
	handleCloseModal = (e: any) => {
		this.setState({ open: false })
	}
	handleUserTitle = (e: any) => {
		this.setState({ title: e.target.value });
	};
	handleCloseNftModal = () => {
		this.setState({ openNftModal: false })
	};
	handleChange = (e: any) => {
		this.setState({ value: e.target.value });
	};
	handleCloseNftPayModal = () => {
		this.setState({ nftPayModal: false })
	};
	handleOpenPaymentModal = () => {
		this.setState({ nftPayModal: true })
	}
	// Customizable Area End
}
// Customizable Area End