import React from "react";
import "./NftBanner.scss";

const NftBanner = ({backgroundImage, title}: any) => {
    return (
        <div className="nft-banner"  style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="opacity-wrapper"></div>
            <p className="banner-title">
                {title}
            </p>
        </div>
    )
}


export default NftBanner;