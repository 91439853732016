// Customizable Area Start
import React,{ createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
	// Customizable Area Start
	navigation?: any;
	id?: string;
	item?: any;
	context?: any;
	scrollRef?: any;
	textareaRef?:any

	// Customizable Area End
}

interface S {
	// Customizable Area Start
	userCurrentId: any
	loading: boolean
	feedData: any;
	feedDataPage: number;
	openAllComment: string;
	commentId: string;
	postComments: any;
	specific_account_post: any;
	userLikes: any
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class DashboardControllerWeb extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	getActivityFeedCallId: string = "";
	audioRef: any = null;
	accountListCallId: string = "";
	scrollRef: any;
	followUserCallId: string = "";
	unfollowUserCallId: string = "";
	textareaRef:any
	postToLikeCallId: string = "";
	decreaseLikeCountCallId: string = "";
	allLikesPostCallId: string = "";
	fetchPostCommentsApiId: string = "";
	postNewCommentsCallId: string = "";
	replyCommentedPostCallId: string = "";
	deletePostCommentCallId: string = "";
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);


		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];

		this.state = {
			feedData: [],
			feedDataPage: 1,
			loading: false,
			userCurrentId: this.props.navigation.history.location.state?.value,
			openAllComment: "",
			commentId: "",
			postComments: [],
			specific_account_post: this.props.navigation.history.location.state?.value?.attributes?.posts,
			userLikes: [],
		};
		this.audioRef = createRef();
		this.scrollRef = React.createRef();
		this.textareaRef = React.createRef();
		// Customizable Area End

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	componentDidMount(): any {
		// Customizable Area Start

		this.getAllLikes()
		// Customizable Area End  
	}

	// Customizable Area Start
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		let propUserId = this.props.navigation.history.location.state?.value
		if (propUserId) {
			if (prevState.userCurrentId?.id != propUserId?.id) {
				this.setState({ userCurrentId: propUserId ,specific_account_post:propUserId.attributes?.posts})
			}
		}
	}


	getToken = () => {
		const token = localStorage.getItem("accessToken");
		return token;
	};
	followUser = (account_id: string) => {

		let headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()

		};
		let httpBody = {}
		httpBody = {
			data: {
				attributes: {
					account_id: account_id,

				},
			}
		}
		const followDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.followUserCallId = followDataMessage.messageId;

		followDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.folllowUnfollowApiEndPoint
		);

		followDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);
		followDataMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		followDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.postApiMethodType
		);

		runEngine.sendMessage(followDataMessage.id, followDataMessage);

	}
	unFollowUser = (account_id: string) => {
		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};
		let httpBody = {}
		httpBody = {
			data: {
				attributes: {
					account_id: account_id,

				},
			}
		}

		const unfollowDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.unfollowUserCallId = unfollowDataMessage.messageId;

		unfollowDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.unFollowApisApiEndPoint}/${account_id}`
		);

		unfollowDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);
		unfollowDataMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		unfollowDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.deleteApiMethodType
		);

		runEngine.sendMessage(unfollowDataMessage.id, unfollowDataMessage);
	}

	handleResForUnfollowingUserFromPost = (from: string, message: Message) => {
		if (this.unfollowUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.data) {
				let currentUser = { ...this.state.userCurrentId }
				currentUser.attributes.is_follow = null
				this.setState({ userCurrentId: currentUser })
			}
		}

	}
	handleResponseToFollowFromPost = (from: string, message: Message) => {
		if (this.followUserCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.data) {
				let currentUser = { ...this.state.userCurrentId }
				currentUser.attributes.is_follow = "following"
				this.setState({ userCurrentId: currentUser })

			}
		}
	}
	handleToOpenAllComments = (id: any) => {
		this.setState({ openAllComment: id })
	}
	deletePostComment = (id: any) => {
		const header = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken(),
		};
		const deleteCommentMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.deletePostCommentCallId = deleteCommentMessage.messageId;

		deleteCommentMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.postNewCommentsApiEndPoint + `/${id}`
		);

		deleteCommentMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		deleteCommentMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getDeleteCommentApiMethod
		);

		runEngine.sendMessage(deleteCommentMessage.id, deleteCommentMessage);

		return true;
	}

	replyOnCommentedPost = ({ parentId, globalPostId, message }: any) => {
		const header = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken(),
		};

		const data = {
			parent_id: parentId,
			post_id: globalPostId,
			description: message
		};
		let httpBody = {
			data: data,
		};

		const replyCommentedPostMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.replyCommentedPostCallId = replyCommentedPostMessage.messageId;

		replyCommentedPostMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.replyCommentPostApiEndPoint
		);

		replyCommentedPostMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		replyCommentedPostMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		replyCommentedPostMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.postApiMethodType
		);

		runEngine.sendMessage(replyCommentedPostMessage.id, replyCommentedPostMessage);

		return true;
	}

	deletePostCommentResponseHandler = (from: string, message: Message) => {
		if (this.deletePostCommentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiResponse.errors) {
				console.log("error")

			} else {
				setTimeout(() => {
					this.fetchAllPostCommentsData(this.state.commentId)
				})

			}
		}
	}

	handleResponseForReplyOnCommentPost = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);
		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.replyCommentedPostCallId &&
				responseJson !== undefined
			) {
				const mappedPostComments = this.state.postComments?.map((comment: any) => {
					if (comment.id == responseJson.data?.attributes.parent_id) {
						return {
							...comment,
							attributes: {
								...comment.attributes,
								comment_reply: [...comment.attributes.comment_reply, responseJson.data]
							}
						}
					}
					return comment;
				})
				this.setState({ postComments: mappedPostComments })
			}
		}
	}


	likeFeedUpdate = (apiResponse: any, state: any) => {
		const specific_account_post = state.specific_account_post 

		const updateLIkeFeed = specific_account_post?.map((item: any) => {
			if (item.id == apiResponse?.data?.attributes.likeable_id) {
				item.attributes.likes += 1;
			} else if (item.id == apiResponse?.post_id) {
				item.attributes.likes -= 1;
			}
			return item;
		});

		return updateLIkeFeed;
	}
	handleLikePostResponse = (from: string, message: Message) => {
		if (this.postToLikeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.setState({ specific_account_post: this.likeFeedUpdate(apiResponse, this.state) })
				this.getAllLikes()
			}
		}
	}

	handlleUnLikePostResponse = (from: string, message: Message) => {
		if (this.decreaseLikeCountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				this.setState({ specific_account_post: this.likeFeedUpdate(apiResponse, this.state) })
				this.getAllLikes()
			}
		}
	}
	handleFeedAllLikesResponse = (from: string, message: Message) => {
		if (this.allLikesPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
			const apiResponse = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage),
			);
			if (apiResponse) {
				console.log(apiResponse,"responseof")
				this.setState({ userLikes: apiResponse.data});
			}
		}
	}

	fetchAllPostCommentsData = (id: any) => {
		const header = {
			"Content-Type": configJSON.exampleApiContentType,
			"token": this.getToken(),
		};

		this.setState({ commentId: id })
		const fetchPostscommentsMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.fetchPostCommentsApiId = fetchPostscommentsMessage.messageId;

		fetchPostscommentsMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.fetchAllCommentsApiEndPoint + `/${id}`
		);

		fetchPostscommentsMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		fetchPostscommentsMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getMethodForUserData
		);

		runEngine.sendMessage(fetchPostscommentsMessage.id, fetchPostscommentsMessage);

		return true;
	}

	postNewComments = (id: number, description: string) => {
		const header = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken(),
		};

		const data = {
			post_id: id,
			description: description
		};
		let httpBody = {}

		httpBody = {
			data: data,
		};

		const postNewCommentMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.postNewCommentsCallId = postNewCommentMessage.messageId;

		postNewCommentMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.postNewCommentsApiEndPoint
		);

		postNewCommentMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		postNewCommentMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		postNewCommentMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.postApiMethodType
		);

		runEngine.sendMessage(postNewCommentMessage.id, postNewCommentMessage);

		return true;
	}
	postToLike = (id: string) => {

		let data = {
			data: {
				likeable_id: id

			}
		}

		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const likePostDataMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.postToLikeCallId = likePostDataMessage.messageId;

		likePostDataMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likePostApiEndPoint
		);

		likePostDataMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(data)
		)

		likePostDataMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		likePostDataMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(likePostDataMessage.id, likePostDataMessage);
	}

	getAllLikes = () => {

		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const postAllLikes = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.allLikesPostCallId = postAllLikes.messageId;

		postAllLikes.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likePostApiEndPoint
		);

		postAllLikes.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		postAllLikes.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(postAllLikes.id, postAllLikes);
	}
	deleteLikeCount = (likedPost: any) => {
		const headers = {
			"Content-Type": configJSON.dashboarContentType,
			token: this.getToken()
		};

		const unlikePostMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.decreaseLikeCountCallId = unlikePostMessage.messageId;

		unlikePostMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.likePostApiEndPoint + `/${likedPost?.id}`
		);

		unlikePostMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);

		unlikePostMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		runEngine.sendMessage(unlikePostMessage.id, unlikePostMessage);
	}
	unLikePost = (id: string) => {
		let likedPost = this.state.userLikes.find((item: any) => item.attributes.likeable_id == id)
		this.deleteLikeCount(likedPost)

	}

	fetchAllCommentsResponseHandler = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);
		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.fetchPostCommentsApiId &&
				responseJson !== undefined
			) {
				this.setState({ postComments: responseJson.data })
			}
		}
	}
	updateFeedData = (responseJson: any, state: any) => {
		const specific_account_post = state.specific_account_post;

		const updatedFeedData = specific_account_post?.map((obj: any) => {
			if (obj.id == responseJson?.data?.attributes.post_id) {
				obj.attributes.comments += 1;
			}
			return obj;
		});

		return updatedFeedData;
	}

	handlePostNewCommentsResponseHandler = (from: string, message: Message) => {
		const apiRequestCallId = message.getData(
			getName(MessageEnum.RestAPIResponceDataMessage)
		);

		let responseJson = message.getData(
			getName(MessageEnum.RestAPIResponceSuccessMessage)
		);

		if (apiRequestCallId != null) {
			if (
				apiRequestCallId === this.postNewCommentsCallId &&
				responseJson !== undefined
			) {
				this.setState({ postComments: [...this.state.postComments, responseJson.data], specific_account_post: this.updateFeedData(responseJson, this.state) })
			}
		}
	}



	// Customizable Area End  

	async receive(from: string, message: Message) {

		// Customizable Area Start

		this.handleResForUnfollowingUserFromPost(from, message)
		this.handleResponseToFollowFromPost(from, message)
		this.handlePostNewCommentsResponseHandler(from, message);
        this.fetchAllCommentsResponseHandler(from, message);
        this.handleLikePostResponse(from, message);
        this.handleFeedAllLikesResponse(from, message);
        this.handlleUnLikePostResponse(from, message);
        this.handleResponseForReplyOnCommentPost(from, message);
        this.deletePostCommentResponseHandler(from, message);
  
		// Customizable Area End
	}

}


