// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
import {createRef} from 'react'

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  
  
}

interface S {
  loading: boolean;
  errorMessage: any;
  securityPin: any;
  showPin: boolean,
  message_final:any,
  login_with_pin: boolean
  profileData: any,
  defaultImage:string,
  isPlaying: boolean,
}

interface SS {
  
  id: any;
  
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  apiEmailLoginCallId: string = "";
  apiSecurityCallId: string = "";
  apiResendOtpId: string = "";
  apiVerifySecurtyPinId: string = "";
  apiVerifySecurtyCodeId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  detailProfileCallId:string = "";
  audioRef: any = null;
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.log(this.context);
    
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      errorMessage: "",
      securityPin: "",
      showPin: false,
      message_final:"",
      login_with_pin:false,
      profileData: {},
      defaultImage:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
        isPlaying: false,
    };

    this.audioRef = createRef()

    this.labelTitle = configJSON.labelTitle;
    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getToken = () => {
    const token = localStorage.getItem("accessToken")
    return token
}
getProfileDetail = () => {
    const headers = {
        "Content-Type": configJSON.dashboarContentType,
        token:  this.getToken()
    };

    const profileMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.detailProfileCallId = profileMessage.messageId;

    profileMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.detailProfileApiEndPoint
    );

    profileMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    profileMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.detailProfileApiMethodType
    );

    runEngine.sendMessage(profileMessage.id, profileMessage);
}

 handlePlayPause = () => {
  if (this.audioRef.current) {
    if (this.state.isPlaying) {
      this.audioRef.current.pause();
      this.setState({isPlaying: false});
    } else {
      this.audioRef.current.play();
      this.setState({isPlaying: true});
    }
  }
};


handleResponseForProfileDetail = (from: string, message: Message) => {
    
    if (this.detailProfileCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiResponse.errors) {
            console.log(apiResponse.errors)


        } else {
    this.setState({ profileData: apiResponse.data })
        }
    }
}
verifySecurityPin() {
    if (this.state.securityPin == "") {
      this.setState({
        errorMessage: "please enter security pin"
      })
      return;
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const attrs = {
      "pin": this.state.securityPin
    };

    const data = {
      "attributes": attrs,
      "type": "email_account"
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifySecurtyPinId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.securityPinAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.securityPinAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getProfileDetail()
   // Customizable Area Start
   this.handlePlayPause()
   // Customizable Area End
  }
  handleShowPin = (showPin: boolean) => {
    this.setState({showPin});
}

  handleLogout = () => {
    localStorage.clear();
    this.props.navigation.navigate("Home")
  }
  setSecurityCode = (text: string) => {
    this.setState({
      securityPin: text,
    });
  };

  responseJsonData = (response:any) => {
    if (response && response?.errors) {
      if (response.errors[0]?.otp) {
        this.setState({ errorMessage: response.errors[0]?.otp })
      }
      if (response.errors[0]?.token) {
        this.setState({ errorMessage: response.errors[0]?.token })
      }
      if (response.errors[0]?.password) {
        this.setState({ errorMessage: response.errors[0]?.password })
      }

    }
}
  onVerifySecurity = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiVerifySecurtyPinId) {
      console.log(responseJson)
      if(responseJson?.message == "Invalid pin!"){
        this.setState({message_final:responseJson?.message})
        return;
      }
      this.responseJsonData(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      this.props.navigation.navigate("Dashboard");
    }
  }

  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.onVerifySecurity(apiRequestCallId,responseJson,errorReponse)
    }
    this.handleResponseForProfileDetail(from,message)
    
  }
}


// Customizable Area End
