/** @format */

import React from "react";
// Customizable Area Start
import FollowingChatController,{Props} from "./FollowingChatController";
import ChatBoxCard from "./ChatBoxCard.web";
import ContentLoader from "react-content-loader";
// Customizable Area End


// Customizable Area Start
class FollowingChat extends FollowingChatController {
	constructor(props: Props) {
		super(props);
	  }
      MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center',background:"#dcd5df" }}>
      {Array(5).fill(5).map((item, i) => <ContentLoader key={i} />)}
  </div>)
	render() {
		return (
           <React.Fragment>
             {this.state.loading ? this.MyLoader() :this.state.userData.length === 0 ? <div>No online user</div> :this.state.userData.map((item: any) => (
                <div className={`chat-card-main" ${this.context.isDark ? "color-white" :""}`} key={item.id}>
                  <ChatBoxCard item={item} navigation={this.props.navigation} />
                </div>
              ))}
           </React.Fragment>
		);
	}
}
export default FollowingChat;

// Customizable Area End
