import React from 'react';
// Customizable Area Start
import { profileImg1, profileImg2 } from "../../dashboard/src/assets";
import { clip, loaderAnimation, send, smile } from "./assets";
import "./Chat.scss";
import "./ActivityFeed.scss";
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import Lottie from "lottie-react";
import ChatImage from './../../../components/src/ChatImage.web';
import {
  closeImage,
} from "../../../blocks/ActivityFeed/src/assets"

// Customizable Area Start
class Chat extends React.Component {
  // Customizable Area Start
  constructor(props: any) {
    super(props)
  }

  LeftView = (props: any) => {
    return (
      <div className="chat-left-main-class">
        <div className="chat-left-item-class" >
          {props.showStatus && props.showImage && <img
            className='chat-left-item-image-class'
            src={props.profilePic || profileImg2}
            alt={'username'} />}

          <div className="chat-left-item-text-class">
            <p className='chat-left-item-text'>{props.message}</p>
            {props.media && <ChatImage media={props.media} />}
          </div>
        </div>
        {
          props.showStatus &&
          <p className={props.showImage ? 'class-left-time-text-with-image' : 'class-left-time-text-without-image'}>
            {new Date(props?.timeStamp ).toLocaleTimeString([], {hour: 'numeric', minute:'numeric', hour12: true , timeZone: 'Asia/Kolkata'})}
          </p>
        }
        
      </div>
    );
  }

  RightView = (props: any) => {
    return (
      <div className="chat-right-main-class">
        <div className="chat-right-item-class" >
          <div className="chat-item-text-class">
            <p className='chat-right-item-text' >{props.message}</p>
            {props.media && <ChatImage media={props.media} />}
          </div>
          {props.showStatus && props.showImage && <img
            className='chat-right-item-image-class'
            src={props.profilePic || profileImg1}
            alt={'username'}
          />}
        </div>
        {
          props.showStatus && 
          <p className={props.showImage ? 'chat-right-time-with-image' : 'chat-right-time-without-image'} >
            {new Date(props?.timeStamp ).toLocaleTimeString([], {hour: 'numeric', minute:'numeric', hour12: true , timeZone: 'Asia/Kolkata'})}
          </p>
        }
       
      </div>
    );
  }
  // Customizable Area End

  render() {
    const {
      messages,
      chatEmojiPickerVisible,
      newMessage,
      loading,
      messagesEndRef,
      textareaRef,
      handleChangeTextArea,
      uploadImgRef,
      context,
      handleMessageSubmit,
      handleEmojiPicker,
      EmojiSelectHandler,
      currentSelectedUser,
      handleFileUpload,
      chatImages,
      closeFileHandler,
      profileData,
      chatAttachment ,
      handleChatUIResponse
    }: any = this.props;
    return (
      <div className="chat-users-class">
        <div className="chat-heading-class">
          <p className="medium-font-text">{currentSelectedUser?.name || ""}</p>
        </div>
        <div className="chat-data-class scroller" data-test-id="chat-data-section">
          {loading && <Lottie style={{ height: 180, width: 180 }} animationData={loaderAnimation} loop={true} />}
          {messages.map((item: any, index: any) => {
            const showStatus = handleChatUIResponse(index)
            if (item.author === profileData.email) {
              return <this.RightView timeStamp={item.state?.timestamp} message={item.body} profilePic={profileData.attributes?.profile_pic?.url} media={item.media} name={item.name} showImage={true} key={index} showStatus={showStatus} />
            } else {
              return <this.LeftView timeStamp={item.state?.timestamp} message={item.body} profilePic={currentSelectedUser?.profileImg} media={item.media} name={item.name} showImage={true} key={index} showStatus={showStatus} />
            }
          })}
          <div ref={messagesEndRef} />
        </div>

        {chatEmojiPickerVisible && <EmojiPicker previewConfig={{ showPreview: false }} onEmojiClick={EmojiSelectHandler} searchDisabled height={1000} width={'95%'} />}
        <div className="text-area-wrapper-class add-post-class" data-test-id="chat-post-section">
          <textarea value={newMessage} ref={textareaRef} onChange={handleChangeTextArea} placeholder="Enter your message here" className={`user-chat-input-text-area ${context.isDark ? "textfield-color" : ''}`} />
          {chatImages?.length > 0 && <div className="modal-image-holder-class">
            {chatImages[0].includes("image") ? <div className="uploaded-image-class-wrapper">
              <img className="uploaded-img-class" src={chatImages[1]} />
              <img onClick={() => {
                closeFileHandler()
              }} className="pointer-class uploaded-img-remove-class" src={closeImage} />
            </div> : chatImages[0].includes("video") ? <div className="uploaded-image-class-wrapper">
              <video className="uploaded-img-class" src={chatImages[1]} />
              <img onClick={() => {
                closeFileHandler()
              }} className="pointer-class uploaded-img-remove-class" src={closeImage} />
            </div> : <div className='uploaded-image-class-wrapper'><div>{chatAttachment.name}</div><img onClick={() => {
              closeFileHandler()
            }} className="pointer-class uploaded-img-remove-class" src={closeImage} /></div>
            }
          </div>}
          <div className="user-input-action-buttons">

            <div className="pointer-class div-align-center">
              <input
                ref={uploadImgRef}
                style={{ display: 'none' }}
                type="file"
                accept="image/*,video/*,.pdf,.doc,.docx"
                name="image-upload"
                id="input"
                data-test-id='file-input-handler'
                onChange={handleFileUpload}
              />
              <img
                onClick={handleEmojiPicker}
                className='chat-input-icon-class'
                src={smile}
              />
            </div>

            <div onClick={() => {
              uploadImgRef.current.click();
            }} className="pointer-class" style={{ alignItems: 'center', justifyItems: 'center' }}>
              <img
                className='chat-input-icon-class'
                src={clip}
              />
            </div>

            <div className="input-field-send-icon"    onClick={handleMessageSubmit} >
              <img
                className='chat-input-icon-center'
                src={send}
              />
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default Chat;
// Customizable Area End