/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import CfApiNftControllerWeb,{Props} from "./CfApiNftControllerWeb";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import ThemeContext from "../../../components/src/ThemeContext.web";
import NftBanner from "../../../components/src/NftBanner";
// Customizable Area End

// Customizable Area Start
import './CfapiNftWeb.scss';
import NftCard from "../../../components/src/NftCard.web";
import { nftImage2, scobby } from "./assets";
// Customizable Area End

// Customizable Area Start
class CfApiNftWeb extends CfApiNftControllerWeb {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation} selected="NFT Market" />
                <Grid container className={`main-container container-inner-main app-page-dashboard ${this.context.isDark ? "dark-color" : ""}`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`container-items pd ${this.context.isDark ? "dark-color" : ""}`}
                    >
                        <Navbar context={this.context} title="Welcome" navigation={this.props.navigation} />
                        <Grid container className="nft-market-screen-wrpr scroller">
                            <div className="banner-wrapper">
                                <NftBanner backgroundImage="https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622024.jpg?w=740&t=st=1676973931~exp=1676974531~hmac=1de948660a5901c5ce4382aec9b19c6c8ae790fe1df45dc7da0786221a446ee0" title="The Final Dungeon" />
                                <NftBanner backgroundImage="https://img.freepik.com/free-vector/hand-drawn-nft-style-ape-illustration_23-2149622021.jpg?w=740&t=st=1676973806~exp=1676974406~hmac=2b080ea6fd5ec406542cb7069dd746bf463529ee2aa986beca7f9c075bb17d77" title="Merkur Magazine Cover" />
                            </div>
                            <div className="nft-row-wrapper">
                                <p className="row-heading">
                                    All Items
                                </p>
                                <div className="nft-card-wrapper">
                                    {this.state.nftCardsData.map((item: any, index: any) =>
                                        <React.Fragment key={index}>
                                            <NftCard image={scobby} />
                                        </React.Fragment>

                                    )}
                                </div>
                            </div>
                            <div className="nft-row-wrapper">
                                <p className="row-heading">
                                    Popular Listing
                                </p>
                                <div className="nft-card-wrapper">
                                    {this.state.nftCardsData.map((item: any, index: any) =>
                                        <React.Fragment key={index}>
                                            <NftCard image={nftImage2} />
                                        </React.Fragment>

                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        );
    }
}
export default CfApiNftWeb;

CfApiNftWeb.contextType = ThemeContext;

// Customizable Area End