
import React, { useEffect, useRef, useState } from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    Modal,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "../../blocks/ActivityFeed/src/Chat.scss";
import { chatImage, closeImage, search } from "../../blocks/ActivityFeed/src/assets";
import ContentLoader from "react-content-loader";

export const NewChatModal = (props: any) => {

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 1500)
    })

    // useEffect(() => {
    //     if (props.userData != null) {
    //         setUserData(props.userData);
    //         setSearchData(props.userData);
    //     }
    // }, [props.userData])

    const [animate, setAnimate] = useState(false);

    const [searchData, setSearchData] = useState([]);

    const [searchValue, setsearchValue] = useState("");

    return (
        <Modal
            open={props.modalVisible}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className={animate ? "mainWrapper-animated" : "mainWrapper"}>
                <div className="new-chat-modalWrapper">
                    <h2 className="modal-title">
                        New Chat
                    </h2>

                    <div className="search-field-wrapper add-post-class">
                        <input type={'text'} value={props.addNewSearch} onChange={(event: any) => props.handleAddNewSearch(event)} placeholder="Search friends" className={`new-chat-search-user-chat-list ${props.context ? "textfield-color" : ''}`} style={{ backgroundColor: '#F7F2F9' }} />
                        <div className="new-chatinput-field-search-icon" style={{ alignItems: 'center', justifyItems: 'center' }}>
                            <img
                                className="chat-input-icon-class"
                                src={search}
                            />
                        </div>
                    </div>
                    <div className="scroller new-chat-user-list">
                        {props?.isFetchingUsersForChat ? <ContentLoader /> : searchData?.length ? searchData.map((item: any, index: any) => {
                            return <div key={index} className={"chat-list"} onClick={() => props.handleNewChatWithUser(item.email)}>
                                <div className="chat-card-sec">
                                    <div className="profile-img-container">
                                        <img
                                            src={item.profileImg}
                                            alt={item.name}
                                        />
                                    </div>
                                    <div style={{ flex: 1 }} className="profile-detail">
                                        <p className="profile-name">{item.name}</p>
                                    </div>
                                </div>
                                <div className="chat-count-main-class">
                                    <img src={chatImage} />
                                </div>
                            </div>
                        }) : (searchValue && !searchData?.length) ? <div><div style={{ flex: 1 }} className="profile-detail">
                            <p className="profile-name">not found</p>
                        </div></div> : props.userData?.map((item: any, index: any) => {
                            return <div key={index} className={"chat-list"} onClick={() => props.handleNewChatWithUser(item.email)}>
                                <div className="chat-card-sec">
                                    <div className="profile-img-container">
                                        <img
                                            src={item.profileImg}
                                            alt={item.name}
                                        />
                                    </div>
                                    <div style={{ flex: 1 }} className="profile-detail">
                                        <p className="profile-name">{item.name}</p>
                                    </div>
                                </div>
                                <div className="chat-count-main-class">
                                    <img src={chatImage} />
                                </div>
                            </div>
                        })

                        }
                    </div>


                    <div
                        className="pointer-class"
                        onClick={props.onClose}
                        style={{
                            alignItems: "center",
                            justifyItems: "center",
                            position: "absolute",
                            right: 30,
                            top: 30,
                        }}>
                        <img
                            style={{ height: 20, width: 20, marginRight: 10 }}
                            src={closeImage}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );

}