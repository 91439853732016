// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react"

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}
export interface DataListItem {
    id: string,
    attributes: {
        first_name: string,
        last_name: string,
        email: string,
        is_follow: string,
        profile_pic: {
            url: string
        }
    }
}
interface FollowData {
    item: DataListItem
}

interface S {
    // Customizable Area Start
    listData: DataListItem[],
    followingData: DataListItem[],
    openModal: boolean,
    errorMsg: string,
    followdata: FollowData,
    followingIdData: FollowData,
    openFollowerModel: boolean
    currentFollowerData: DataListItem[]

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
// Customizable Area End


// Customizable Area Start
export default class FollowersController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    followerCallId: string = "";
    addFromSuggestionCallId: string = "";
    UnFollowFromSuggestionCallId: string = "";
    getFollowingCallId: string = "";
    addFollowerFromFollowingCallId: string = "";
    UnFollowFromFollowingCallId: string = "";
    getCurrentUserFollowersCallId: string = "";
    addFollowerFromFollowerCallId: string = "";
    UnFollowFromFollowerCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            listData: [],
            followingData: [],
            openModal: false,
            errorMsg: "",
            followdata: {
                item: {
                    id: "",
                    attributes: {
                        first_name: "",
                        last_name: "",
                        email: "",
                        is_follow: "",
                        profile_pic: {
                            url: ""
                        }
                    }
                }
            },
            followingIdData: {
                item: {
                    id: "",
                    attributes: {
                        first_name: "",
                        last_name: "",
                        email: "",
                        is_follow: "",
                        profile_pic: {
                            url: ""
                        }
                    }
                }
            },
            currentFollowerData: [],
            openFollowerModel: false,
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        this.getListOfSuggestionData()
        // Customizable Area End
    }

    getToken = () => {
        const token = localStorage.getItem("token-access")
        return token
    }
    getListOfSuggestionData = () => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };

        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.followerCallId = followerDataMessage.messageId;

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.suggestionListApiEndPoint
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }
    addFromSuggestionList = (account_id: string) => {
        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()

        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }
        const addFromSuggestionDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFromSuggestionCallId = addFromSuggestionDataMessage.messageId;

        addFromSuggestionDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFollowersApiEndPoint
        );

        addFromSuggestionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFromSuggestionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFromSuggestionDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(addFromSuggestionDataMessage.id, addFromSuggestionDataMessage);

    }
    unFollowFromSuggestion = (account_id: string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }
        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UnFollowFromSuggestionCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/:${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onCloseFollowerModal = () => {
        this.setState({ openFollowerModel: false })
    }
    handleOperator = (item: DataListItem) => {
        if (item.attributes.is_follow === "following") {
            return <button data-test-id={`unFollowUser_${item.id}`} style={{ cursor: "pointer" }} onClick={() => this.unFollowFromFollower(item.id)}> {configJSON.followingText}</button>
        } else if (item.attributes.is_follow === "current_account") {
            return <p></p>
        } else {
            return <button data-test-id={`followerbuttonData_${item.id}`} style={{ cursor: "pointer" }} onClick={() => this.addFromFollowers(item.id)}>{configJSON.followText}</button>
        }
    }
    getFollowingData = (item: DataListItem) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        this.setState({ followingIdData: { item } })
        const followingDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFollowingCallId = followingDataMessage.messageId;

        followingDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followingApiEndPoint}${item.id}`
        );

        followingDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followingDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followingDataMessage.id, followingDataMessage);

    }
    addFromFollowing = (account_id: string) => {

        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()

        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFollowersApiEndPoint
        );

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);

    }
    unFollowFromFollowing = (account_id: string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }

        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UnFollowFromFollowingCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/:${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }
    getCurrentUserFollowersData = (item: DataListItem) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        this.setState({ followdata: { item } })
        const currentUserFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCurrentUserFollowersCallId = currentUserFollowerDataMessage.messageId;

        currentUserFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.followersApiEndPoint}${item.id}`
        );

        currentUserFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        currentUserFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(currentUserFollowerDataMessage.id, currentUserFollowerDataMessage);
    }
    addFromFollowers = (account_id: string) => {

        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()

        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFollowerFromFollowerCallId = addFollowerDataMessage.messageId;

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFollowersApiEndPoint
        );

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);

    }
    unFollowFromFollower = (account_id: string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.getToken()
        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }

        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UnFollowFromFollowerCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/:${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }



    handleResponseForSuggestion = (from: string, message: Message) => {

        if (this.followerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.setState({ listData: apiResponse.data })
            }
        }
    }
    handleResponseToAddFromSuggestion = (from: string, message: Message) => {
        if (this.addFromSuggestionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.getListOfSuggestionData()

            }
        }
    }
    handleResponseForUnFollowFromSuggestion = (from: string, message: Message) => {
        if (this.UnFollowFromSuggestionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.getListOfSuggestionData()
            }
        }

    }
    handleResponseForFollowing = (from: string, message: Message) => {
        if (this.getFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.following) {
                this.setState({ followingData: [], errorMsg: apiResponse.following, openModal: true })


            } else {
                this.setState({ followingData: apiResponse.data, openModal: true, errorMsg: "" })
                this.getListOfSuggestionData()
            }
        }

    }
    handleResponseForAddFromFollowing = (from: string, message: Message) => {
        if (this.addFollowerFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })


            } else {
                this.getListOfSuggestionData()
                setTimeout(() => {
                    this.getFollowingData(this.state.followingIdData.item)
                })

            }
        }
    }
    handleResForUnFollowFromFollowing = (from: string, message: Message) => {
        if (this.UnFollowFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })


            } else {
                this.getListOfSuggestionData()
                setTimeout(() => {
                    this.getFollowingData(this.state.followingIdData.item)
                })

            }
        }

    }
    handleResponseForCurrentUserFollowerData = (from: string, message: Message) => {
        if (this.getCurrentUserFollowersCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.followers) {
                this.setState({ currentFollowerData: [], errorMsg: apiResponse.followers, openFollowerModel: true })


            } else {
                this.setState({ currentFollowerData: apiResponse.data, openFollowerModel: true, errorMsg: "" })
            }
        }

    }
    handleResponseForAddFromFollower = (from: string, message: Message) => {
        if (this.addFollowerFromFollowerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.getListOfSuggestionData()
                setTimeout(() => {
                    this.getCurrentUserFollowersData(this.state.followdata.item)
                })

            }
        }
    }
    handleResForUnFollowFromFollower = (from: string, message: Message) => {
        if (this.UnFollowFromFollowerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.error })

            } else {
                this.getListOfSuggestionData()
                setTimeout(() => {
                    this.getCurrentUserFollowersData(this.state.followdata.item)
                })

            }
        }

    }

    async receive(from: string, message: Message) {

        this.handleResponseForSuggestion(from, message)
        this.handleResponseToAddFromSuggestion(from, message)
        this.handleResponseForUnFollowFromSuggestion(from, message)
        this.handleResponseForFollowing(from, message)
        this.handleResponseForCurrentUserFollowerData(from, message)
        this.handleResForUnFollowFromFollowing(from, message)
        this.handleResponseForAddFromFollowing(from, message)
        this.handleResponseForAddFromFollower(from, message)
        this.handleResForUnFollowFromFollower(from, message)

    }

}
// Customizable Area End
