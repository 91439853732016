/** @format */

import React from "react";
// Customizable Area Start
import OwnedNftController, { Props } from "./OwnedNftController";
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class OwnedNft extends OwnedNftController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className={`nft-detail ${this.context.isDark ? "black-color" :""}`}>
				<h1 className={`nft-detail-text ${this.context.isDark ? "text-color" :""}`}>0 Nft</h1>
			</div>
		);
	}
}
export default OwnedNft;
OwnedNft.contextType = ThemeContext;
// Customizable Area End
