// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
    Box,
    Typography,
    Switch,

} from "@material-ui/core";

// Customizable Area Start
import { data, webStyle } from "../../Settings5/src/IsoData";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar'
import "./Notification.scss"

// Customizable Area End

import NotificationSettingController, { Props } from "./NotificationSettingController";

export const switchFunc =({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
}
export const IOSSwitch: any = withStyles(data)(switchFunc);
export default class Notification extends NotificationSettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPickerElementFunc({ title, key, para }: any) {
        return (
            <Box sx={webStyle.pickerWrapper} className="main-wrapper">
                <Box
                    className="md-iso"
                    component="div"
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Typography
                        variant="body2"
                        style={webStyle.soundsettingTitle}
                        className={`title-width ${this.props.context.isDark ? "color-white" : ""}`}
                    >
                        {title}
                    </Typography>
                    <Typography className={`pd-iso ${this.props.context.isDark ? "color-white" : ""}`}>{para}</Typography>
                </Box>
                <Box className="iso_switch switch_btn" sx={{ width: 80 }}>
                    <IOSSwitch
                        data-test-id={`switch-btn-${key}`}
                        checked={this.state.notificationSettings[key]}
                        onChange={() => this.handleNotificationSettings(key)}
                        name="checkedB"
                    />
                </Box>

            </Box>
        );
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div className={`${this.props.context.isDark ? "color-white" : "snackbar-bg"}`}>
                    <Snackbar
                        open={this.state.openSnackbar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseSanacbar}
                        message='Setting changed successfully!'
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    />
                </div>
                <div className="notification-section">
                    <Box sx={webStyle.mainWrapper} className={`${this.props.context.isDark ? "dark-color" : ""}`}>
                        <Typography className={`${this.props.context.isDark ? "color-white" : ""}`} variant="h4" style={webStyle.settingTitle}>
                            Notification
                        </Typography>
                        <p className={`${this.props.context.isDark ? "color-white" : ""}`} style={webStyle.settingDescription}>
                            We’ll always let you know about important changes, but you pick what else you want to hear about.
                        </p>
                        <Box style={{ marginTop: "15px" }} className="notification-scrollbar">
                            {this.renderPickerElementFunc({
                                title: "Sending and receiving funds/ NFTs",
                                key: "notification_for_nft",
                            })}
                            {this.renderPickerElementFunc({
                                title: "Failed and successes in exchanging funds",
                                key: "notification_for_exchange_fail_success",
                            })}
                            {this.renderPickerElementFunc({
                                title: "Accepted bids or new bids on NFTs",
                                key: "notification_for_bids",
                            })}
                            {this.renderPickerElementFunc({
                                title: "Significant price increases and decreases of coins",
                                key: "notification_for_price_increase_decrease",
                            })}
                            {this.renderPickerElementFunc({
                                title: "When an NFT on your wishlist goes on sale or is purchased",
                                key: "notification_for_sale_purchase",
                            })}

                            {this.renderPickerElementFunc({
                                title: "Messages (DMs)",
                                key: "notification_for_message",
                            })}
                            {this.renderPickerElementFunc({
                                title: "Posts",
                                key: "notification_for_post",
                                para: "if you are subscribed to someone and want to receive notifications for when they post something"
                            })}
                        </Box>
                    </Box>

                </div>
            </>

            // Customizable Area End
        );
    }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
