import React from "react";
// Customizable Area Start
import {
  Grid,
} from "@material-ui/core";
import { search } from "./assets";
import Button from '@material-ui/core/Button';
import "./Chat.scss";
import "./ActivityFeed.scss";
import { NewChatModal } from "../../../components/src/NewChatModal.web";
import ContentLoader from 'react-content-loader'

const override: any = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
// Customizable Area End

// Customizable Area Start
class ChatList extends React.Component {

  constructor(props: any) {
    super(props)
  }

  MyLoader = () => <ContentLoader />

  render() {
    const { userData, searchData, newChatModalVisible, userChatList, isFetchingChatList, handleNewChatModal, handleSearch, openUserChatHandler, handleNewChatWithUser, searchValue, selectedUserId, isFetchingUsersForChat , handleAddNewSearch , addNewSearch}: any = this.props;
    return (
      <div className="chat-list-main-class">

    <Grid container>
      <Grid item xs={12}>
        <div className="app--create-post-form-field-button">
          <Button data-test-id={'NewChatClick'} onClick={handleNewChatModal} className={`app-create-post-form-button`} variant="contained">New Chat</Button>
        </div>
      </Grid>
    </Grid>

    <div className="search-field-wrapper add-post-class">
      <input type={'text'} onChange={handleSearch} placeholder="Search people" className={`search-user-chat-list`} />
      <div className="input-field-search-icon" style={{ alignItems: 'center', justifyItems: 'center' }}>
        <img
          className="chat-input-icon-class"
          src={search}
        />
      </div>
    </div>
    <div className="scroller chat-list-scroll-class">
      {isFetchingChatList && this.MyLoader()}
      {searchData?.length ? searchData.map((item: any, index: any) => (
        <div key={index} className={`chat-list ${selectedUserId === item?.id ? "chat-list-selected": ""}`} onClick={() => openUserChatHandler(item)}>
          <div className="chat-card-sec card-section">
            <div className="profile-img-container image-pd">
              <img
                src={item?.profileImg}
                alt={item?.name}
              />
              <span className="dots mr" />
            </div>
            <div style={{ flex: 1 }} className="profile-detail">
              <p className="profile-name pd">{item?.name}</p>
              <p className="item-status-text ms">{item?.status}</p>
            </div>
          </div>
          <div className="chat-count-main-class">
            {index != 0 ? <p className="item-date-text" >30.01.21</p> : null }
          </div>
        </div>
      )) : (searchValue && !searchData?.length) ? <div><div style={{ flex: 1 }} className="profile-detail">
        <p className="profile-name">not found</p>
      </div></div> : userChatList?.map((item: any, index: any) => (
        <div key={index} className={`chat-list ${selectedUserId === item?.email ? "chat-list-selected": ''}`} onClick={() => openUserChatHandler(item)} data-test-id={`chat-list-selected-${index}`}>
          <div className="chat-card-sec">
            <div className="profile-img-container">
              <img
                src={item?.profileImg?.url || item?.profileImg}
                alt={item?.name}
              />
              <span className="dots" />
            </div>
            <div style={{ flex: 1 }} className="profile-detail">
              <p className="profile-name">{item?.name}</p>
              <p className="item-status-text">{item.lastMessage}</p>
            </div>
          </div>
          <div className="chat-count-main-class">
            {index != 0 ? <p className="item-date-text" >{new Date(item?.lastMessageDate || item.dateCreated).toLocaleTimeString([], {hour: 'numeric', minute:'numeric', hour12: true , timeZone: 'Asia/Kolkata'})}</p> : null }
          </div>
        </div>
      ))}
    </div>

    <NewChatModal userData={userData} modalVisible={newChatModalVisible} onClose={handleNewChatModal} handleNewChatWithUser={handleNewChatWithUser} isFetchingUsersForChat={isFetchingUsersForChat} handleAddNewSearch={handleAddNewSearch} addNewSearch={addNewSearch}/>
  </div>
);
  }
}

export default ChatList;
// Customizable Area End