/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
import UserProfileDetail from "./UserProfileDetail.web";
import OwnedNft from "./OwnedNft.web";
import ThemeContext from "../../../components/src/ThemeContext.web";
import ActivityFeedController, { Props } from "./ActivityFeedController";
import ContentLoader from 'react-content-loader'
import { FeedView } from "../../../components/src/FeedView.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class UserActivity extends ActivityFeedController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	MyLoader = () => (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
		{Array(4).fill(4).map((item, i) => <ContentLoader key={i} />)}
	</div>)
	feedTabData = (item: any, index: any) => (

		<FeedView navigation={this.props.navigation}
			loading={this.props.loading}
			item={item}
			key={index}
			allComments={this.props.postComments}
			fetchCommentsData={this.props.fetchCommentsData}
			postNewComments={this.props.postNewComments}
			likeAction={this.props.likeFeed}
			deleteLikeAction={this.props.deleteLikeFeed}
			userPostList={this.props.userPostList}
			userLikes={this.props.userLikes}
			replyComment={this.props.replyComment}
			deleteComments={this.props.deleteComments}
			context={this.props.context}
			textareaRef={this.props.textareaRef}
			openAllComment={this.props.openAllComment}
			handleOpenAllComments={() => this.props.handleOpenAllComments(item.id)}
			handleOpenChat={this.props.handleOpenChat}
		/>
	)
	render() {

		return (
			<div className="faqs">
				<div className=" container-width">
					<Grid container>
						<Grid item md={7} lg={7} className="profile-nft-detail">
							<UserProfileDetail navigation={this.props.navigation} context={this.context} />

							<OwnedNft navigation={this.props.navigation} />
						</Grid>
						<Grid item md={5} lg={5} className="user-activity-feed">
							<h2 className={`player-activity-text-class ${this.context.isDark ? "text-color" : ""}`}>Activity</h2>
							<div className={`scroller scroller-width user-feedview`}>
								{this.props.loading ? this.MyLoader() : this.props?.userFollowingPost?.length === 0 ? <div>No post to show</div> :
									this.props?.userFollowingPost?.map(this.feedTabData)
								}
							</div>

						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}
export default UserActivity;
UserActivity.contextType = ThemeContext;
// Customizable Area End
