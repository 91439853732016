/** @format */

import React from "react";
// Customizable Area Start
import "./ChatBoxCard.scss";
import { chatImage } from "./assets";
import FollowingChatController from "./FollowingChatController";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class ChatBoxCard extends FollowingChatController {

	defaultImage = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"

	render() {
		return (
			<div className="chat-card">
				<div className="chat-card-sec">
					<div className="profile-img-container">
						<img
							src={this.props.item?.attributes?.profile_pic.url === null ? this.defaultImage : this.props.item?.attributes?.profile_pic.url}
							alt={this.props.item.attributes?.first_name}
						/>
						<span className="dots" />
					</div>
					<div className="profile-detail">
						<h3 className="profile-detail-name">{this.props.item.attributes?.first_name} {this.props.item.attributes?.last_name}</h3>
						<p className="profile-detail-status">online</p>
					</div>
				</div>
				<div className="icon-button">
					<img data-test-id="open-chat" className="chat-img" src={chatImage} onClick={() => {
						this.handleOpenChat(this?.props?.item?.attributes?.email)
					}} />
				</div>
			</div>
		);
	}
}
export default ChatBoxCard;

// Customizable Area End
