Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";  
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.profileDetailApiMethodType = "GET";
exports.profileDetailApiEndPoint = "bx_block_settings/settings/account_detail";
exports.deleteApiMethodType = "DELETE";
exports.deleteApiEndPoint = "bx_block_login/logins/logout";
exports.onlineFollowingUserApiEndPoint = "bx_block_followers/follow/active_following"
exports.searchUserEndpoints = "/bx_block_login/logins/suggestion"
exports.followingApiEndPoint = "bx_block_followers/follow/following?id=";
exports.postApiMethodType = "POST";
exports.folllowUnfollowApiEndPoint = "bx_block_followers/follows";
exports.unFollowApisApiEndPoint = `bx_block_followers/follow/unfollow_account`;
exports.getActivityFeed = "bx_block_posts/global_post";

exports.btnExampleTitle = "CLICK ME";
exports.userDetailApiEndPoint = "account_block/account_activities";
exports.getActivityFeed = "bx_block_posts/global_post";
exports.likePostApiEndPoint = "bx_block_like/likes";
exports.fetchUserForChatApiEndPoint = "bx_block_login/logins/suggestion";
exports.getMethodForUserData = "GET";
exports.createNewChannelMethod = "POST";
exports.createNewChannelApiEndpoint = "bx_block_chat9/create_channel";
exports.fetchUserChatListApiEndPoint = "bx_block_chat9/chat_lists"
exports.fetchAllCommentsApiEndPoint = "bx_block_comment/comments"
exports.postNewCommentsApiEndPoint = "bx_block_comment/comments"
exports.replyCommentPostApiEndPoint = "bx_block_comment/reply_comment";
exports.getTwilioTokenApiEndPoint = "bx_block_chat9/generate_chat_token";
exports.getTwilioTokenApiMethod = "POST";
exports.getUserPostList = "bx_block_posts/posts";
exports.getPostList = "bx_block_posts/posts";
exports.getDeleteCommentApiMethod = "DELETE";
exports.getUserFollowingPostListApiEndPoint = "bx_block_followers/follow/following_post";
exports.accountListApiEndPoint = "/bx_block_login/logins/suggestion";
exports.specificAccountApiEndPoint = "account_block/specific_account_details";
exports.deleteFollowApiEndPoint ="bx_block_followers/follow/unfollow_account";
exports.globalSearchApiEndPoint = "account_block/global_search?query="
// Customizable Area End